import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { Checkbox, InputAdornment, TextField } from "@mui/material";
import Search from "@mui/icons-material/Search";
import Plus from '../../../assets/icons/plusFilledIcon.svg'
import Minus from '../../../assets/icons/minusFilledIcon.svg'
import Cross from '../../../assets/icons/crossFilledIcon.svg'
import { ATTRIBUTE_LABEL, COLOR_DATA, REGEX } from "../../../utils/constant";
import { IColorPalette, INPUT_FIELD_TYPE } from "../../../utils/types";

interface ResourceFilterProps {
    label: string;
    isSearchable: boolean;
    resourceOptions: any;
    onChange: (id: number, isSelected: boolean, value?: string) => void;
    selectedOptions: (string | number)[];
    isRangeFilter: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.primary.primary800,
    },
    value: {
        color: theme.palette.text.primary.primary800,
    },
    search: {
        background: "#EDEDED",
        borderRadius: "4px",
        "& .MuiOutlinedInput-notchedOutline": {
            border: 0
        }
    },
    searchIcon: {
        color: "#A1A1A1"
    },
    selectedFilterChip: {
        background: theme.palette.background.primary.primary50,
        color: theme.palette.text.primary.primary800,
        border: `1px solid ${theme.palette.border.primary.primary200}`
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: `${theme.palette.border.neutral.neutral200} !important`,
        borderRadius: '8px',
        fontWeight: 400
    },
    error: {
        color: theme.palette.text.secondary.secondary500
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.primary.primary200}`
    }
    
}));

export const ResourceFilter: React.FC<ResourceFilterProps> = ({ label, isSearchable, resourceOptions, onChange, selectedOptions, isRangeFilter }) => {

    const classes = useStyles();
    const [showAll, setShowAll] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    if (!resourceOptions) return null;

    const handleClick = () => {
        setShowAll(!showAll);
    };

    const displayedData = Array.isArray(resourceOptions)
        ? showAll
            ? resourceOptions
            : []
        : [];
    
    const searchData = displayedData?.filter((item: any) =>
        item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );

    const handleRangeOnCancel = (e: React.MouseEvent<HTMLSpanElement>) => {
        onChange(0, false, "")
        onChange(1, false, "")
    }

    const handleOnChange = (id: number) => ( e: any) => {
        if(e?.target?.type ===  INPUT_FIELD_TYPE.CHECKBOX && e?.target?.value && id) {
            onChange(id, e?.target?.checked ?? false, e?.target?.value)
        } else {
            if (REGEX.PLP_RANGED_ATTRIBUTES.test(e?.target?.value) || e?.target?.value === '') {
                onChange(id, e?.target?.checked ?? false, e?.target?.value)
            } 
        }
    }
    const handleCrossClick = (id: number) => ( e: any ) => {
        onChange(id, false, '');
    };
    
    const getRangeDetails = (minValue: any, maxValue: any) => {
        if(!!minValue && !!maxValue) return  `${minValue} - ${maxValue} mm`
        return `${minValue || maxValue} mm`
    }

    if(label === "Hardness")return null;

    const getAttributeValue = (attributeName: string, attributeValue: string, uom: string) => {
        try {
            if(attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
                return attributeValue;
            }
            const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="flex items-center">
                    <div className="w-6 h-4 mr-2 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue} {uom}</span>
                </div>
            )
        } catch (error) {
            return attributeValue;
        }
	}

    return (
        <div className={`${classes.borderBottom} grid gap-y-2`}>
            <div className="flex justify-between">
                <div className={`${classes.title} text-base font-medium`}>{label}</div>
                {resourceOptions.length > 0 && (
                    <div className="flex cursor-pointer" onClick={handleClick}>
                        {showAll ? <img src={Minus} /> : <img src={Plus} />}
                    </div>
                )}
            </div>
            {showAll && isRangeFilter && (
                <>
                    <div className="flex flex-col gap-2 pb-2">
                        <TextField
                            id={`${label}_min`}
                            size="small"
                            placeholder="Enter Minimum Value"
                            label="Min"
                            variant="outlined"
                            type="number"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            value={selectedOptions?.[0]}
                            onChange={handleOnChange(0)}
                        />
                        <TextField
                            id={`${label}_max`}
                            size="small"
                            placeholder="Enter Maximum Value"
                            label="Max"
                            variant="outlined"
                            type="number"
                            InputProps={{
                                classes: {notchedOutline: classes.notchedOutline}
                            }}
                            value={selectedOptions?.[1]}
                            onChange={handleOnChange(1)}
                            error={parseInt(selectedOptions?.[0] as string) > parseInt(selectedOptions?.[1] as string)}
                            helperText={parseInt(selectedOptions?.[0] as string) > parseInt(selectedOptions?.[1] as string) && 'Max must be greater than Min'}
                        />
                    </div>
                </>
            )}
            {showAll && isSearchable && (
                <div className="flex gap-x-2 rounded ">
                    <TextField 
                        size="small"
                        className={classes.search}
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            className: "px-2 h-8 text-base",
                            startAdornment: (
                                <InputAdornment position="start" >
                                    <Search className={classes.searchIcon} />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
            )}
            {searchData  && !isRangeFilter &&
                searchData.map((item: any, index: number) => (
                    <div className="flex gap-x-1.5 last:pb-2" key={`${item.id}_${index}`}>
                        <div>
                            <Checkbox
                                sx={{ padding: 0, color:"#5B78D7" }}
                                checked={selectedOptions?.includes(item.id)}
                                onChange={handleOnChange(item.id)}
                            />
                        </div>
                        <div className={`${classes.value} my-auto text-sm font-normal`}>{getAttributeValue(label, item.name, "")}</div>
                    </div>
            ))}
            {
                !!resourceOptions.length && !!!displayedData.length && 
                <div className="flex flex-wrap">
                    {!isRangeFilter ? !!resourceOptions.length && !!!displayedData.length &&
                        resourceOptions.map((item: any, index: number) => (
                            selectedOptions?.includes(item.id) &&
                            <div className={`px-4 py-2 ${classes.selectedFilterChip} rounded-lg font-medium w-max flex items-center gap-2 mb-2 mr-2 text-sm`} key={`${item.id}_${index}`}>
                                {item.name}
                                <span className="cursor-pointer" onClick={handleCrossClick(item.id)}>
                                    <img src={Cross} />
                                </span>
                            </div> )) : ( 
                        (!!selectedOptions?.[0] || !!selectedOptions?.[1]) && !showAll &&
                        <div className="">
                            <div className={`px-4 py-2 ${classes.selectedFilterChip} rounded-lg font-medium w-max flex items-center gap-2 text-sm`}>
                                {getRangeDetails(selectedOptions?.[0], selectedOptions?.[1])}
                                <span className="cursor-pointer" onClick={handleRangeOnCancel}>
                                    <img src={Cross} />
                                </span>
                            </div>
                            { parseInt(selectedOptions?.[0] as string) > parseInt(selectedOptions?.[1] as string) && <div className={`text-xs font-normal ${classes.error}`}>Max must be greater than Min</div>}
                        </div>
                    )}
                </div>
            }
        </div>
    );
};