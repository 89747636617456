import { FAQ } from "../components/template/CatalogueDetail/FAQ.template";
import { ContactUsPopupProps, DeliveryAndTransportationSectionProps, IColorPalette, IPagination, MY_MATERIAL, option, PaymentTermsSectionProps } from "./types";
import CallIcon from '../assets/icons/call.svg';
import MailIcon from '../assets/icons/mail.svg';
import WriteToUsIcon from '../assets/icons/writeToUs.svg';
import WarehouseIcon from '../assets/icons/warehouse.svg';
import TransportationIcon from '../assets/icons/transportation.svg';
import { IPriceDetails } from "../components/template/Cart/CartPriceDetails.template";

export const CUSTOMER_ROUTES = {
    ONBOARDING: "/onboarding",
    CATALOGUE_LISTING: "/catalogue/list",
    CATALOGUE_DESCRIPTION: "/catalogue/view",
    CATALOGUE_MTO_VIEW: "/mto-catalogue/view",
    SECONDARY_CATALOGUE_DESCRIPTION: "/secondary-catalogue/view",
    SECONDARY_CATALOGUE_LOT_VIEW: "/secondary-catalogue-lot/view",
    CART: "/cart",
    WISHLIST: "/wishlist",
    DASHBOARD: "/dashboard",
    TERMS_AND_CONDITIONS: "/terms-conditions",
    MY_PROFILE: '/my-profile',
    STORE_FRONT: '/store-front',
    STORE_FRONT_CATALOGUE_VIEW: '/store-front/view',
    CHANGE_PASSWORD: "/change-password",
    KEY_PERSON_DETAILS: "/key-person-details",
    BUSINESS_LOCATION: "/business-location",
    BUSINESS_PROFILE: "/business-profile",
    STATUTORY_DOCUMENTS: "/statutory-documents",
    PREFRENCES: "/preferences",
    MY_MATERIAL_LISTING: "/dashboard/my-material",
    FINISHED_PRODUCT: "/dashboard/finished-product",
    SCRAP_PRODUCT: "/dashboard/scrap-product",
    VENDOR_PRODUCT_PRICE_LISTING: '/dashboard/vendor/price-management/list',
    VENDOR_PRODUCT_PRICE_CREATE: '/dashboard/vendor/price-management/add',
    VENDOR_PRODUCT_PRICE_UPDATE: '/dashboard/vendor/price-management/edit',
    VENDOR_PRODUCT_PRICE_VIEW: '/dashboard/vendor/price-management/view',
    LEAD: "/lead",
    QUOTATION: "/quotation",
    LEAD_SECONDARY: "/lead-secondary",
    LEAD_SECONDARY_LOT: "/lead-secondary-lot",
    ORDER: "/order",
    ORDER_SECONDARY: "/order-secondary",
    ORDER_SECONDARY_LOT: "/order-secondary-lot",
    ONBOARDING_WELCOME: "/onboarding-welcome",
    CATALOGUE_BUNDLE: "/catalogue-bundle/view",
    BUYER_DASHBOARD: "/buyer-dashboard",
    VENDOR_CATALOGUE_DESCRIPTION: "/vendor/catalogue/view",
    VENDOR_CATALOGUE_MTO_VIEW: "/vendor/mto-catalogue/view",
    SELLER_DASHBOARD: "/seller-dashboard",
}

export const STATIC_PAGES_ROUTES = {
    PRIVACY_POLICY: "/privacy-policy",
    TERMS_AND_CONDITIONS: "/terms-and-conditions",
    SUPPORT_CARE: '/support-and-care',
    STEEL_BAZAAR_SERVICES: '/services',
    CONTACT_US: '/contact-us',
    ABOUT_US: 'about-us',
    SELL_ON_STEELBAZAAR: 'sell-on-steelbazaar',
    // BUY_ON_STEELBAZAAR: 'buy-on-steelbazaar',
    HELP_CENTER: ' help-center',
    TRADE_ASSURANCE: 'trade-assurance',
    B2B_MARKETPLACE: 'b2b-marketplace',
    SELLER_CENTRAL: 'seller-central',
    PROCUREMENT: 'procurement',
    VENDOR_CENTRAL: 'vendor-central',
    CUSTOMER_BUYER: 'customer-buyer',
    SUPPLYCHAIN_FINANCE: 'supply-chain-finance',
    AUCTION : 'auction',
    DIGISTORE: 'digi-store',
    DIGITAL_CATALOG: 'digital-catalog',
    DIGI_STOCK: 'digi-inventory',
    PARTNERSHIP:   'partnership',
    TRUST_ASSURANCE: 'trust-assurance',
    CAREER: 'career',
}

export const DEBOUNCE_TIME = {
    CART: 700,
    CATALOGUE_FILTERS: 200,
    PLP_CATEGORIES: 500
}

export const ATTRIBUTES_WITH_RANGE = ['LENGTH', 'WIDTH', 'THICKNESS']

export const API_METHOD = {
    POST: "POST",
    GET: "GET",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
};

export const CLASS_TYPES = [
    {
        id: "STANDARD",
        name: "Standard Prime"
    },
    {
        id: "NONSTANDARD",
        name: "Non Standard Prime"
    },
    {
        id: "",
        name: "Commercial Scrap"
    },
    {
        id: "",
        name: "Melting Scrap"
    },
    {
        id: "BUNDLE",
        name: "Bundle"
    }
];

export const CATALOGUE_TYPES = [
    {
        id: "CURRENT_INVENTORY",
        name: "In Stock"
    },
    {
        id: "MAKE_TO_ORDER",
        name: "Make to Order"
    },

];

export const VENDOR_CATALOGUE_TYPES = [
    {
        id: "SINGLE_PRODUCT",
        name: "In Stock"
    },
    {
        id: "MAKE_TO_ORDER",
        name: "Make to Order"
    }
];

export const VENDOR_NON_STANDARD_CATALOGUE_TYPES = [
    {
        id: "SINGLE_PRODUCT",
        name: "In Stock"
    }
    // ,
    // {
    //     id: "MAKE_TO_ORDER",
    //     name: "Make to Order"
    // }
];

export const NON_STANDARD_CATALOGUE_TYPES = [
    {
        id: "CURRENT_INVENTORY",
        name: "In Stock"
    }
    // ,
    // {
    //     id: "MAKE_TO_ORDER",
    //     name: "Make to Order"
    // }
];

export const SECONDARY_CATALOGUE_TYPES = [
    {
        id: "CURRENT_INVENTORY",
        name: "In Stock"
    },
    {
        id: "MAKE_TO_ORDER",
        name: "Lots"
    }
];

export const SHAPE_TYPES = [
    {
        id: 'SCROLL',
        name: "Scroll"
    },
    {
        id: 'BAR',
        name: "Bar"
    },
    {
        id: 'PLATE',
        name: "Plate"
    },
    {
        id: "COIL",
        name: "Coil"
    },
    {
        id: "SHEET",
        name: "Sheet"
    },
    {
        id: "STRIP",
        name: "Strip"
    },
    {
        id: "ANGLE",
        name: "Angle"
    },
    {
        id: "CHANNEL",
        name: "Channel"
    },
    {
        id: "BEAM",
        name: "Beam"
    },
    {
        id: 'PIPE',
        name: "Pipe"
    },
    {
        id: 'TUBE',
        name: "Tube"
    },
    {
        id: 'ROUND',
        name: "Round"
    },
    {
        id: 'SQUARE',
        name: "Square"
    },
    {
        id: 'RECTANGLE',
        name: "Rectangle"
    },
    {
        id: 'OTHERS',
        name: "Others"
    },
];

export const REGEX = {
    EMAIL: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    PASSWORD: /^(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*_\-+=|\\(){}[\]:;'"<>,.?/])(.{8,})$/,
    FULL_NAME: /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
    FULL_NAME_V2: /^[a-zA-Z0-9 _-]+$/,
    NAME: /^[A-Za-z',.]+(?:\s+[A-Za-z',.]+)*$/,
    PHONE_NUMBER: /^\d{10}$/,
    GSTIN: /(?=^.{15}$)[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/,
    UPPERCASE: /[A-Z]/,
    LOWERCASE: /[a-z]/,
    SYMBOL: /[!@#$%^&*(),.?":{}|<>]/,
    NUMBER: /[1-9][0-9]*/,
    FLOAT: /((\.\d{0,2})?|\.\d{1,2})$/,
    QUANTITY: /^(\d*\.?\d{0,3})?$/,
    PLP_RANGED_ATTRIBUTES: /^\d{1,4}(\.\d{0,4})?$/,
    URL: /^(ftp|http|https):\/\/[^ "]+$/,
    IFSC: /^[A-Z]{4}[0-9]{7}$/,
    ACCOUNT_NUMBER: /^\d{8,16}$/,
    CIN: /^[LU][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/,
    UDHYAM: /^[A-Za-z0-9]{12}$/,
    PAN: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    TWO_DECIMAL: /^\d+(\.\d{1,2})?$/
};

export const COLOR_DATA: IColorPalette = {
    "RAL1021": {
        "bgColor": "#F8B700",
        "labelColor": "#FFFFFF"

    },
    "RAL2008": {
        "bgColor": "#EC681A",
        "labelColor": "#FFFFFF"
    },
    "RAL5012": {
        "bgColor": "#3481B8",
        "labelColor": "#FFFFFF"
    },
    "RAL5015": {
        "bgColor": "#2874B2",
        "labelColor": "#FFFFFF"
    },
    "RAL5018": {
        "bgColor": "#21888F",
        "labelColor": "#FFFFFF"
    },
    "RAL6011": {
        "bgColor": "#6D7D5A",
        "labelColor": "#FFFFFF"
    },
    "RAL6018": {
        "bgColor": "#609839",
        "labelColor": "#FFFFFF"
    },
    "RAL7015": {
        "bgColor": "#4E5258",
        "labelColor": "#FFFFFF"
    },
    "RAL7037": {
        "bgColor": "#7D7E7C",
        "labelColor": "#FFFFFF"

    },
    "RAL9002": {
        "bgColor": "#D7D5CA",
        "labelColor": "#000000"

    },
    "RAL9003": {
        "bgColor": "#ECECE8",
        "labelColor": "#000000"
    },
    "RAL9011": {
        "bgColor": "#37393B",
        "labelColor": "#FFFFFF"
    },
    "RAL0404040": {
        "bgColor": "#8E4130",
        "labelColor": "#FFFFFF"
    },
    "RAL0709005": {
        "bgColor": "#EDE4D9",
        "labelColor": "#000000"
    }
}

export const ATTRIBUTE_LABEL = {
    COLOR: "Color",
    COATING_GRADE: "Coating Grade",
    HARDNESS: "Hardness",
    TEMPER: "Temper",
}

export const CART_LIFE_CYCLE = {
    REQUEST_QUOTATION: "REQUEST",
    REMOVE_PRODUCT: "DELETED",
    ADD_PRODUCT: "ADDED",
    LOGIN: "LOGIN",
}

export const PAYMENT_METHODS = {
    CASH_PAYMENT: "CASH_PAYMENT",
    CREDIT_PAYMENT: "CREDIT_PAYMENT"
}

export const PAYMENT_METHODS_V2 = {
    CASH: "CASH",
    CREDIT: "CREDIT"
}

export const UNIT = {
    UOM: "MT"
}

export const MAX_DISPLAY_ITEMS = {
    SPECIFICATIONS: 5,
    FAQS: 3,
}

export const SEE_MORE_LESS = {
    SEE_MORE: "See More",
    SEE_LESS: "See Less",
}

export const ATTRIBUTES_TO_HIDE = ["HARDNESS"]

export const NUMBER_URL = {
    WHATSAPP: 'https://api.whatsapp.com/send/?phone=919654555531',
    STEEL_BAZAR_NEWS: 'https://chat.whatsapp.com/EDo65eMrfcdErvheDmbCNo'
};

export const SUBJECT_TO_TERMS_DISCLAIMER = {
    SUBJECT_TO: "*Subject to",
    TERMS_CONDITION: "Terms & Condition",
    AND: "and",
    DISCLAIMER: "Disclaimer",
    SALES: "of sales"
}

export const faqItems: FAQ[] = [
    {
        question: "What product categories does your company deal in?",
        answer: "We can provide customers with different kinds of metal products, such as HR, CR, Galvanized Steel, Colour Coated Steel, Electrolytic Tinplate, Tin Free Steel, CRNO, CRGO, etc",
        expanded: false
    },
    {
        question: 'How long is your delivery time?',
        answer: 'Typically, delivery is instant for in-stock items, or 7-10 days if items are out of stock.',
        expanded: false
    },
    {
        question: 'How can I contact your company?',
        answer: 'You can reach us via email, WhatsApp, or phone. Additionally, you can visit our warehouses located in Taloja and Delhi.',
        expanded: false
    },
    {
        question: 'What types of processing services does your company provide?',
        answer: 'We offer a range of processing services in our warehouses, including shearing, slitting, blanking, printing, and many others. Please feel free to contact us for any special requirements',
        expanded: false
    },
    {
        question: 'What are your payment terms?',
        answer: 'We offer attractive payment discounts for advance and on-time delivery payments and flexible payment options. Please do not hesitate to contact us for further details',
        expanded: false
    },
];

export const FAVOURITE = {
    ADD_TO_FAVOURITE: "Add to favourite",
}

export const PAYMENT_TERMS = {
    PAYMENT_TERMS: "Payment Terms",
    OR: "OR",
}

export const PAYMENT_TERMS_SECTION: PaymentTermsSectionProps[] = [
    {
        value: PAYMENT_METHODS.CASH_PAYMENT,
        heading: "Avail Attractive Offers for Cash Down Payment",
        subHeading: "Click view to find further information on our payment terms to avail the best plan for you",
        hasNext: true,
        isAvailable: true
    },
    {
        value: PAYMENT_METHODS.CREDIT_PAYMENT,
        heading: "Avail Flexible and Convenient Credit Payment",
        subHeading: "Click view to find further information on our payment terms to avail the best plan for you",
        isAvailable: false
    }
]

export const PAYMENT_TERMS_SECTION_V2: PaymentTermsSectionProps[] = [
    {
        value: PAYMENT_METHODS_V2.CASH,
        heading: "Avail Attractive Offers for Cash Down Payment",
        subHeading: "Click view to find further information on our payment terms to avail the best plan for you",
        isAvailable: true
    },
    {
        value: PAYMENT_METHODS_V2.CREDIT,
        heading: "Avail Flexible and Convenient Credit Payment",
        subHeading: "Click view to find further information on our payment terms to avail the best plan for you",
        isAvailable: true
    }
]

export const PAYMENT_SELECTION = {
    CASH_DOWN_PAYMENT: "You’ve selected cash down payment.",
    CREDIT_PAYMENT: "You’ve selected convenient credit payment."
}

export const DELIVERY_AND_TRANSPORTATION_SECTION: DeliveryAndTransportationSectionProps[] = [
    {
        text: "Delivery",
        icon: WarehouseIcon,
        description: "Ex- Warehouse (as selected above)",
    },
    {
        text: "Transportation",
        icon: TransportationIcon,
        description: "To Be Managed by Customer",
    }
]

export const CONNECT_WITH_US = {
    TALK_TO_US: "Talk to Us",
    HELP_SUPPORT: "Help & Support",
}

export const CONNECT_WITH_US_POPUP: ContactUsPopupProps[] = [
    {
        icon: CallIcon,
        text: "Call Us",
        country: "+91",
        mobile: "9654555510",
        description: "Mon-Fri  10am to 7pm",
    },
    {
        icon: MailIcon,
        text: "Write to us",
        email: "info@abainfotech.com",
    },
    {
        icon: WriteToUsIcon,
        text: "Write to us",
        country: "+91",
        mobile: "9654555510",
    }
]

export const PRICE_TRENDS = {
    KNOW_ABOUT_PAST_PRICE_TRENDS: "Know about Past Price Trends",
}

export const CUSTOMIZTION_TYPE = {
    SLITTING: "SLITTING",
    SHEARING: "SHEARING",
    BLANKING: "BLANKING",
    METAL_OFFSET_PRINTING: "METAL_OFFSET_PRINTING"
}

export const SELECT_CUSTOMIZATION = {
    SELECT_CUSTOMIZATION: "Select Customization",
    SELECT_THE_VALUE: "Please select the value adding services you want",
    SELECTED_SERVICES: "You've selected following services:",
}

export const SLITTING_HEADING = [
    "Sno.",
    "Width (MM)",
    "Weight of Coil (MT)",
    "CTW Charges/MT",
    "Total Charges",
    "Action"
]

export const SHEARING_HEADING = [
    "Sno.",
    "Length (MM)",
    "No. of Pieces",
    "Weight/Pc (MT)",
    "Weight (MT)",
    "CTL Charges/MT",
    "Total Charges",
    "Action"
]

export const SUCCESS_FAILURE_POPUP = {
    SUCCESS: "Success!",
    DELETE_PRODUCT: "Delete Product?",
    DELETE_SERVICE: "Delete Service?",
    QUOTATION_SENT: "Your quotation has been sent!",
    WANT_TO_DELETE: "Are you sure you want to delete this?",
    DOWNLOAD_PDF: "Download PDF",
    CONTINUE: "Continue",
    YES: "Yes",
    NO: "No",
};

export const POLICY_SECTION = {
    PRIVACY_POLICY_INTRODUCTION: "Privacy Policy",
    YOUR_INFORMATION: "Your Information",
    USE_OF_INFORMATION: "Use of Information",
    DISCLOSURE_OF_INFORMATION: "Disclosure of Information",
    CHANGES_TO_YOUR_INFORMATION: "Changes to Your Information",
    SECURITY_OF_YOUR_INFORMATION: "Security of Your Information",
    EXTERNAL_LINKS_ON_THE_PLATFORM: "External Links on the Platform",
    OPT_OUT_POLICY: "Opt-Out Policy",
    GRIEVANCE_OFFICER_AND_CONTACT_INFORMATION: "Grievance Officer and Contact Information",
    APPLICABLE_LAW_AND_DISPUTE_RESOLUTION: "Applicable Law and Dispute Resolution",

    TERMS_INTRODUCTION: "Terms and Conditions of  Use",
    GENERAL: "General",
    ACCESS_TO_THE_PLATFORM: "Access to the Platform",
    YOUR_PROFILE_AND_YOUR_INFORMATION: "Your Profile and Your Information",
    USE_OF_THE_PLATFORM: "Use of the Platform",
    PAYMENTS: "Payments",
    LOGISTICS_AND_FULFILLMENT_SERVICES: "Logistics and Fulfillment Services Policy",
};

export const ATTRIBUTE_SECTION_HEADING = {
    SELECT_ATTRIBUTES: "Select Attributes",
    RESET_SELECTION: "Reset Selection",
}

export const PAYMENT_TYPES = [
    {
        value: 'CASH_PAYMENT',
        label: 'Avail more discounts, make cash payments'
    },
    {
        value: 'CREDIT_PAYMENT',
        label: 'Avail flexible credit payment plans'
    }
];

export const PAYMENT_TYPES_SELECTION = {
    CASH_PAYMENT: "You’ve selected for Cash payment.",
    CREDIT_PAYMENT: "You’ve selected for Credit payment."
}

export const TAXES_TYPE = {
    CGST: "CGST @9%",
    SGST: "SGST @9%",
    TCS: "TCS @1%"
}

export const TAXES = {
    GST: 9,
    TCS: 1
}

export const PRICE_DETAILS = (iconOverrides: Partial<IPriceDetails> = {}): IPriceDetails => {
    const defaultDetails: IPriceDetails = {
        subTotal: {
            title: "Sub Total",
            subTitle: "Incl Packaging Charges, Other Charges",
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: false,
        },
        centralGSTCharges: {
            title: "CGST @9%",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: false,
        },
        stateGSTCharges: {
            title: "SGST @9%",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: false,
        },
        warehouseCharges: {
            title: "Warehousing charges",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: true,
        },
        handlingCharges: {
            title: "Handling charges",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: true,
        },
        GST: {
            title: "Add: GST",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: false,
        },
        TCS: {
            title: "TCS @1%",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: false,
        },
    };

    return {
        ...defaultDetails,
        ...iconOverrides,
    };
};

export const VAS_SECTIONS = {
    VAS_DETAILS: "VAS_DETAILS",
    ADD_ANOTHER_SERVICE: "ADD_ANOTHER_SERVICE"
}

export const ENTITY_TYPES = [
    { id: 'Large Corporate', name: 'Large Corporate – [Above 500 Cr]'},
    { id: 'Mid Corporate', name: 'Mid Corporate – [250 Cr to 500 Cr]' },
    { id: 'Medium Enterprise', name: 'Medium Enterprise – [50 Cr to 250 Cr]'},
    { id: 'Small Enterprise', name: 'Small Enterprise – [2 Cr to 50 Cr]'},
    { id: 'Micro Enterprise', name: 'Micro Enterprise – [Below 2 Cr]'},
];

export const INDUSTRY_TYPES = [
    { label: 'Infrastructure', value: 'INFRASTRUCTURE' },
    { label: 'Construction', value: 'CONSTRUCTION' },
    { label: 'Automotive', value: 'AUTOMOTIVE' },
    { label: 'Engineering', value: 'ENGINEERING' },
    { label: 'Trade', value: 'TRADE' },
    { label: 'Distributor', value: 'DISTRIBUTOR' },
    { label: 'Energy Electricals', value: 'ENERGY_ELECTRICALS' },
    { label: 'Transportation', value: 'TRANSPORTATION' },
    { label: 'Equipments', value: 'EQUIPMENTS' },
    { label: 'Plant Machinery', value: 'PLANT_MACHINERY' },
    { label: 'Oil and Gas', value: 'OIL_AND_GAS' },
    { label: 'Consumer Durables', value: 'CONSUMER_DURABLES' },
    { label: 'Packaging', value: 'PACKAGING' }
];

export const INDUSTRY_STATES = {
    INFRASTRUCTURE: 'Infrastructure',
    CONSTRUCTION: 'Construction',
    AUTOMOTIVE: 'Automotive',
    ENGINEERING: 'Engineering',
    ENERGY_ELECTRICALS: 'Energy Electricals',
    DISTRIBUTOR: 'Distributor',
    TRADE: 'Trade',
    PLANT_MACHINERY: 'Plant Machinery',
    TRANSPORTATION: 'Transportation',
    EQUIPMENTS: 'Equipments',
    OIL_AND_GAS: 'Oil and Gas',
    CONSUMER_DURABLES: 'Consumer Durables',
    PACKAGING: 'Packaging'
};

export const initialPaginationValues: IPagination = {
    page: 0,
    size: 10,
    totalPages: 1,
    totalRecords: 0,
}

export const DOCUMENT_RESOURCE_TYPE = {
    BUSINESS_PROFILE_STATUTORY: "business_profile/statutory",
    BUSINESS_PROFILE_KEY_PERSON_DETAILS: "business_profile/key_person_details",
    BUSINESS_PROFILE_FINISHED_PRODUCT: (userId: string) => `business_profile/${userId}/finished_product`,
    BUSINESS_PROFILE_SCRAP: (userId: string) => `business_profile/${userId}/scrap`,
    VAS_MACHINE: "vas_machines"
};

export const certificateTypeOptions = [
    {
        label: "GSTIN",
        value: "GSTIN"
    },
    {
        label: "MSME",
        value: "MSME"
    },
    {
        label: "UDYAM",
        value: "UDYAM"
    }
];

export const DASHBOARD_OPTIONS = {
    LOGOUT: {
        label: "Logout",
        value: "LOGOUT"
    }
}

export const DASHBOARD_RESOURCES: {
    [key: string]: string
} = {
    MY_ACCOUNT: "My Profile",
    MY_PROFILE: "Profile Overview",
    MY_AVIGHNA: "My SteelBazaar",
    BUSINESS_ENTITY_PROFILE: "Business Profile",
    BUSINESS_LOCATION: "Business Location",
    STATUTORY_AND_LEGAL: "Statutory Documents",
    KEY_PERSON_DETAILS: "Key Person Details",
    MY_MATERIAL: "My Material",
    PREFERENCES: "Preferences",
    CHANGE_PASSWORD: "Change Password",
    REQUEST: "Enquiries",
    ORDER: "Order",
    DASHBOARD: "Dashboard",
    STANDARD: "Standard",
    SECONDARY: "Secondary",
    SECONDARY_LOT: "Secondary Lot",
    ADD_INVENTORY: "Add Inventory",
    CONTACTS: "Contacts",
    PRICE_MANAGEMENT: "Price Management",
    VALUE_ADDED_SERVICES: "Value Added Services",
    MAKE_CATALOG: "Make Catalog",
    ENQUIRIES: "Enquiries",
    ORDERS: "Orders",
    AUCTION: "Auction",
    CREATE_SALE_AUCTION: "Create Sale Auction",
    REVERSE_BUY_AUCTION: "Reverse Buy Auction",
    MANAGE_AND_VIEW_AUCTION: "Manage&View Auction",
    PARTICIPATE_IN_AUCTION: "Participate in Auction",
    BUY_LEADS: "Buy Leads",
    BRAND_CENTRE: "Brand Centre",
    INVENTORY_MANAGEMENT: "Inventory Management",
    ADD_VENDOR_PRODUCT: "Add Product",
    STORE_FRONT: ' My Storefront',
    BUYER_DASHBOARD: "Dashboard",
    AUCTIONS: "My Auctions",
    MESSAGE_CENTER: "Message Center",
    ADD_PRODUCT: "Post Product"
}

export const GENERIC_EXCEPTION_CODE = {
    DATA_NOT_FOUND: "DATA_NOT_FOUND",
    DUPLICATE_ENTRY: "DUPLICATE_ENTRY"
}

export const VENDOR_TYPE = [
    {
        value: "PRIMARY_MANUFACTURER",
        label: "Primary Manufacturer",
    },
    {
        value: "SECONDARY_MANUFACTURER",
        label: "Secondary Manufacturer",
    },
    {
        value: "DISTRIBUTOR",
        label: "Distributor",
    },
    {
        value: "TRADER",
        label: "Trader",
    }, 
    {
        value: "CHANNEL_PARTNER",
        label: "Channel Partner",
    },
    {
        value: "IMPORTERS",
        label: "Importers",
    },
    {
        value: "STEEL_SERVICE_CENTER",
        label: "Steel Service Center",
    },  
]

export const SOCIAL_LINKS = [
    { label: 'Facebook', value: 'FACEBOOK' },
    { label: 'Twitter', value: 'TWITTER' },
    { label: 'Instagram', value: 'INSTAGRAM' },
    { label: 'LinkedIn', value: 'LINKEDIN' }
];

export const PREFIX = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." }
]

export const PASSWORD_CHECKLIST = {
    uppercase: "Includes a Uppercase Letter",
    minLength: "Minimum 8 Characters",
    lowercase: "Includes a Lowercase Letter",
    symbol: "Includes a Symbol",
    number: "Includes a Number"
};

export const OTHER_PREFERENCE = [
    { id: 1, value: 'Receive updates via Whatsapp' },
    { id: 2, value: 'Would you like to join our Whatsapp community?' },
    { id: 3, value: 'Receive updates via email' },
    { id: 4, value: 'Grant permission for sharing documents for KYC and credit check' },
];

export const CREDIT_PERIOD = [
    {
        label: "3 DAYS",
        value: "3 DAYS"
    },
    {
        label: "7 DAYS",
        value: "7 DAYS"
    },
    {
        label: "10 DAYS",
        value: "10 DAYS"
    },
    {
        label: "15 DAYS",
        value: "15 DAYS"
    }
];

export const PAYMENT_PREFERENCE = [
    {
        label: "Cash on Delivery",
        value: "Cash on Delivery"
    },
    {
        label: "Credit",
        value: "Credit"
    },
    {
        label: "Debit",
        value: "Debit"
    }
];

export const COMMUNICATION_CHANNEL = [
    {
        label: "Email",
        value: "Email"
    },
    {
        label: "Phone",
        value: "Phone"
    },
    {
        label: "WhatsApp",
        value: "WhatsApp"
    }
];

export const CONTACT_PREFERENCE = [
    {
        label: "Email",
        value: "Email"
    },
    {
        label: "Phone",
        value: "Phone"
    },
    {
        label: "In Person Meeting",
        value: "In Person Meeting"
    }
];

export const LANGUAGE = [
    {
        label: "English",
        value: "English"
    },
    {
        label: "Hindi",
        value: "Hindi"
    }
];
export const SCRAP_PRODUCT_ROUTES = {
    MY_MATERIAL: "/dashboard/my-material",
    ADD_SCRAP_PRODUCT: "/dashboard/scrap-product/add",
    EDIT_SCRAP_PRODUCT: "/dashboard/scrap-product/edit",
}

export const VALUE_ADDED_SERVICE_ROUTES = {
    VALUE_ADDED_SERVICE_LIST: "/dashboard/value-added-services",
    USCS_LIST: "/dashboard/value-added-services/USCs",
    USCS_CREATE: "/dashboard/value-added-services/USCs/add",
    USCS_UPDATE: "/dashboard/value-added-services/USCs/:id/update",
    USCS_VIEW: "/dashboard/value-added-services/USCs/:id",
    MACHINES_LIST: "/dashboard/value-added-services/machines",
    MACHINES_CREATE: "/dashboard/value-added-services/machines/add",
    MACHINES_UPDATE: "/dashboard/value-added-services/machines/:id/update",
    MACHINES_VIEW: "/dashboard/value-added-services/machines/:id",
}
export const FINISHED_PRODUCTS = {
    MY_MATERIALS: "My Materials",
    FINISHED_PRODUCTS_LISTING: "Finished Products Listing",
    APPLICATION_TYPE: "Application Type",
    FINISHED_PRODUCT: "Finished Product",
    UPLOAD_IMAGE: "Upload Image",
    PRODUCT_NAME: "Product Name",
    PRODUCT_DESCRIPTION: "Product Description",
};

export const FINISHED_PRODUCTS_APPLICATION_TYPE = [
    {
        value: "FINISHED_PRODUCT",
        label: "Finished Product",
    },
    {
        value: "AUTOMOTIVE_PARTS",
        label: "Automotive Parts",
    },
    {
        value: "PACKAGING_MATERIAL",
        label: "Packaging Material",
    },
    {
        value: "CONSTRUCTION_MATERIAL",
        label: "Constructive Material",
    },
    {
        value: "CONSUMER_GOODS",
        label: "Consumer Goods",
    },
    {
        value: "ELECTRONIC_COMPONENTS",
        label: "Electronic Components",
    },
];

export const SCRAP_PRODUCTS = {
    MY_MATERIALS: "My Materials",
    SCRAP_PRODUCTS_LISTING: "Scrap Products Listing",
    CURRENT_SALES_MODE: "Current Sales Mode",
    ADD_NEW_SCRAP: "Add New Scrap",
    UPLOAD_IMAGE: "Upload Image",
    SCRAP_CATEGORY: "Scrap Category",
    TYPE_OF_SCRAP: "Type of Scrap",
    MONTHLY_GENERATED_QUANTITY: "Monthly generated quantity (MT)",
};

export const SCRAP_PRODUCTS_CATEGORY = [
    {
        value: "HMS",
        label: "Hms",
    },
    {
        value: "HOME_SCRAP",
        label: "Home Scrap",
    },
    {
        value: "INTERNAL_SCRAP",
        label: "Internal Scrap",
    },
    {
        value: "NEW_SCRAP",
        label: "New Scrap",
    },
    {
        value: "OBSOLETE_SCRAP",
        label: "Obsolete Scrap",
    },
    {
        value: "OLD_SCRAP",
        label: "Old Scrap",
    },
    {
        value: "POST_CONSUMER_SCRAP",
        label: "Post Consumer Scrap",
    },
    {
        value: "PRE_CONSUMER_SCRAP",
        label: "Pre Consumer Scrap",
    },
    {
        value: "PROMPT_SCRAP",
        label: "Prompt Scrap",
    },
    {
        value: "RECYCLING",
        label: "Recycling",
    },
    {
        value: "SCRAP_PROCESSING",
        label: "Scrap Processing",
    },
    {
        value: "STEEL_MAKING",
        label: "Steel Making",
    },
    {
        value: "STEEL_SCRAP",
        label: "Steel Scrap",
    },
]

export const SCRAP_PRODUCTS_TYPE = [
    {
        value: "ALUMINUM",
        label: "Aluminum",
    },
    {
        value: "STEEL",
        label: "Steel",
    },
    {
        value: "COPPER",
        label: "Copper",
    },
]

export const SCRAP_PRODUCTS_SALES = [
    {
        value: "ONLINE_SALES",
        label: "Online Sales",
    },
    {
        value: "ONLINE_MARKETPLACE",
        label: "Online Marketplace",
    },
    {
        value: "DIRECT_SALES",
        label: "Direct Sales",
    },
]

export const FINISHED_PRODUCT_ROUTES = {
    MY_MATERIAL: (type?: MY_MATERIAL) => `/dashboard/my-material?type=${type}`,
    ADD_FINISHED_PRODUCT: "/dashboard/finished-product/add",
    EDIT_FINISHED_PRODUCT: "/dashboard/finished-product/edit",
}

export const MATERIAL_REQUIREMENT_APPLICATION_TYPE = [
    { id: "RAW_MATERIAL", name: "Raw Material" },
    { id: "RECYCLED_PRODUCT", name: "Recycled Product" },
    { id: "MANUFACTURING_COMPONENT", name: "Manufacturing Component" },
]

export const SOURCING_PURPOSE = [
    { id: "MANUFACTURING", name: "Manufacturing" },
    { id: "RESALE", name: "Resale" },
    { id: "EXPORT", name: "Export" },
]

export const FREQUENCY = [
    { id: "DAILY", name: "Daily" },
    { id: "WEEKLY", name: "Weekly" },
    { id: "MONTHLY", name: "Monthly" },
    { id: "QUARTERLY", name: "Quarterly" },
    { id: "ANNUALLY", name: "Annually" },
];

export const MY_MATERIAL_CUSTOMIZATION_ATTRIBUTE = ["THICKNESS", "WIDTH"]

export const MODE = {
    VIEW: "VIEW",
    ADD: "ADD",
    UPDATE: "UPDATE",
    EDIT: "EDIT",
}

export const MATERIAL_REQUIREMENT_CONSTANT = {
    OTHER_PREFERENCE: "OTHER_PREFERENCE",
    BRAND_MANUFACTURER: "BRAND_MANUFACTURER",
    PRODUCT_CATEGORY: "PRODUCT_CATEGORY"
}

export const CITY_OPTIONS = [
    { id: "DELHI", label: "Delhi" },
    { id: "MUMBAI", label: "Mumbai" },
    { id: "NOIDA", label: "Noida" },
    { id: "BANGALORE", label: "Bangalore" },
    { id: "CHENNAI", label: "Chennai" },
    { id: "KOLKATA", label: "Kolkata" },
    { id: "HYDERABAD", label: "Hyderabad" },
    { id: "PUNE", label: "Pune" },
    { id: "AHMEDABAD", label: "Ahmedabad" },
    { id: "SURAT", label: "Surat" },
    { id: "LUCKNOW", label: "Lucknow" },
    { id: "JAIPUR", label: "Jaipur" },
    { id: "KANPUR", label: "Kanpur" },
    { id: "NAGPUR", label: "Nagpur" },
    { id: "BHOPAL", label: "Bhopal" },
    { id: "PATNA", label: "Patna" },
    { id: "LUDHIANA", label: "Ludhiana" },
    { id: "AGRA", label: "Agra" },
    { id: "NASHIK", label: "Nashik" },
    { id: "VADODARA", label: "Vadodara" },
    { id: "INDORE", label: "Indore" },
    { id: "COIMBATORE", label: "Coimbatore" },
    { id: "THANE", label: "Thane" },
    { id: "RANCHI", label: "Ranchi" },
    { id: "JABALPUR", label: "Jabalpur" },
    { id: "GHAZIABAD", label: "Ghaziabad" },
    { id: "VARANASI", label: "Varanasi" },
    { id: "MEERUT", label: "Meerut" },
    { id: "RAIPUR", label: "Raipur" },
    { id: "RAJKOT", label: "Rajkot" },
    { id: "JODHPUR", label: "Jodhpur" },
    { id: "KOCHI", label: "Kochi" },
    { id: "MYSORE", label: "Mysore" },
    { id: "TRIVANDRUM", label: "Trivandrum" },
    { id: "GURGAON", label: "Gurgaon" },
    { id: "FARIDABAD", label: "Faridabad" },
    { id: "ALLAHABAD", label: "Allahabad" },
    { id: "AMRITSAR", label: "Amritsar" },
    { id: "VIJAYAWADA", label: "Vijayawada" },
    { id: "AURANGABAD", label: "Aurangabad" },
    { id: "SOLAPUR", label: "Solapur" },
    { id: "GUWAHATI", label: "Guwahati" },
    { id: "TIRUCHIRAPPALLI", label: "Tiruchirappalli" },
    { id: "MADURAI", label: "Madurai" },
    { id: "SALEM", label: "Salem" },
    { id: "THIRUVANANTHAPURAM", label: "Thiruvananthapuram" },
    { id: "KOTA", label: "Kota" },
    { id: "KURUKSHETRA", label: "Kurukshetra" },
    { id: "AJMER", label: "Ajmer" },
    { id: "ALIGARH", label: "Aligarh" },
    { id: "AMBIKAPUR", label: "Ambikapur" },
    { id: "AMRAVATI", label: "Amravati" },
    { id: "ANAND", label: "Anand" },
    { id: "ANANTAPUR", label: "Anantapur" },
    { id: "ASANSOL", label: "Asansol" },
    { id: "AURANGABAD", label: "Aurangabad" },
    { id: "BILASPUR", label: "Bilaspur" },
    { id: "BOKARO", label: "Bokaro" },
    { id: "CALICUT", label: "Calicut" },
    { id: "CUDDALORE", label: "Cuddalore" },
    { id: "CUTTACK", label: "Cuttack" },
    { id: "DHANBAD", label: "Dhanbad" },
    { id: "DHARWAD", label: "Dharwad" },
    { id: "DIMAPUR", label: "Dimapur" },
    { id: "DURGAPUR", label: "Durgapur" },
    { id: "ELURU", label: "Eluru" },
    { id: "ETAWAH", label: "Etawah" },
    { id: "GANDHINAGAR", label: "Gandhinagar" },
    { id: "GAYA", label: "Gaya" },
    { id: "GORAKHPUR", label: "Gorakhpur" },
    { id: "GULBARGA", label: "Gulbarga" },
    { id: "GUNTUR", label: "Guntur" },
    { id: "GURDASPUR", label: "Gurdaspur" },
    { id: "HARIDWAR", label: "Haridwar" },
    { id: "HUBLI", label: "Hubli" },
    { id: "IMPHAL", label: "Imphal" },
    { id: "JALGAON", label: "Jalgaon" },
    { id: "JAMMU", label: "Jammu" },
    { id: "JAMSHEDPUR", label: "Jamshedpur" },
    { id: "JHANSI", label: "Jhansi" },
    { id: "JUNAGADH", label: "Junagadh" },
    { id: "KALYAN", label: "Kalyan" },
    { id: "KANCHIPURAM", label: "Kanchipuram" },
    { id: "KANNUR", label: "Kannur" },
    { id: "KARNAL", label: "Karnal" },
    { id: "KHARAGPUR", label: "Kharagpur" },
    { id: "KHARGHAR", label: "Kharghar" },
    { id: "KOLHAPUR", label: "Kolhapur" },
    { id: "KOLLAM", label: "Kollam" },
    { id: "KOTTAYAM", label: "Kottayam" },
    { id: "KURNOOL", label: "Kurnool" },
    { id: "LATUR", label: "Latur" },
    { id: "MANGALORE", label: "Mangalore" },
    { id: "MATHURA", label: "Mathura" },
    { id: "MOHALI", label: "Mohali" },
    { id: "NAGAPATTINAM", label: "Nagapattinam" },
    { id: "NANDED", label: "Nanded" },
    { id: "NELLORE", label: "Nellore" },
    { id: "PALAKKAD", label: "Palakkad" },
    { id: "PANIPAT", label: "Panipat" },
    { id: "PONDICHERRY", label: "Pondicherry" },
    { id: "ROHTAK", label: "Rohtak" },
    { id: "ROURKELA", label: "Rourkela" },
    { id: "SAHARANPUR", label: "Saharanpur" },
    { id: "SANGLI", label: "Sangli" },
    { id: "SILIGURI", label: "Siliguri" },
    { id: "SRINAGAR", label: "Srinagar" },
    { id: "THANJAVUR", label: "Thanjavur" },
    { id: "TIRUNELVELI", label: "Tirunelveli" },
    { id: "UDAIPUR", label: "Udaipur" },
    { id: "UDUPI", label: "Udupi" },
    { id: "ULHASNAGAR", label: "Ulhasnagar" },
    { id: "UTTARKASHI", label: "Uttarkashi" },
    { id: "WARANGAL", label: "Warangal" },
    { id: "YAMUNANAGAR", label: "Yamunanagar" },
    { id: "YAVATMAL", label: "Yavatmal" }
];

export const OTHER_MANUFACTURERS = {
    COMPARISON_WITH_OTHER_MANUFACTURERS: "Comparison With Other Manufacturers",
}

export const TRADE_TYPE = [
    { value: 'IMPORT', label: 'Import' },
    { value: 'EXPORT', label: 'Export' },
    { value: 'BOTH', label: 'Both' },
]

export const PREFERRED_DELIVERY_DATE = [
    { value: '1 Day', label: '1 Day' },
    { value: '3 Days', label: '3 Days' },
    { value: '5 Days', label: '5 Days' },
    { value: '7 Days', label: '7 Days' },
    { value: '9 Days', label: '9 Days' },
]

export const CATALOGUES_TYPES = {
    STANDARD_CATALOG: 'STANDARD_CATALOG',
    NONSTANDARD_CATALOG: 'NONSTANDARD_CATALOG',
    PROCESSED_PRIME: 'PROCESSED_PRIME',
    MELTING_SCRAP: 'MELTING_SCRAP'
}

export const VENDOR_CATALOGUE_ROUTES = {
   MAKE_CATALOGUE: "/dashboard/catalogue",
   ADD_CATALOGUE: "/dashboard/catalogue/add",
   EDIT_CATALOGUE: "/dashboard/catalogue/edit",
   VIEW_CATALOGUE: "/dashboard/catalogue"
}

export const TAB_MAPPING_CATALOGUES = {
    STANDARD_CATALOG: '1',
    NONSTANDARD_CATALOG: '2',
    PROCESSED_PRIME: '3',
    MELTING_SCRAP: '4'
}

export const VENDOR_PRODUCT_ROUTES = {
    POST_PRODUCT: "/dashboard/vendor/product",
    ADD_PRODUCT: "/dashboard/vendor/product/add",
    EDIT_PRODUCT: "/dashboard/vendor/product/edit",
}

export const GENERIC_FILTER_SECTIONS = {
    UPC: "UPC",
    MATERIAL_FILTER: "MATERIAL_FILTER",
    OTHER: "OTHER",
}

export const PRODUCT_SECTION = {
    MASTER_UPC: "MASTER_UPC",
    BRAND_UPC: "BRAND_UPC",
    SECONDARY: "SECONDARY",
    INVENTORY: "INVENTORY",
    VENDOR_PRODUCTS: "VENDOR_PRODUCTS"
}

export const CLASSES = [
    {
        label: "Standard",
        id: 'STANDARD'
    },
    {
        label: "Secondary",
        id: 'SECONDARY'
    },
    // {
    //   label: "Non Standard",
    //   id: 'NONSTANDARD'
    // },
]

export const ATTRIBUTES = {
    SPECIFICATION: "SPECIFICATION",
    GENERALIZATION: "GENERALIZATION",
    CLASSIFICATION: "CLASSIFICATION"
}

export const STATUS_OPTION: option[] = [
    { value: "ALL", label: "All" },
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
];

export const PACKAGING_TYPES: option[] = [
    { value: "WITHOUT_PACKAGING", label: "Bare Packaging" },
    { value: "PLASTIC_PACKAGING", label: "Plastic Packaging" },
    { value: "METAL_PACKAGING", label: "Metal Packaging" },
    { value: "WOOD_PACKAGING", label: "Wood Packaging" },
];

export const SORT_OPTION = [
    {
        id: "createdAt,asc",
        label: 'Created Date(Ascending)'
    },
    {
        id: "createdAt,desc",
        label: 'Created Date(Descending)'
    },
]

export const statusOptions: option[] = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
    { value: "DRAFT", label: "Draft" },
];
export const RESOURCE_STATUS = {
    PUBLISHED: "Published",
    ACTIVE: "Active",
    COMPLETED: "Completed",
    PARTIALLY_COMPLETED: "Partially Completed",
    ACCEPTED: "Accepted",
    PENDING: "Pending",
    ONGOING: "Ongoing",
    INACTIVE: "Inactive",
    UNPUBLISHED: "Unpublished",
    REJECTED: "Rejected",
    CANCELLED: "Cancelled",
    ADJUST_OUT:"Adjust Out",
    FAILED: "Failed",
    INVALID_SHEET: "Invalid Sheet",
    NOTPUBLISHED: "Not Published",
    OTHER: 'Other',
    DRAFT: 'Draft'

}

export const PRODUCTS_TYPES = {
    SCROLL: "Scroll",
    STANDARD: "Standard Prime",
    NON_STANDARD: "Non Standard Prime",
    SECONDARY: "Secondary",
    MAKE_TO_ORDER: "Make To Order",
    PLATE: 'Plate',
    BAR: 'Bar',
    COIL: 'Coil',
    SHEET: 'Sheet',
    STRIP: 'Strip',
    ANGLE: 'Angle',
    CHANNEL: 'Channel',
    BEAM: 'Beam',
    PIPE: 'Pipe',
    TUBE: 'Tube',
    ROUND:  'Round',
    SQUARE: 'Square',
    RECTANGLE: 'Rectangle',
    OTHERS: 'Others'
};

export const PRODUCT_CLASS_TYPES = {
    STANDARD: "STANDARD",
    NONSTANDARD: "NONSTANDARD",
    SECONDARY: "SECONDARY",
    MELTING_SCRAP: "MELTING_SCRAP"
}

export const DELIVERY_INFORMATION = [
    { value: "IMMEDIATE", label: "Immediate" },
    { value: "SEVEN_TO_TEN_DAYS", label: "7-10 Days" },
    { value: "FIFTEEN_TO_THIRTY_DAYS", label: "15-30 days" },
    { value: "THIRTY_TO_SIXTY_DAYS", label: "30-60 Days" },
    { value: "SIXTY_PLUS_DAYS", label: "60+ Days" },
];

export const PriceInformationstatusOptions: option[] = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
];

export const CATALOGUES_TYPE = {
    STANDARD: 'Standard',
    NONSTANDARD: 'Non Standard',
    SECONDARY: 'Commercial Scrap',
    MELTING_SCRAP: 'Melting Scrap'
}

export const VENDOR_PRODUCT_CLASS_TYPE = [
    { value: "STANDARD", label: "Standard Prime" },
    { value: "NONSTANDARD", label: "Non Standard Prime" },
    { value: "SECONDARY", label: "Commercial Scrap" },
    { value: "MELTING_SCRAP", label: "Melting Scrap" },
]

export const VENDOR_PRODUCT_CLASS_SELECTION_TYPE = [
    { value: "EXISTING_PRODUCT", label: "Select from Existing Product" },
    { value: "ADD_NEW_PRODUCT", label: "Add your own Product" }
]

export const VendorProductSections = {
    VENDOR_PRODUCT_PRICING: 'VENDOR_PRODUCT_PRICING',
};

export const sortOptions: option[] = [
    { value: "name,asc", label: "Name (Ascending)" },
    { value: "name,desc", label: "Name (Descending)" },
    { value: "createdAt,asc", label: "Created Date(Ascending)" },
    { value: "createdAt,desc", label: "Created Date(Descending)" },
];

export const STATUS = {
    ONGOING: "ONGOING",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    PENDING: "PENDING",
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    DRAFT: "DRAFT",
    ARCHIVED: "ARCHIVED",
    DELIVERED: "DELIVERED"
};

export const SERVICE_STATUS_OPTIONS = [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'INACTIVE', label: 'Inactive' },
];

export const catalogueStatusOptions: option[] = [
    { value: "PUBLISHED", label: "Published" },
    { value: "UNPUBLISHED", label: "Unpublished" },
]

export enum CATALOGUE_TYPE {
    SINGLE_PRODUCT = "SINGLE_PRODUCT",
    MULTIPLE_PRODUCT = "MULTIPLE_PRODUCT",
    CUSTOMISE_PRODUCT = "CUSTOMISE_PRODUCT"
}

export const radioGroupOptionCatalog = [
    { value: "SINGLE_PRODUCT", label: "Single Product" },
    { value: "MULTIPLE_PRODUCT", label: "Multiple Product (Bundling)" },
    { value: "CUSTOMISE_PRODUCT", label: "Choose Your Product" },
]

export const catalogueFilterStatusOptions: option[] = [
    { value: "PUBLISHED", label: "Published" },
    { value: "UNPUBLISHED", label: "Unpublished" },
    { value: "DRAFT", label: "Draft" },
    { value: "PENDING", label: "Pending" },
    { value: "REJECTED", label: "Rejected" }
]

export const CONTACT = [
    {
        name: "Abhinav Singla",
        mobile: "9717817357",
        country: "+91",
    }
]

export const QUANTITY_DISCOUNT = {
    QUANTITY_DISCOUNTS: "Quantity Discounts",
    QUANTITY: "Qty (MT)",
    DISCOUNT: "Disc. /MT",
}

export const PAYMENT_TERMS_REVIEW_FORM_SECTION: PaymentTermsSectionProps[] = [
    {
        value: "CASH",
        heading: "Avail Attractive Offers for Cash Down Payment",
        subHeading: "Click view to find further information on our payment terms to avail the best plan for you",
        hasNext: true,
        isAvailable: true
    },
    {
        value: "CREDIT",
        heading: "Avail Flexible and Convenient Credit Payment",
        subHeading: "Click view to find further information on our payment terms to avail the best plan for you",
        isAvailable: true
    }
]

export const paymentTermOptions = {
    CASH:"CASH",
    CASH_DOWN: "Cash Down",
    CREDIT: "Credit"
}
export const dateFilter = [
    {
        label: "All",
        value: 0
    },
    {
        label: "Past 3 Months",
        value: 3
    },
    {
        label: "Past 6 Months",
        value: 6
    },
    {
        label: "Past 1 Year",
        value: 12
    }
];

export const VENDOR_FILTER_TYPE = {
    VENDOR_FILTER: "VENDOR_FILTER",
    FILTER: "FILTER"
}

export const FORCED_POPUP_TIMER = {
   POPUP_TIME: 10000 
}

export const SHAPE_TYPES_RFQ = [
    {
        id: "COIL",
        name: "Coil"
    },
    {
        id: 'SHEET',
        name: "Sheet"
    },
    {
        id: 'PLATE',
        name: "Plate"
    },
    {
        id: 'STRIP',
        name: "Strip"
    },
    {
        id: 'OTHERS',
        name: "Others"
    }
];
