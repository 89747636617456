import React, { useEffect, useState } from 'react';
import { IImage } from '../../template/CatalogueDetail/CatalogueImage.template';
import { createUseStyles, useTheme } from 'react-jss';
import ImageGroup from '../../molecules/ImageGroup/ImageGroup';
import NoImage from '../../../assets/images/noImage.jpg'
import IosShareIcon from '@mui/icons-material/IosShare';
import CatalogueSharetemplate from '../../template/CatalogueDetail/CatalogueShare.template';

interface IImagePreviewerProps {
    images?: IImage[];
    defaultActiveIndex: number;
    height: any;
    width: any;
}

const useStyles = createUseStyles({
    container: {
        maxWidth: ({ width }: any) => width || "344px",
        display: "flex"
    },
    selectedImage: {
        width: ({ width }: any) => width || "344px",
        height: ({ height }: any) => height || "299px",
        border: ({ theme }: any) => `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    iconContainer: {
        top: "4px",
        right: "4px",
        color: "black",
    },
    dialog: {
        position: "absolute",
        top: "40px",
        left: '-10px',
    },
    "@media (max-width: 767px)": {
        selectedImage: {
            width: "100%",
            maxWidth: "336px",
            maxHeight: "250px",
        },
    }
});

const ImagePreviewer: React.FC<IImagePreviewerProps> = ({ images, height, width, defaultActiveIndex }) => {

    const theme = useTheme<Jss.Theme>();
    const classes = useStyles({ theme, height, width });

    const [activeIndex, setActiveIndexTo] = useState(defaultActiveIndex);
    const [imagesToBeDisplayed, setImagesToBeDisplayedTo] = useState<IImage[] | []>([]);
    const [open, setOpenTo] = useState<boolean>(false);

    const handleImageClick = (index: number) => {
        setActiveIndexTo(index);
    };

    useEffect(() => {
        if (images)
            setImagesToBeDisplayedTo(images?.slice(0, Math.min(4, images.length)));
    }, [images])

    const handleFavoriteClick = () => (event: any) => {
        event.stopPropagation();
        setOpenTo(true)
    };

    const onDialogClose = () => {
        setOpenTo(false);
    };

    document.body.onclick = onDialogClose;

    return (
        <div className='grid gap-y-4 w-full'>
            <div className=' relative mt-0 my-auto z-0'>
                <img className={`mx-auto object-cover rounded-xl ${classes.selectedImage}`} src={(imagesToBeDisplayed && imagesToBeDisplayed[activeIndex]?.path) ?? NoImage} alt="No Image" />
                <div className={`${classes.iconContainer} flex px-2 py-1 absolute z-20 cursor-pointer `} id="child"  onClick={(e) => e.stopPropagation()} ><IosShareIcon onClick={handleFavoriteClick()} />
                    {open &&
                        <div className={classes.dialog}>
                            <CatalogueSharetemplate open={open} onClose={onDialogClose} />
                        </div>
                    }
                </div>
            </div>
            <div className={`${classes.container} w-full mx-auto`}>
                <ImageGroup images={imagesToBeDisplayed} activeIndex={activeIndex} onClick={handleImageClick} />
            </div>
        </div>
    )
}
export default ImagePreviewer;
