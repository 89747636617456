import React, { useEffect, useState } from 'react';
import Button from '../../atoms/Button/Button';
import { CATALOGUES_TYPES, TAB_MAPPING_CATALOGUES, VENDOR_PRODUCT_ROUTES } from '../../../utils/constant';
import TabsV2, { ITabsSchema } from '../../molecules/TabsV2/TabsV2';
import { createUseStyles } from 'react-jss';
import ComingSoon from '../../atoms/ComingSoon/ComingSoon';
import { useNavigate } from 'react-router-dom';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { HTTP_STATUS, VENDOR_PRODUCTS_STATES } from '../../../utils/types';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { usePostProductService } from '../../../services/usePostProductService';
import ProductCataloguesTableViewTemplate from '../../template/VendorManagement/ProductCataloguesTableView.template';

export interface IVendorStats {
    STANDARD: number;
    NONSTANDARD: number;
}

export const initialStatsValue: IVendorStats = {
    STANDARD: 0,
    NONSTANDARD: 0
}


const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.primary.primary900,
    },
}));


const PostProductListPage: React.FC = () => {
    const initialTab = TAB_MAPPING_CATALOGUES[CATALOGUES_TYPES.STANDARD_CATALOG as TabType];
    const [activeTab, setActiveTabTo] = useState(initialTab);
    const [productStats, setProductStats] = useState<IVendorStats>(initialStatsValue);
    const productService = usePostProductService();
    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const navigate = useNavigate();

    const loadVendorProductStats = () => {
        if (user?.businessId) {
            productService.getVendorProductStats(user?.businessId, VENDOR_PRODUCTS_STATES.PRODUCT)
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK) {
                        setProductStats(res?.data?.data);
                    }
                }).catch((error) => {
                    console.error("Error Fetching Stats: ", error);
                });
        }
    }


    const handleAddNewProduct = () => {
        navigate(VENDOR_PRODUCT_ROUTES.ADD_PRODUCT);
    }

    const schema: ITabsSchema[] = [
        { label: `Standard  (${productStats.STANDARD ?? 0})`, component: <ProductCataloguesTableViewTemplate catalogueType={"STANDARD"} stats={productStats.STANDARD ?? 0}/> },
        { label: `Non Standard (${productStats.NONSTANDARD ?? 0})`, component: <ProductCataloguesTableViewTemplate catalogueType={"NONSTANDARD"} stats={productStats.NONSTANDARD ?? 0}/> },
        { label: 'Commercial Scrap', component: <ComingSoon /> },
        { label: 'Melting Scrap', component: <ComingSoon /> },
    ];

    type TabType = keyof typeof TAB_MAPPING_CATALOGUES;

    const handleTabChange = (tab: string | number) => {
        setActiveTabTo(tab.toString());
    };

    useEffect(() => {
        const currentTab = initialTab;
        setActiveTabTo(currentTab);
    }, [initialTab]);

    useEffect(() => {
        loadVendorProductStats();
    }, []);

    return (
        <div>
            <div className={`${classes.heading} text-lg font-medium`}>Post Product</div>
            <div className='flex justify-end'>
                <Button variant='primaryContained' label={"Add New Product"} className='!font-normal' onClick={handleAddNewProduct} />
            </div>
            <div className='mt-6'>
                <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
            </div>
        </div>
    );
};

export default PostProductListPage;
