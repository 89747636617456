import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { Link } from 'react-router-dom';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';
import navbarArrowIcon from '../../../../assets/icons/navbarArrowIcon.svg';
import Navbarplatform1 from '../../../../assets/images/NavbarServices01.jpg';
import Navbarplatform2 from '../../../../assets/images/Navbarplatform2.jpg';
import Navbarplatform3 from '../../../../assets/images/Navbarplatform3.jpg';
import Navbarplatform4 from '../../../../assets/images/Navbarplatform4.jpg';
import NavbarServices01 from '../../../../assets/images/NavbarServices01.jpg';
import NavabarServices9 from '../../../../assets/images/NavbarServices9.jpg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: theme.palette.background.neutral.neutral50,
        boxShadow: `0px 20px 20px 0px rgba(0, 0, 0, 0.25)`,
        position: 'absolute',
        width: '100%',
        top: '125px',
        left: '0',
        zIndex: '99',
     
    },
    primaryContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    dropdownContent: {
        "& h3": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& h4": {
            lineHeight: '22px',
            color: '#000',
            borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`
        },
        "& p": {
            color: '#000',
            position: 'relative', 
            paddingLeft: '24px', 
            "&::before": {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '8px', 
                height: '12px',
                backgroundImage: `url(${navbarArrowIcon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
        },
    },
    dropdownImgContainer: {
        border: '4px solid white',
        borderRadius: '8px',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
        overflow: 'hidden',
        '& img': {
            width: '100%',
        }, 
    },
}));

const ContactUsDropdown: React.FC = () => {
    const classes = useStyles();

    const [hoveredImage, setHoveredImage] = useState<string>(Navbarplatform1);
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);

    const imagesMap: Record<string, string> = {
        'Navbarplatform1': Navbarplatform1,
        'Navbarplatform2': Navbarplatform2,
        'Navbarplatform3': Navbarplatform3,
        'Navbarplatform4': Navbarplatform4,
        'NavabarServices9': NavabarServices9,
    };

    const handleLinkClick = () => {
        setIsDropdownOpen(false);
      };
    
      if (!isDropdownOpen) return null; 

    return (
        <div className={`${classes.container} py-10`}>
            <div className={`${classes.primaryContainer} flex justify-between items-center`}>
                <div className='flex w-full gap-[15px]'>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>  <Link to={`${STATIC_PAGES_ROUTES.CONTACT_US}`} onClick={handleLinkClick}>Customer Support</Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CONTACT_US}`} onClick={handleLinkClick}>Phone & Email Chat Support</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'> <Link to={`${STATIC_PAGES_ROUTES.CONTACT_US}`} onClick={handleLinkClick}>Office Location</Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform2'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CONTACT_US}`} onClick={handleLinkClick}>Principal Office</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform3'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CONTACT_US}`} onClick={handleLinkClick}>Regional Offices</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'><Link to={`${STATIC_PAGES_ROUTES.PARTNERSHIP}`}>Partnerships </Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform4'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.PARTNERSHIP}/?component=channel-trade-partner`} onClick={handleLinkClick}>Channel Trade Partners</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform2'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.PARTNERSHIP}/?component=finance-partners`} onClick={handleLinkClick}>Finance Partners</Link>
                                </p>
                          
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform3'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.PARTNERSHIP}/?component=logistic-partners`} onClick={handleLinkClick}>Logistic Partners</Link>
                                </p>                      
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'> <Link to={`${STATIC_PAGES_ROUTES.CAREER}`} onClick={handleLinkClick}>Careers</Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CAREER}/?component=job-opening`} onClick={handleLinkClick}>Job Openings</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavabarServices9'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CAREER}/?component=life-at-steelbazaar`} onClick={handleLinkClick}>Life At SteelBazaar</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.dropdownImgContainer} rounded-lg w-1/4 h-fit`}>
                        {hoveredImage && <img src={hoveredImage} alt="Hovered Image" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsDropdown;
