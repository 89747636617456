import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: theme.palette.background.neutral.neutral50,
        boxShadow: `0px 20px 20px 0px rgba(0, 0, 0, 0.25)`,
        position: 'absolute',
        width: '100%',
        top: '146px',
        left: '0',
        zIndex: '99',
    },
    primaryContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    heading: {
        color: theme.palette.text.primary.primary700,
        fontSize: '32px',
    },
    subText: {
        color: theme.palette.text.neutral.neutral600,
    },
    memberOptions: {
        width: '320px',
        background: 'rgba(231, 246, 254, 0.50)',
        "& span": {
            color: theme.palette.text.primary.primary600,
        }
    },
}));



const MembershipDropdown: React.FC = () => {
    const classes = useStyles();

    return (
        <div></div>
    );
};

export default MembershipDropdown;
