import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { Link } from 'react-router-dom';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';
import navbarArrowIcon from '../../../../assets/icons/navbarArrowIcon.svg';
import NavbarServices5 from '../../../../assets/images/Navbarplatform4.jpg';
import NavbarServices6 from '../../../../assets/images/NavbarServices6.jpg';
import Navbarplatform4 from '../../../../assets/images/Navbarplatform4.jpg';
import Navbarplatform5 from '../../../../assets/images/Navbarplatform5.png';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: theme.palette.background.neutral.neutral50,
        boxShadow: `0px 20px 20px 0px rgba(0, 0, 0, 0.25)`,
        position: 'absolute',
        width: '100%',
        top: '125px',
        left: '0',
        zIndex: '99',
      
    },
    primaryContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    dropdownContent: {
        "& h3": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& h4": {
            lineHeight: '22px',
            color: '#000',
            borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`
        },
        "& p": {
            color: '#000',
            position: 'relative', 
            paddingLeft: '24px', 
            "&::before": {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '8px', 
                height: '12px',
                backgroundImage: `url(${navbarArrowIcon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
        },
    },
    dropdownImgContainer: {
        border: '4px solid white',
        borderRadius: '8px',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
        overflow: 'hidden',
        '& img': {
            width: '100%',
        },
    },
}));

const BuyerDropdown: React.FC = () => {
    const classes = useStyles();

    const [hoveredImage, setHoveredImage] = useState<string>(Navbarplatform4);
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);

    const imagesMap: Record<string, string> = {
        'NavbarServices5': NavbarServices5,
        'NavbarServices6': NavbarServices6,
        'Navbarplatform4': Navbarplatform4,
        'Navbarplatform5': Navbarplatform5,
    };

    const handleLinkClick = () => {
        setIsDropdownOpen(false);
      };
    
      if (!isDropdownOpen) return null; 

    return (
        <div className={`${classes.container} py-10`}>
            <div className={`${classes.primaryContainer} flex justify-between items-center`}>
                <div className='flex w-full gap-[15px]'>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'><Link to={`${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=features`} onClick={handleLinkClick}>Why Buy on SteelBazaar? </Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform4'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=features`} onClick={handleLinkClick}>Benefits & Features</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'><Link to={`${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=Registration`} onClick={handleLinkClick}>How to Get Started</Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices6'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=Registration`} onClick={handleLinkClick}>Buyer Registration Process</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform4'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=catalogue`} onClick={handleLinkClick}>Catalogue Sales</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices6'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=instant-quotation`} onClick={handleLinkClick}>Placing RFQs & Orders</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'><Link to={`${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=features`} onClick={handleLinkClick}>Advanced Buying Tools </Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform4'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=features`} onClick={handleLinkClick}>Buyer Exclusive Features</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        {/* <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Case Studies</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Successful Buyer Experience</p>
                            </div>
                        </div> */}
                    </div>
                    <div className={`${classes.dropdownImgContainer} rounded-lg w-1/4 h-fit `}>
                        {hoveredImage && <img src={hoveredImage} alt="Hovered Image" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuyerDropdown;
