import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Checkbox, TablePagination, IconButton, Input, useTheme } from '@mui/material';
import { ADVANCE_FILTER_TYPE, HTTP_STATUS, IPagination } from "../../../utils/types";
import { ICatalogueAttributes, IMaterialFilterData } from "./GenericSerachDialog.template";
import { initialPaginationValues } from "../../../utils/constant";
import { enumToString, showAttributesUom } from "../../../utils/helper";
import CloseIcon from '@mui/icons-material/Close';
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { IProductFilterRequest, IProductSearchParams, useGenericSearchService } from "../../../services/useGenericSearchService";
import { IMultiSelectOption } from "../../atoms/MultiSelectInput/MultiSelectInput2";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import ChipsV3, { ChipItem } from "../../molecules/Chips/ChipsV3";

interface ProductSelectionFormTemplateProps {
    materialFilters: IMaterialFilterData;
    sectionType: string;
    setFilterData: (materialFilters: IMaterialFilterData) => void;
    onFilterSubmit: () => void;
    handleClose: () => void;
    isSingleSelect?: boolean;
    advanceFilterType?: string;
    classType?: string;
}

interface IProducts {
    productCode: string;
    productId: number;
    status: string;
    attributes: { [key: string]: ProductSpecification[] };
    title: string;
}

interface ProductSpecification {
    name: string;
    minValue: string;
    maxValue: string;
    uom: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    chipContainer: {
        width: "100%"
    },
    activeProgressBar: {
        backgroundColor: "#CA4F02"
    },
    inActiveProgressBar: {
        backgroundColor: "#FD6202"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    heading: {
        color: theme.palette.text.primary.primary900,
    },
    subHeading: {
        color: theme.palette.text.primary.primary500,
    },
    values: {
        color: theme.palette.text.primary.primary900,
    },
    paginationTable: {
        "& .MuiTablePagination-selectLabel": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: '1px solid #E6E6E6',
            width: '80px',
            paddingRight: '10px',
            marginRight: "24px",
            height: "30px"
        },
        "& .MuiTablePagination-displayedRows": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-spacer": {
            flex: 0
        },
        "& .MuiToolbar-root": {
            paddingLeft: "0px !important",
            paddingRight: "0px",
            width: "100%"
        },
    },
    paginationComponent: {
        color: theme.palette.text.neutral.neutral100,
        fontWeight: 500,
        fontSize: "14px",
        width: "100%",
        justifyContent: 'end',
    },
    statusActive: {
        color: theme.palette.text.success.success500
    },
    statusInactive: {
        color: theme.palette.text.success.success500
    },
    statusActiveContainer: {
        borderRadius: "24px",
        backgroundColor: theme.palette.text.success.success50
    },
    statusInactiveContainer: {
        borderRadius: "24px",
        backgroundColor: theme.palette.text.success.success500
    },
    attributeName: {
        color: theme.palette.text.primary.primary400
    },
    attributeValue: {
        color: theme.palette.text.primary.primary500
    },
    border: {
        borderBottom: `1px solid ${theme.palette.border.primary.primary50}`,
    },
    checkboxText: {
        "&.MuiCheckbox-root.Mui-checked": {
            color: theme.palette.text.primary.primary500,
        },
    },
    inputBar: {
        width: "54px",
        height: "28px",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.border.neutral.neutral50}`,
        paddingLeft: "16px"
    }

}));

const ProductSelectionFormTemplate: React.FC<ProductSelectionFormTemplateProps> = ({ materialFilters, sectionType, setFilterData, onFilterSubmit, handleClose, isSingleSelect, advanceFilterType = ADVANCE_FILTER_TYPE.PRODUCTS, classType }) => {
    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const [products, setProductsTo] = useState<IProducts[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);

    const genericSearchService = useGenericSearchService();

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            page: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            size: newRowsPerPage
        }));
    };

    const [filter, setFilter] = useState<IProductFilterRequest>({});

    const refreshProducts = (page: number, size: number) => {
        let params: IProductSearchParams = {
            page: page,
            size: size,
            sort: materialFilters?.sort?.[0]?.value.toString() ?? 'createdAt,desc',
            sectionType: sectionType
        }
        if(advanceFilterType === ADVANCE_FILTER_TYPE.PRODUCTS){
            genericSearchService.searchProduct(params, filter)
            .then((response) => {
                if (response.data.data && response.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = response?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setProductsTo(response?.data?.data?.content);
                }
                else {
                    setProductsTo([]);
                }
            })
            .catch((error) => {
                console.error("Brands fetching error - ", error);
            });
        }
        else if(advanceFilterType === ADVANCE_FILTER_TYPE.VENDOR_PRODUCTS && user?.businessId){
            params = {
                ...params,
                classType: classType || "STANDARD"
            }
            genericSearchService.searchProductByvendorId(user?.businessId, params, filter)
            .then((response) => {
                if (response.data.data && response.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = response?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setProductsTo(response?.data?.data?.content);
                }
                else {
                    setProductsTo([]);
                }
            })
            .catch((error) => {
                console.error("Products fetching error - ", error);
            });
        }
        
    }

    useEffect(() => {
        setFilter({
            productCode: materialFilters?.productCode?.length ? materialFilters.productCode.map((superCategory: any) => superCategory.value) : null,
            superCategory: materialFilters?.superCategory?.length ? materialFilters.superCategory.map((superCategory: any) => superCategory.value) : null,
            mainCategory: materialFilters?.mainCategory?.length ? materialFilters.mainCategory.map((mainCategory: any) => mainCategory.value) : null,
            productCategory: materialFilters?.productCategory?.length ? materialFilters.productCategory.map((productCategory: any) => productCategory.value) : null,
            manufacturer: materialFilters?.manufacturer?.length ? materialFilters.manufacturer.map((manufacturer: any) => manufacturer.value) : null,
            brand: materialFilters?.brand?.length ? materialFilters.brand.map((brand: any) => brand.value) : null,
            standard: materialFilters?.standard?.length ? materialFilters.standard.map((standard: any) => standard.value) : null,
            grade: materialFilters?.grade?.length ? materialFilters.grade.map((grade: any) => grade.value) : null,
            shape: materialFilters?.shape?.length ? materialFilters.shape.map((shape: any) => shape.value) : null,
            classType: materialFilters?.classes?.length ? materialFilters.classes.map((classes: any) => classes.value) : null,
            customer: materialFilters?.customer?.length ? materialFilters.customer.map((customer: any) => customer.value) : null,
            status: materialFilters?.status?.length ? materialFilters.status?.[0]?.value.toString() : null,
            attributes: Object.keys(materialFilters?.attributes ?? {}).length === 0 ? null : materialFilters?.attributes,
            isRootVendorProducts: true
        })
    }, [materialFilters])

    useEffect(() => {
        setFilter((prevFilter: IProductFilterRequest) => ({
            ...prevFilter,
            classType: classType ? [classType] : []
        }));
    }, [classType]);
    
    useEffect(() => {
        if (Object.keys(filter).length !== 0) {
            refreshProducts(pagination.page, pagination.size);
        }
    }, [pagination.page, pagination.size, filter]);

    const updateSelected = (data: number) => {
        if (data) {
            if (isSingleSelect) {
                setFilterData({
                    ...materialFilters, productIds: [data]
                });
            }
            else {
                const filterExists = materialFilters?.productIds?.some((item: any) => item === data);
                if (!filterExists) {
                    const existingProductIds = materialFilters?.productIds;
                    setFilterData({
                        ...materialFilters,
                        productIds: Array.isArray(existingProductIds)
                            ? [...existingProductIds, data]
                            : [data]
                    });

                } else {

                    const existingProductIds = materialFilters?.productIds;
                    setFilterData({
                        ...materialFilters,
                        productIds: Array.isArray(existingProductIds)
                            ? existingProductIds.filter((filterItem: any) => filterItem !== data)
                            : []
                    });
                }
            }
        }
    };

    const handleFilterChipDelete = (value: ChipItem, key: string, subKey: string) => {
        if (key === 'attributes') {
            const existingFilter = materialFilters?.[key] as { [key: string]: ICatalogueAttributes; };
            const attributes: { [key: string]: ICatalogueAttributes; } = existingFilter;
            if (existingFilter && existingFilter?.[subKey]?.values?.length) {
                attributes[subKey] = {
                    minValue: null,
                    maxValue: null,
                    values: existingFilter[subKey]?.values?.filter((item: string) => item !== value.label) ?? []
                };
            }

            setFilterData({
                ...materialFilters,
                attributes: Object.keys(attributes).length === 0 ? null : attributes
            })
        } else if (key === 'productIds') {
            const existingFilter = materialFilters?.productIds as number[];
            setFilterData({
                ...materialFilters,
                productIds: Array.isArray(existingFilter)
                    ? existingFilter.filter((filterItem: any) => filterItem !== value.label) ?? [] as number[]
                    : []
            });

        } else if (key !== 'classes') {
            const existingFilter = materialFilters?.[key] as IMultiSelectOption[];
            setFilterData({
                ...materialFilters,
                [key]: Array.isArray(existingFilter)
                    ? existingFilter.filter((filterItem: any) => filterItem.label !== value.label) ?? [] as IMultiSelectOption[]
                    : []
            });
        }

    }

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
        const [inputPage, setInputPage] = useState(page + 1);

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>,
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        const handleInputPageChange = (event: any) => {
            setInputPage(parseInt(event.target.value, 10));

        };
        const handleInputBlur = (event: any) => {
            onPageChange(event, inputPage - 1);
        };

        return (
            <div className={`flex gap-x-6 ${classes.paginationComponent}`}  >
                <div className="flex gap-x-2.5">
                    <div className='my-auto'>Page</div>
                    <div className='my-auto'>
                        <Input
                            type="number"
                            value={inputPage}
                            onChange={handleInputPageChange}
                            onBlur={handleInputBlur}
                            disableUnderline={true}
                            inputProps={{ min: 1, max: Math.ceil(count / rowsPerPage) }}
                            className={classes.inputBar} />
                    </div>
                    <div className='my-auto'>of {Math.ceil(count / rowsPerPage)}</div>
                </div>

                <div className={`flex`}>
                    <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                    <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                    <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </div>
            </div>
        );
    }

    const renderChips = (data: any) => {
        return Object.entries(data).map(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.length === 0) {
                    return null;
                }
                if (key === 'productIds') {
                    return <></>;
                }
                return (
                    <div key={key} className='flex gap-x-2'>
                        <ChipsV3 values={value} onDelete={(value) => handleFilterChipDelete(value, key, '')} />
                    </div>
                );
            }
            if (typeof value === 'object' && value !== null) {
                return (
                    <div key={key} className='flex gap-x-2'>
                        {Object.entries(value).map(([subKey, subValue]) => (
                            <div key={subKey}>
                                {Array.isArray(subValue.values) && subValue.values.length > 0 && (
                                    <>
                                        <ChipsV3 values={subValue.values.map((option: any) => ({ 'label': option, 'id': option }))} onDelete={(value) => handleFilterChipDelete(value, key, subKey)} />
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                );
            }

            return null;
        });
    };

    const checkHandler = (id: number): boolean => {
        return materialFilters?.productIds?.includes(id) ?? false;
    }

    return (
        <div>
            <div className="flex flex-col">
                <div className="flex justify-between">
                    <div className={`${classes.heading} text-2xl font-medium my-auto`}>Search and Select UPC</div>
                    <div className={`cursor-pointer`} onClick={handleClose}>
                        <CloseIcon />
                    </div>
                </div>
                <div className={`${classes.subHeading} text-sm `}>
                    {pagination.totalRecords} Results Available
                </div>
            </div>
            <div className={`${classes.border} mt-10`}></div>
            <div className={classes.chipContainer}>
                <div className="flex gap-3 mt-4 flex-wrap">{renderChips(materialFilters)}</div>
            </div>
            <div >
                {products?.map((product, index) => (
                    <div key={index} className="">
                        <div className={`${classes.container} rounded-lg p-4 mt-4`}>
                            <div className={`flex gap-x-3`}>
                                <Checkbox
                                    className={`${classes.checkboxText}`}
                                    checked={checkHandler(product.productId)}
                                    onChange={() => updateSelected(product.productId)}
                                />
                                <div className='flex w-full'>
                                    <div className='flex flex-col flex-grow'>
                                        <div className={`${classes.heading} text-base font-medium`}>
                                            {product.productCode}
                                        </div>
                                        {product.title && (
                                            <div className={`${classes.subHeading} text-sm`}>
                                                {product.title}
                                            </div>
                                        )}
                                    </div>
                                    <div className='flex items-center'>
                                        <div className={`${product?.status === 'INACTIVE' ? classes.statusInactiveContainer : classes.statusActiveContainer} flex justify-center`}>
                                            <div className={`${product?.status === 'INACTIVE' ? classes.statusInactive : classes.statusActive} text-sm mx-4 my-2`}>
                                                {enumToString(product?.status ?? '')}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className={`${classes.border} my-3`}></div>
                            <div className="grid gap-y-4">
                                <div className="flex gap-x-6">
                                    {product?.attributes?.productSpecification?.map((spec, index) => (
                                        <div key={index} className=" flex flex-col">
                                            <div className={`${classes.attributeName} text-xs`}>{spec.name}</div>
                                            <div className={`${classes.attributeValue} flex items-center justify-center text-sm font-medium`}>
                                                {showAttributesUom(spec.minValue, spec.maxValue, spec.uom)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <TablePagination
                className={`${classes.paginationTable} w-full mt-4 flex`}
                component="div"
                rowsPerPageOptions={[5, 10, 15]}
                count={pagination.totalRecords}
                page={pagination.page}
                onPageChange={handlePaginationChange}
                rowsPerPage={pagination.size}
                ActionsComponent={TablePaginationActions}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Rows per page"
            />

        </div>
    );
};

export default ProductSelectionFormTemplate;
