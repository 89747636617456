import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import MenuIcon from "@mui/icons-material/Menu";
import NavbarDropdownTemplate from './NavbarDropdowns/NavbarDropdown.template';
import CategoriesDropdown from './NavbarDropdowns/CategoriesDropdown';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
    },
    primaryContainer: {},
    homeSection: {
        borderRight: `1px solid ${theme.palette.border.primary.primary700}`,
        cursor: 'pointer',
        color: theme.palette.text.primary.primary700,
    },
    categories: {
        color: theme.palette.text.neutral.neutral600,
    },
    homemenu: {
        position: 'relative',
        color: theme.palette.text.primary.primary700,
        cursor: 'pointer',
        '&:hover $dropDownContent': {
            visibility: 'visible',  
            opacity: 1,  
            transition: 'opacity 0.3s ease-in-out',
        },
    },
    dropDownContent: {
        visibility: 'hidden', 
        opacity: 0,  
        position: 'absolute',
        left: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1000,
        top: '100%', 
        width: '100%',
        transition: 'visibility 0s, opacity 0.3s ease-in-out',  
    },
}));

const NavbarOptions: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.container}`}>
            <div className={`${classes.primaryContainer} container mx-auto lg:px-4`}>
                <div className="flex gap-x-2 items-center WebNavigation py-[9px]">
                    <div className={`${classes.homemenu} flex gap-2`}>
                        <MenuIcon />
                        <div className={`font-medium text-base ${classes.homeSection} ${classes.homemenu} pr-3 relative`}>
                            Categories
                        </div>
                        <div className={`${classes.dropDownContent} CatMenuDropBox`}>
                            <CategoriesDropdown />
                        </div>
                    </div>
                    <NavbarDropdownTemplate />
                </div>
            </div>
        </div>
    );
};

export default NavbarOptions;
