import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useCategoryService } from '../../../../services/useCategoryService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { CATALOGUE_TYPE_STATES, CLASS_TYPE, HTTP_STATUS } from '../../../../utils/types';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface ICategoryFilter {
  id: number;
  name: string;
  path: string | null;
  subCategories?: ICategoryFilter[];
}

const useStyles = createUseStyles((theme: any) => ({
  primaryContainer: {
    background: '#F2F2F3',
    height: '500px',
    marginTop: '13px',
  },
  containers: {
    background: '#F2F2F3',
    zIndex: 999,
    position: 'relative',
    top: '20px',
    marginTop: '5px',
    width: '100%',
    height: '100%',
    boxShadow: `0px 20px 20px 0px rgba(0, 0, 0, 0.25)`,
  },
  navigationLevel1: {
    borderRight: `1px solid ${theme.palette.border.neutral.neutral50}`,
    position: 'relative',
    '& ul': {
      listStyle: 'none',
      borderRight: `1px solid ${theme.palette.border.neutral.neutral50}`,
    },
    '& a': {
      display: 'block',
      position: 'relative',
      padding: '1em',
      color: theme.palette.text.neutral.neutral900,
      textDecoration: 'none',
      fontSize: '14px',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        right: '15px',
        transform: 'translateY(-50%)',
      },
      '&:hover': {
        background: '#fff',
        borderRight: `1px solid ${theme.palette.border.neutral.neutral50}`,
        color: theme.palette.text.primary.primary500,
      },
    },
  },
  navigationLevel2: {
    '& ul': {
      background: '#F2F2F3',
      display: 'none',
      position: 'absolute',
      top: 0,
      left: '100%',
      borderRight: `1px solid ${theme.palette.border.neutral.neutral50}`,
      zIndex: 1000,
    },
    '&:hover > ul': {
      display: 'block',
      zIndex: 2000,
      height: '100%',
    },
  },
  navigationLevel1Hovered: {
    '& > a': {
      color: theme.palette.text.primary.primary500,
      backgroundColor: theme.palette.background.neutral.neutral100,
    },
  },
  lastDropdownMenu: {
    flexWrap: 'wrap',
    gap: '10px',
    position: 'absolute',
    top: 0,
    left: '100%',
    background: 'white',
    zIndex: 3000,
    padding: '10px',
  },
  lastLevel: {
    display: 'inline-block',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  lastCategoryItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '15px',
  },
  productImg: {
    width: '100%',
    height: 'auto',
    maxWidth: '180px',
    display: 'block',
  },
  imgcontainer: {
    width: '120px !important',
    height: '102px !important',
    borderRadius: '100% !important',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  productName: {
    fontSize: '14px',
    color: theme.palette.text.neutral.neutral600,
  },
}));

const CategoriesDropdown: React.FC = () => {
  const classes = useStyles();
  const CategoryService = useCategoryService();
  const { showSnackbar } = useSnackbar();
  const [categoriesFilter, setCategoriesFilter] = useState<ICategoryFilter[]>([]);
  const [loading, setLoading] = useState(true);
  const [hoveredParents, setHoveredParents] = useState<Set<number>>(new Set());
  const [topLevelHovered, setTopLevelHovered] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    getCategoriesFilters();
  }, []);

  const getCategoriesFilters = async () => {
    try {
      const categoriesFiltersResponse = await CategoryService.getCategoriesFilters();
      if (categoriesFiltersResponse.status === 200 && categoriesFiltersResponse.data) {
        const categoriesData = categoriesFiltersResponse.data.data;
        setCategoriesFilter(categoriesData);
      } else {
        showSnackbar('error', 'No categories found');
      }
    } catch (error) {
      showSnackbar('error', 'Failed to fetch categories');
    } finally {
      setLoading(false);
    }
  };

  const handleMouseEnter = (id: number) => {
    setHoveredParents((prev) => new Set(prev).add(id));
  };

  const handleMouseLeave = (id: number) => {
    setHoveredParents((prev) => {
      const updated = new Set(prev);
      updated.delete(id);
      return updated;
    });
  };

  const handleTopLevelMouseEnter = (id: number) => {
    setTopLevelHovered(id);
  };

  const handleTopLevelMouseLeave = () => {
    setTopLevelHovered(null);
  };

  const handleProductNavigation = (id: string) => {
    navigate(`/catalogue/list?classes=${CLASS_TYPE.STANDARD}&catalogueTypes=${CATALOGUE_TYPE_STATES.CURRENT_INVENTORY}&categoryId=${id}`);
  };

  const rawCategory = {
    id: 0,
    name: (
      <>
        Raw / Semi Finished
        <ArrowForwardIosIcon
          style={{
            position: 'absolute',
            top: '50%',
            right: '15px',
            transform: 'translateY(-50%)',
            fontSize: '16px',
          }}
        />
      </>
    ),
    path: null,
    subCategories: categoriesFilter,
  };

  const finishedProductsCategory = {
    id: 1,
    name: (
      <>
        Finished Products
        <ArrowForwardIosIcon
          style={{
            position: 'absolute',
            top: '50%',
            right: '15px',
            transform: 'translateY(-50%)',
            fontSize: '16px',
          }}
        />
      </>
    ),
    path: null,
    subCategories: [],
  };


  const mainCategory = (categories: ICategoryFilter[]) => (
    <ul className={`${classes.navigationLevel1} CatMenuL2`}>
      {categories.map((category) => (
        <li
          key={category.id}
          className={`${classes.navigationLevel2} ${hoveredParents.has(category.id) ? classes.navigationLevel1Hovered : ''
            }`}
          onMouseEnter={() => handleMouseEnter(category.id)}
          onMouseLeave={() => handleMouseLeave(category.id)}
        >
          <a href={category.path || '#'}>
            {category.name}
            <ArrowForwardIosIcon
              style={{
                position: 'absolute',
                top: '50%',
                right: '15px',
                transform: 'translateY(-50%)',
                fontSize: '16px',
              }}
            />
          </a>
          {category.subCategories && superCategory(category.subCategories)}
        </li>
      ))}
    </ul>
  );

  const superCategory = (subCategories: ICategoryFilter[]) => (
    <ul className='CatMenuL3'>
      {subCategories.map((subCategory) => (
        <li
          key={subCategory.id}
          className={`${classes.navigationLevel2} ${hoveredParents.has(subCategory.id) ? classes.navigationLevel1Hovered : ''
            }`}
          onMouseEnter={() => handleMouseEnter(subCategory.id)}
          onMouseLeave={() => handleMouseLeave(subCategory.id)}
        >
          <a href={subCategory.path || '#'}>
            {subCategory.name}
            <ArrowForwardIosIcon
              style={{
                position: 'absolute',
                top: '50%',
                right: '15px',
                transform: 'translateY(-50%)',
                fontSize: '16px',
              }}
            />
          </a>
          {subCategory.subCategories && productCategory(subCategory.subCategories)}
        </li>
      ))}
    </ul>
  );

  const productCategory = (subCategories: ICategoryFilter[]) => (
    <ul className={`${classes.lastDropdownMenu} CatMenuLast xl:px-4`}>
      {subCategories.map((subCategory) => (
        <li key={subCategory.id} className={classes.lastLevel}>
          <div className={classes.lastCategoryItem} onClick={() => handleProductNavigation(subCategory.id.toString())}>
            {subCategory.path && (
              <div className={classes.imgcontainer}>
                <img src={subCategory.path} alt={subCategory.name} className={classes.productImg} />
              </div>
            )}
            <span className={classes.productName}>{subCategory.name}</span>
          </div>
        </li>
      ))}
    </ul>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`${classes.primaryContainer} CatMenuDropBox`}>
      <div className={classes.containers}>
        <div className={`${classes.navigationLevel1} CatMenuItems`}>
          <div className={`${classes.navigationLevel1} CatMenuL1`}>
            <div
              className={`${classes.navigationLevel2} ${topLevelHovered === rawCategory.id ? classes.navigationLevel1Hovered : ''
                }`}
              onMouseEnter={() => handleTopLevelMouseEnter(rawCategory.id)}
              onMouseLeave={handleTopLevelMouseLeave}
            >
              <a href="#">{rawCategory.name}</a>
              {mainCategory(rawCategory.subCategories || [])}
            </div>
            <div
              className={`${classes.navigationLevel2} ${topLevelHovered === finishedProductsCategory.id ? classes.navigationLevel1Hovered : ''
                }`}
              onMouseEnter={() => handleTopLevelMouseEnter(finishedProductsCategory.id)}
              onMouseLeave={handleTopLevelMouseLeave}
            >
              <a href="#">{finishedProductsCategory.name}</a>
              {mainCategory(finishedProductsCategory.subCategories || [])}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesDropdown;
