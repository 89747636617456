import Drawer from '@mui/material/Drawer';
import React, { useMemo } from "react";
import MaterialFilterTemplate from './MaterialFilterSection.template';
import ProductCodeSearchFormTemplate from './ProductCodeSearch.template';
import OtherFilterFormTemplate from './OtherFilterSection.template';
import ProductSelectionFormTemplate from './ProductSelection.template';
import { GENERIC_FILTER_SECTIONS } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';
import CloseIcon from '@mui/icons-material/Close';
import { IMultiSelectOption } from '../../atoms/MultiSelectInput/MultiSelectInput2';
import Button from '../../atoms/Button/Button';

export interface ProductSearchDialogTemplateProps {
  sections: string[];
  productType: string;
  filterData: IMaterialFilterData;
  setFilterData: (filterData: any) => void;
  handleFilterClear: () => void;
  onFilterSubmit: () => void;
  triggerButton: React.ReactNode;
  isSingleSelect?: boolean;
  advanceFilterType?: string;
  classType?: string
}

export interface IData {
  [key: string]: IMultiSelectOption[] | [] | number[] | IMultiSelectOption | null | undefined | { [key: string]: ICatalogueAttributes; } | string[];
}
export interface IMaterialFilterData extends IData {
  productCode?: IMultiSelectOption[] | null;
  superCategory?: IMultiSelectOption[] | null,
  mainCategory?: IMultiSelectOption[] | null,
  productCategory?: IMultiSelectOption[] | null,
  manufacturer?: IMultiSelectOption[] | null,
  brand?: IMultiSelectOption[] | null,
  standard?: IMultiSelectOption[] | null,
  grade?: IMultiSelectOption[] | null,
  shape?: IMultiSelectOption[] | null,
  classes?: IMultiSelectOption[] | null,
  customer?: IMultiSelectOption[] | null,
  status?: IMultiSelectOption[] | null,
  sort?: IMultiSelectOption[] | null,
  attributes?: { [key: string]: ICatalogueAttributes; } | null,
  productIds?: number[] | null,
  classType?: string[] | IMultiSelectOption[] | null;
}

// export interface IMaterialFilterData {
//   productCode?: AdvanceFilterSchema | null;
//   superCategory?: AdvanceFilterSchema | null,
//   mainCategory?: AdvanceFilterSchema | null,
//   productCategory?: AdvanceFilterSchema | null,
//   manufacturer?: AdvanceFilterSchema | null,
//   brand?: AdvanceFilterSchema | null,
//   standard?: AdvanceFilterSchema | null,
//   grade?: AdvanceFilterSchema | null,
//   shape?: AdvanceFilterSchema | null,
//   classes?: AdvanceFilterSchema | null,
//   customer?: AdvanceFilterSchema | null,
//   status?: AdvanceFilterSchema | null,
//   sort?: AdvanceFilterSchema | null,
//   attributes?: AdvanceFilterSchema | null,
//   productIds?: AdvanceFilterSchema | null,
// }

// export interface AdvanceFilterSchema {
//   singleSelect: boolean,
//   isSearchable: boolean,
//   isDisabled: boolean,
//   options: IData | null,
// }

export interface ICatalogueAttributes {
  minValue: string | null;
  maxValue: string | null;
  values: string[] | null;
}

const useStyles = createUseStyles((theme: any) => ({
  container: {
    border: `1px solid #EBEFFA`
  },
  filterContainer: {
    borderTop: `1px solid #EBEFFA`
  },
  filterHeading: {
    color: theme.palette.text.primary.primary800,
  },
  body: {
    border: `1px solid ${theme.palette.border.neutral.neutral100}`,
  },
  border: {
    borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
  },
}));

export const ACTIVE_COMPONENT_SECTIONS = {
  FILTERS: "FILTERS",
  PRODUCT_LIST: "PRODUCT_LIST",
}


const UpcMpcSearchDialogModal: React.FC<ProductSearchDialogTemplateProps> = ({ sections, productType, filterData, setFilterData, handleFilterClear, onFilterSubmit, triggerButton, isSingleSelect, advanceFilterType, classType }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [activeComponent, setActiveComponent] = React.useState(ACTIVE_COMPONENT_SECTIONS.FILTERS);

  const handleClose = () => {
    setActiveComponent(ACTIVE_COMPONENT_SECTIONS.FILTERS)
    setOpen(prev => !prev);
  }

  const clearAllSelection = () => {
    setFilterData({
      ...filterData,
      productIds: []
    });
  }
  
  const activeContent = useMemo(() => {
    if (activeComponent === ACTIVE_COMPONENT_SECTIONS.FILTERS)
      return (
        <>
          <div className={`${classes.container} p-x-6 border m-x-6 rounded-lg relative`}>
            <div className="flex m-4 justify-between">
              <div className={`${classes.filterHeading} text-2xl font-medium my-auto `}>Search and Select Product</div>
              <div className={`cursor-pointer`} onClick={handleClose}>
                <CloseIcon />
              </div>
            </div>
            <div className={`${classes.border}`}></div>
            <div className={`${classes.body} rounded-lg m-4`}>
              {sections.includes(GENERIC_FILTER_SECTIONS.UPC) && <ProductCodeSearchFormTemplate
                materialFilters={filterData}
                sectionType={productType}
                setFilterData={setFilterData}
                onFilterSubmit={onFilterSubmit}
                handleClose={handleClose}
                handleFilterClear={handleFilterClear}
                classType={classType}
              />}
              {sections.includes(GENERIC_FILTER_SECTIONS.MATERIAL_FILTER) && <MaterialFilterTemplate
                materialFilters={filterData}
                sectionType={productType}
                setFilterData={setFilterData}
                onFilterSubmit={onFilterSubmit}
                handleClose={handleClose}
                handleFilterClear={handleFilterClear}
                classType={classType}
              />}
              {sections.includes(GENERIC_FILTER_SECTIONS.OTHER) && <OtherFilterFormTemplate
                materialFilters={filterData}
                sectionType={productType}
                setFilterData={setFilterData}
                onFilterSubmit={onFilterSubmit}
                handleClose={handleClose}
                handleFilterClear={handleFilterClear}
              />}

            </div>
            <div className="flex justify-between gap-y-3 gap-x-3 p-3 sticky bottom-0 right-0 z-100000 w-full bg-white">
              <div className="flex gap-x-5">
                <Button variant="secondaryText" size="large" label={"Clear All"} onClick={handleFilterClear} />
              </div>
              <div className='flex gap-y-3 gap-x-3'>
                <div className="flex gap-x-5">
                  <Button variant="tertiaryContained" size="large" label={"Back"} onClick={() => { handleClose() }} />
                </div>
                <div className="flex gap-x-5">
                  <Button variant="primaryContained" size="large" label={"Search"} onClick={() => { setActiveComponent(ACTIVE_COMPONENT_SECTIONS.PRODUCT_LIST) }} />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    else if (activeComponent === ACTIVE_COMPONENT_SECTIONS.PRODUCT_LIST)
      return (
        <>
          <div className={`p-x-6 m-x-6 rounded-lg relative`}>
            <div className={`p-6`}>
              <ProductSelectionFormTemplate
                materialFilters={filterData}
                sectionType={productType}
                setFilterData={setFilterData}
                onFilterSubmit={onFilterSubmit}
                handleClose={handleClose}
                isSingleSelect={isSingleSelect}
                advanceFilterType = {advanceFilterType}
                classType={classType}
              />
            </div>
            <div className="flex justify-between gap-y-3 gap-x-3 p-3 sticky bottom-0 right-0 z-100000 w-full bg-white">
              <div className="flex gap-x-5">
                <Button variant="secondaryText" size="large" label={"Clear All Selection"} onClick={clearAllSelection} />
              </div>
              <div className='flex gap-y-3 gap-x-3'>
                <div className="flex gap-x-5">
                  <Button variant="tertiaryContained" size="large" label={"Back"} onClick={() => { setActiveComponent(ACTIVE_COMPONENT_SECTIONS.FILTERS) }} />
                </div>
                <div className="flex gap-x-5">
                  <Button
                    variant="primaryContained"
                    size="large"
                    label={`+Add(${filterData?.productIds?.length || 0})`}
                    onClick={() => {
                      onFilterSubmit();
                      handleClose();
                    }}
                  />
                </div></div>
            </div>
          </div>
        </>
      );
  }, [filterData, activeComponent, sections]);

  return (
    <div>
      {React.cloneElement(triggerButton as React.ReactElement, { onClick: handleClose })}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => { }}
        sx={{
          width: '43%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '60%',
            boxSizing: 'border-box',
          },
        }}

      >
        {activeContent}
      </Drawer>
    </div>
  );
}

export default UpcMpcSearchDialogModal;