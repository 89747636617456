import React from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '@mui/joy';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DigitalSecondaryBanner from '../../../assets/images/digitalSecondaryBanner.png';
import DigitalBanner from '../../../assets/images/digitalBanner.png';
import primaryBlueTick from '../../../assets/icons/primaryBlueTick.jpg';
import greenTick from '../../../assets/icons/greenTick.svg';
import redTick from '../../../assets/icons/redTick.svg';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import catalogType from '../../../assets/images/catalogType.png';
import selectSku from '../../../assets/images/selectSku.png';
import inventoryDetails from '../../../assets/images/inventoryDetails.png';
import priceOffer from '../../../assets/images/priceOffer.png';
import customizeOption from '../../../assets/images/customizeOption.png';
import Termlogistics from '../../../assets/images/logistics.png';
import remarks from '../../../assets/images/remarks.png';
import manageCatalog from '../../../assets/images/manageCatalog.png';
import bundleCatalog from '../../../assets/images/bundleCatalog.png';
import rupee from '../../../assets/icons/currency_rupee.jpg';
import verified from '../../../assets/icons/verifiedTick2.svg';
import catalogIcon1 from '../../../assets/icons/catalogIcon1.svg';
import catalogIcon2 from '../../../assets/icons/catalogIcon2.svg';
import catalogIcon3 from '../../../assets/icons/catalogIcon3.svg';
import catalogIcon4 from '../../../assets/icons/catalogIcon4.svg';
import catalogIcon5 from '../../../assets/icons/catalogIcon5.svg';
import catalogIcon6 from '../../../assets/icons/catalogIcon6.svg';
import catalogIcon7 from '../../../assets/icons/catalogIcon7.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${DigitalBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    bannerSellContainer: {
        "& h1": {
            fontSize: '48px',
            lineHeight: '66px',
            color: theme.palette.text.warning.warning400,
        },
        "& p": {
            color: '#fff',
            lineHeight: '66px',
            fontSize: '48px',
        },
        "& h3": {
            color: '#fff',
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    text: {
        fontSize: '54px',
        color: "#000",
        lineHeight: '66px',
    },
    decContainer: {
        width: '780px',
        "& p": {
            color: "#000",
            lineHeight: '26px',
        },
    },
    headingTextMain: {
    },
    headingText: {
        color: theme.palette.text.neutral.neutral800,
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.neutral.neutral600,
        },

        "& h4": {
            color: theme.palette.text.primary.primary500,
            fontSize: '48px',
            lineHeight: '58px',
        },

        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    tradeContainer1: {
        background: theme.palette.background.primary.primary500,
        border: theme.palette.border.neutral.neutral200,
        width: '19%',
        display: 'flex',
        alignItems: 'center',
        "& p": {
            fontSize: '32px',
            color: '#fff',
            lineHeight: '38px',
        },
    },
    tradeContainer: {
        background: '#F8F8F8',
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        width: '19%',
        "& h4": {
            fontSize: '22px',
        },
    },
    tradeContainer2: {
        background: theme.palette.background.success.success500,
        display: 'flex',
        alignItems: 'center',
        "& p": {
            color: '#000',
            fontSize: '32px',
            lineHeight: '38px',
        },
    },
    cardsContainer: {
        background: '#fff',
        "& p": {
            fontSize: '28px',
        },
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        padding: '20px 15px',
        width: '31.6%',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#Fff',
    },
    sectionHeading: {
        color: "#000 !important",
        fontSize: '54px !important',
        lineHeight: '66px',
        "& span": {
            color: theme.palette.text.primary.primary500,
            fontSize: '54px !important',
            lineHeight: '66px',
            fontWeight: '700',
        },
        "& p": {
            color: theme.palette.text.success.success500,
        },
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600,
    },
    secondaryBanner: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${DigitalSecondaryBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    secondaryContainer: {
        '& h2': {
            fontSize: '40px',
            color: theme.palette.text.warning.warning400,
            lineHeight: '48px',
        },
        "& p": {
            color: theme.palette.text.primary.primary50,
            lineHeight: '28px',
        },
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
        fontSize: '20px',
    },
    catalog1: {
        border: `1px solid ${theme.palette.border.complementary.complementary400}`,
    },
    catalog2: {
        border: '1px solid #FD8235',
    },
    catalog3: {
        border: `1px solid ${theme.palette.border.success.success400}`,
    },
    catalogMain: {
        width: '33%',
        overflow: 'hidden',
        background: '#fff',
        "& h4": {
            fontSize: '28px',
            lineHeight: '34px',
            color: '#000',
        },
    },
    catalogHeader1: {
        background: theme.palette.background.complementary.complementary100,
    },
    catalogHeader2: {
        background: '#FFE0CC',
    },
    catalogHeader3: {
        background: theme.palette.background.success.success100,
    },
    catalogText: {
        "& p": {
            fontSize: '16px',
            lineHeight: '20px',
            "& span": {
                color: '#000',
            },
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
            fontSize: '32px',
            lineHeight: '38px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
}));
const cardData = [
    {
        img: rupee,
        title: "Detailed Product Specifications",
        description:
            "Provide all technical and commercial details, including dimensions, grades, and certifications like Mill Test Certificates, ensuring complete transparency and buyer confidence.",
    },
    {
        img: verified,
        title: "Real-Time Pricing ",
        description:
            "Dynamic pricing for materials helps sellers stay competitive, and buyers get accurate cost insights. Location-specific pricing enhances local relevance.",
    },
    {
        img: catalogIcon1,
        title: "High-Quality Visual Representation",
        description:
            "Upload default or real images to give buyers clear product visualisation, building trust and improving engagement.",
    },
    {
        img: catalogIcon2,
        title: "Customization Options",
        description:
            "Highlight options for tailored materials, sizes, grades, and packaging to meet specific buyer needs, fostering flexibility in procurement.",
    },
    {
        img: catalogIcon3,
        title: "Integrated Financing Options",
        description:
            "Link catalogs to SB Instafin for buyers to access pre-approved credit limits and flexible payment terms, making procurement seamless.",
    },
    {
        img: catalogIcon4,
        title: "Mill Discounts & Offers",
        description:
            "Mention special promotions, mill quantity discounts, and seasonal offers to attract more buyers and close deals quickly.",
    },
    {
        img: catalogIcon5,
        title: "Reviews & Ratings",
        description:
            "Showcase buyer feedback to strengthen trust and improve decision-making for prospective buyers.",
    },
    {
        img: catalogIcon6,
        title: "Digital Negotiation Tools",
        description:
            "Enable buyers to request quotes, compare offers, and negotiate online to close deals faster and more efficiently.",
    },
    {
        img: catalogIcon7,
        title: "Logistics & Delivery Options",
        description:
            "Include transportation choices with estimated timelines and costs, ensuring end-to-end procurement ease.",
    },
];


const DigitalCatalogTemplate: React.FC = () => {

    const classes = useStyles();

    const tradeData = [
        {
            className: `${classes.tradeContainer1}`,
            content: <p className="font-semibold">Benefits of<br />For Buyers</p>,
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Centralized <br /> Access',
            description: 'All product details, specifications, and pricing in one convenient location.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Real-Time<br />Updates',
            description: 'Instant access to live pricing, availability, and inventory changes.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Streamlined<br />Transactions',
            description: 'Faster and smoother procurement process with integrated tools for financing and logistics.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Cost and Time <br /> Efficiency',
            description: 'Reduces paperwork, improves accuracy, and saves time compared to traditional methods.',
        },
        {
            className: `${classes.tradeContainer} ${classes.tradeContainer2}`,
            content: <p className="font-semibold">Benefits of<br />For Sellers</p>,
        },
        {
            className: `${classes.tradeContainer}`,
            heading: ' Wide <br /> Reach',
            description: 'Showcase products to a broad, global buyer base.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Enhanced  <br /> Sales',
            description: 'Attract buyers with detailed catalogs, discounts, and customisation.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Faster  <br /> Payments',
            description: 'Use integrated financing options for instant funds.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Easy  <br /> Updates',
            description: 'Real-time catalogue management with analytics insights.',
        },
    ];


    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className='font-semibold mb-[30px]'>SB DigiLog</h1>
                    <p className='font-semibold mt-4 mb-4'>Everything<br />For Easy Steel Buying<br />At One Location</p>
                    <h3 className='text-2xl font-medium mt-[15px]'>Streamline, Showcase, and Simplify Your Steel <br /> Procurement Journey</h3>
                </div>
            </div>
            <div className={`${classes.intoContainer}`}>
                <div className={`${classes.container} flex items-center gap-10 py-14`}>
                    <div className=''>
                        <p className={`${classes.text} font-medium mb-2`}>Elevate your<br />Business Presence</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-4'>SteelBazaar's Digital Catalogs provide a centralised platform for steel procurement, enabling sellers to showcase products with detailed specifications, high-quality images, and real-time pricing. This digital approach enhances visibility, streamlines transactions, and integrates customisation options, financing solutions, and logistics management. By embracing SteelBazaar's Digital Catalogs, businesses can efficiently manage inventory, reach a broader audience, respond swiftly to market demands, and reduce costs associated with traditional methods, thereby elevating their presence in the competitive steel industry</p>
                    </div>
                </div>
            </div>
            <div className={`${classes.container}`}>
                <div className={`${classes.featuresContainer} pt-[110px]`}>
                    <h2 className={`${classes.sectionHeading} font-bold text-center`}>Empowering Steel Trade <span> with Digital Precision</span>
                    </h2>
                    <div className={`${classes.subHeading} text-2xl font-medium text-center mb-10 mt-[10px]`}>Benefits of Steelbazaar Catalogs</div>
                </div>
                <div className="flex gap-y-10 gap-x-[15px] flex-wrap py-5">
                    {tradeData.map((item, index) => (
                        <div key={index} className={`${item.className} py-5 px-[15px] rounded-lg`}>
                            {item.heading && <h4 className="font-semibold mb-3" dangerouslySetInnerHTML={{ __html: item.heading }} />}
                            {item.description && <p className="text-base">{item.description}</p>}
                            {item.content}
                        </div>
                    ))}
                </div>
            </div>
            <div className={classes.cardsContainer} id='features'>
                <div className={`${classes.container} pt-[110px] pb-[110px]`}>
                    <div className={`${classes.featuresContainer}`}>
                        <h2 className={`${classes.sectionHeading} font-bold text-center`}>Showcase Steel<span> Inventory Digitally</span>
                        </h2>
                        <div className={`${classes.subHeading} text-2xl font-medium text-center mb-10 mt-[10px]`}>Features of Steelbazaar Catalogs</div>
                    </div>
                    <div className="flex gap-x-[30px] gap-y-[36px] flex-wrap mt-[36px]">
                        {cardData.map((card, index) => (
                            <div key={index} className={`${classes.card} rounded-lg`}>
                                <div
                                    className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}
                                >
                                    <img src={card.img} alt={card.title} />
                                </div>
                                <h4 className="mt-[10px] font-semibold mb-3">{card.title}</h4>
                                <p className="text-base leading-5">{card.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={`${classes.intoContainer} py-[110px]`}>
                <div className={`${classes.container}`}>
                    <div className={`${classes.featuresContainer}`}>
                        <h2 className={`${classes.sectionHeading} font-bold text-center`}>Accelerate Sales<span> with Digital Catalogs </span>
                        </h2>
                        <div className={`${classes.subHeading} text-2xl font-medium text-center mb-10 mt-[10px]`}>Choose Your Catalog Types</div>
                    </div>
                    <div className='flex gap-[15px] mt-[50px]'>
                        <div className={`${classes.catalog1} ${classes.catalogMain} rounded-lg`}>
                            <div className={`${classes.catalogHeader1} p-[15px]`}>
                                <h4 className='font-semibold text-center'>Single Product Catalogs</h4>
                            </div>
                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={primaryBlueTick} alt="" />
                                <p className=''><span className='font-semibold'>Standard Materials:</span>List reputed brand products like TATA, JSW, SAIL, AMNS, JSPL, etc., with detailed specifications.</p>
                            </div>
                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={primaryBlueTick} alt="" />
                                <p className=''><span className='font-semibold'>Non-Standard Materials:</span> Showcase customised or unique materials for niche buyers.</p>
                            </div>
                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={primaryBlueTick} alt="" />
                                <p className=''><span className='font-semibold'>Commercial Scraps:</span> Highlight industrial-grade reusable scrap materials.</p>
                            </div>
                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={primaryBlueTick} alt="" />
                                <p className=''><span className='font-semibold'>Melting Scraps:</span> Present steel scraps for recycling or melting processes.</p>
                            </div>
                        </div>
                        <div className={`${classes.catalog2} ${classes.catalogMain} rounded-lg`}>
                            <div className={`${classes.catalogHeader2} p-[15px]`}>
                                <h4 className='font-semibold text-center'>Bundle Catalogs</h4>
                            </div>
                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={redTick} alt="" />
                                <p className=''><span className='font-semibold'>Hybrid Catalogs:</span> Combine various material categories in bulk for competitive offerings.</p>
                            </div>
                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={redTick} alt="" />
                                <p className=''><span className='font-semibold'>Pre-Curated & Customizable Bundles: </span>Ready-to-order steel product combinations tailored to specific project and buyer needs.</p>
                            </div>
                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={redTick} alt="" />
                                <p className=''><span className='font-semibold'>Segmented Sales Offers:</span> Customized discounts and offers for SMEs, large enterprises, and bulk buyers to optimize savings.</p>
                            </div>
                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={redTick} alt="" />
                                <p className=''><span className='font-semibold'>Cost-Effective Procurement Solutions:</span> Bundled pricing with flexible options, ensuring efficient and affordable procurement for all buyer segments</p>
                            </div>
                        </div>
                        <div className={`${classes.catalog3} ${classes.catalogMain} rounded-lg`}>
                            <div className={`${classes.catalogHeader3} p-[15px]`}>
                                <h4 className='font-semibold text-center'>RFQ Catalogs</h4>
                            </div>

                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={greenTick} alt="" />
                                <p className=''><span className='font-semibold'>Customisable Specifications:</span> Buyers can select exact steel specifications from multiple options to meet their project needs.</p>
                            </div>
                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={greenTick} alt="" />
                                <p className=''><span className='font-semibold'>Simplified Request for Quote (RFQ): </span>Easy-to-use interface to submit tailored RFQs directly to sellers for quick responses..</p>
                            </div>
                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={greenTick} alt="" />
                                <p className=''><span className='font-semibold'>Flexible Comparison:</span> Compare quotes from multiple reputed brands, TATA, JSW, AMNS, SAIL, and others, based on specifications, pricing, and delivery timelines.</p>
                            </div>
                            <div className={`${classes.catalogText} flex gap-1 py-[10px] px-[15px] items-start`}>
                                <img src={greenTick} alt="" />
                                <p className=''><span className='font-semibold'>Precision Procurement: </span>Ensure buyers receive steel products that match their chemical, mechanical, and quality requirements.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} py-[110px]`}>
                <div className={`${classes.featuresContainer}`}>
                    <h2 className={`${classes.sectionHeading} font-bold text-center`}>Creating Your<span> Digital Steel Catalog </span>
                        is Easy</h2>
                    <div className={`${classes.subHeading} text-2xl font-medium text-center mb-10 mt-[10px]`}>Choose Your Catalog Types</div>
                </div>
                <div className={`${classes.container} flex gap-[60px] items-center pt-[48px]`}>
                    <div><img src={catalogType} alt="" /></div>
                    <div className='w-[720px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 01:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'><span className={`${classes.headingText} text-5xl font-bold`}>Choose Your </span>Catalog Type</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <h4 className='font-semibold'>Identify and Select Your Inventory Category</h4>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Choose from Standard, Non-Standard, Commercial, or Scrap catalogue types.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Match inventory type to the appropriate category for easy management.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Facilitate buyer navigation with precise catalogue categorisation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.container} flex gap-[60px] items-center pt-[48px]`}>
                    <div className='w-[720px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 02:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'><span className={`${classes.headingText} text-5xl font-bold`}>Select or </span>Create SKUs</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <h4 className='font-semibold'>Build Your Product Blueprint</h4>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Choose Pre-defined SKUs: Access a library of standard SKUs from leading brands like TATA, JSW, SAIL, AMNS, and JSPL.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Customise Your SKUs: Tailor SKUs to match unique product specifications and branding requirements.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Define Detailed Attributes: Specify product category, standards, grades, size specifications, coatings, chemical composition, and mechanical properties.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={selectSku} alt="" /></div>
                </div>
                <div className={`${classes.container} flex gap-[60px] items-center pt-[48px]`}>
                    <div><img src={inventoryDetails} alt="" /></div>
                    <div className='w-[720px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 03:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'><span className={`${classes.headingText} text-5xl font-bold`}>Enter</span> Inventory Details</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <h4 className='font-semibold'>Organize Your Inventory with Precision</h4>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Specify Quantities: Input the number of coils or bundles available for each SKU.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Assign Unique Serial Numbers (USNs): Allocate USNs to each unit for precise tracking and identification.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Record Location and Packaging: Document storage locations and packaging details for streamlined logistics.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.container} flex gap-[60px] items-center pt-[48px]`}>
                    <div className='w-[720px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 04:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Set Pricing <span className={`${classes.headingText} text-5xl font-bold`}>and Sales Offers</span></h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <h4 className='font-semibold'>Attract Buyers with Competitive , Dynamic Pricing & Discounts</h4>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Define Competitive Pricing: Establish prices that reflect market conditions and business objectives.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Create Promotional Offers: Develop sales promotions to attract and retain customers.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Implement Dynamic Pricing: Adjust prices in real-time based on demand and inventory levels.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={priceOffer} alt="" /></div>
                </div>
                <div className={`${classes.container} flex gap-[60px] items-center pt-[48px]`}>
                    <div><img src={customizeOption} alt="" /></div>
                    <div className='w-[720px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 05:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'><span className={`${classes.headingText} text-5xl font-bold`}>Associate</span> Customization Options</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <h4 className='font-semibold'>Tailor Solutions for Your Buyers</h4>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Offer Product Variants: Provide size, grade, and coating options to meet diverse customer needs.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Enable Custom Orders: Facilitate bespoke orders with specific requirements.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Highlight Customization Capabilities: Showcase your ability to deliver tailored solutions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.container} flex gap-[60px] items-center pt-[48px]`}>
                    <div className='w-[720px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 06:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'><span className={`${classes.headingText} text-5xl font-bold`}>Define</span> Payment Terms & Logistics</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <h4 className='font-semibold'>Simplify Payments and Deliveries</h4>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Set Payment Conditions: Outline terms, including credit options and payment schedules.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Detail Delivery Options: Specify shipping methods, timelines, and associated costs.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Clarify Return Policies: Provide clear guidelines on returns and exchanges.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={Termlogistics} alt="" /></div>
                </div>
                <div className={`${classes.container} flex gap-[60px] items-center pt-[48px]`}>
                    <div><img src={remarks} alt="" /></div>
                    <div className='w-[720px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 07:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'><span className={`${classes.headingText} text-5xl font-bold`}>Add Visual</span> Content and Remarks</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <h4 className='font-semibold'>Visualise Your Inventory with Comprehensive Buyer Information</h4>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Upload High-Quality Images: Include visuals for each USN to enhance buyer confidence.Either Use SB default Images or Upload Product images. </p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Attach Certificates: Provide mill test certificates and other relevant documentation.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Include Specific Remarks: Add notes on product condition, special features, or handling instructions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.container} flex gap-[60px] items-center pt-[48px]`}>
                    <div className='w-[720px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 08:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Publish and Manage<span className={`${classes.headingText} text-5xl font-bold`}> Your Catalog</span></h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <h4 className='font-semibold'>Make Your Catalog Visible to Buyers</h4>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Review and Finalize: Ensure all information is accurate and complete before publishing.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Distribute Across Platforms: Share your digital catalogue via email, social media, and the Steelbazaar marketplace.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Monitor Performance: buyer inquiries, and sales through the intuitive Seller Dashboard.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={manageCatalog} alt="" /></div>
                </div>
                <div className={`${classes.container} flex gap-[60px] items-center pt-[48px]`}>
                    <div><img src={bundleCatalog} alt="" /></div>
                    <div className='w-[720px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 09:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'><span className={`${classes.headingText} text-5xl font-bold`}>Bundle and</span> RFQ Catalogs</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <h4 className='font-semibold'>Streamline Large Inventories for Buyers</h4>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Combine multiple catalogs for easier browsing.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Manufacturers can create <strong>RFQ Catalogs</strong> with multiple SKUs for bulk orders.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={primaryBlueTick} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg'>Enable smooth buyer interactions with detailed catalogs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.secondaryBanner}`}>
                <div className={`${classes.container} ${classes.secondaryContainer} flex flex-col justify-center h-full`}>
                    <h2 className='font-semibold mb-[10px]'>Steelbazaar Digital Catalogs: <br />Fulfilling Every Buyer Need</h2>
                    <p className='font-semibold mb-[30px] text-2xl'>Our catalogues provide 24/7 access to products with flexible financing, transparent <br /> pricing, and unmatched customisation. Backed by modern digital tools and <br /> comprehensive buyer support, Steelbazaar Digital Catalogs redefine steel <br /> procurement.</p>
                    <Button className={`${classes.btn3} font-bold text-base flex items-center gap-[10px] px-5 py-[10px] w-max`}> <span className={classes.btnText}>Contact Now</span><div className={`${classes.btnArrow2} flex items-center justify-center`}><ArrowForwardIcon /></div></Button>
                </div>
            </div>
            <RequestDemoTemplate />

        </>
    );
};
export default DigitalCatalogTemplate;
