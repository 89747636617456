import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Search from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import { FormHelperText } from '@mui/material';
import { IconButton } from '@mui/joy';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { TablePagination, useTheme, Input } from '@mui/material';
import advanceFilterIcon from '../../../assets/icons/advanceFilterIcon.svg';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { IVendorProductPriceSectionProps, IVendorProductPriceForm } from '../../pages/VendorManagement/VendorProductPriceCreate.page';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import UpcMpcSearchDialogModal, { IMaterialFilterData } from '../GenericSearchDialog/GenericSerachDialog.template';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { ADD_VENDOR_PRICE_STATES, ADVANCE_FILTER_TYPE, HTTP_STATUS, IPagination, STATUS } from '../../../utils/types';
import { CUSTOMER_ROUTES, GENERIC_FILTER_SECTIONS, initialPaginationValues, PRODUCT_SECTION } from '../../../utils/constant';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import ProductViewTemplate from '../DashBoard/Inventory Management/ProductView.template';
import Button from '../../atoms/Button/Button';
import { IDetails } from './ProductCatalogueView.template';
import { IProductData } from '../DashBoard/Inventory Management/SelectProduct.template';
import { IProductSearchParams, useGenericSearchService } from '../../../services/useGenericSearchService';
import HoverButton from '../../atoms/Button/HoverButton';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

export interface IRequestBodyValues {
    upcIds: (string | number)[] | null;
    brandIds: (string | number)[] | null;
}

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    activatProgressBar: {
        backgroundColor: theme.palette.background.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.border.neutral.neutral100
    },
    barHeading: {
        color: theme.palette.text.neutral.neutral200,
    },
    textHeading:{
        color: theme.palette.text.primary.primary900
    },
    activeBarHeading: {
        color: theme.palette.text.primary.primary500,
    }, 
    selectBox:{
        border: `1px solid ${theme.palette.background.neutral.neutral100}`,
    },
    search: {
        margin: "0",
        background: theme.palette.background.neutral.neutral400,
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600
    },
    searchIcon: {
        color: theme.palette.background.neutral.neutral400
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary400
    },
    previousActiveHeading: {
        color: theme.palette.background.primary.primary400
     },
    paginationTable: {
        "& .MuiTablePagination-selectLabel": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: '1px solid #E6E6E6',
            width: '80px',
            paddingRight: '10px',
            marginRight: "24px",
            height: "30px"
        },
        "& .MuiTablePagination-displayedRows": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-spacer": {
            flex: 0
        },
        "& .MuiToolbar-root": {
            paddingLeft: "0px !important",
            paddingRight: "0px",
            width: "100%"
        },
    },
    paginationComponent: {
        color: theme.palette.text.neutral.neutral700,
        fontWeight: 500,
        fontSize: "14px",
        width: "100%",
        justifyContent: 'end',
    },
}));

const SelectPriceProductTemplate: React.FC<IVendorProductPriceSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const genericSearchService = useGenericSearchService();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { user } = useAuthenticatedUser();
    const [filterSection, setFilterSections] = useState<string[]>([]);
    const [upcFilterData, setUpcFilterData] = useState<IMaterialFilterData>({});
    const [requestBodyValues, setRequestBodyValuesTo] = useState<IRequestBodyValues>({ upcIds: null, brandIds: null });
    const [productsData, setProductsData] = useState<IProductData[]>([{ id: null,productId:null, skuCode: '', productName: '' }]);
    const vendorInventoryService = useVendorInventoryService();
    const { showSnackbar } = useSnackbar();
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);

    const handleCancel = () => {
        navigate(CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_LISTING);
    };

    const handleBack = () => {
        setCurrentSectionTo(ADD_VENDOR_PRICE_STATES.PRODUCT_TYPE);
    }

    const onNext = async (event?: React.FormEvent) => {
        if(!formik?.values?.productId){
            showSnackbar("error", "Please select a product")
            return;
        }
        let params: IProductSearchParams = {
            page: 0,
            size: 10,
            sort: 'createdAt,desc',
            classType: formik?.values?.productType
        }
        const filter = {
            productCode: formik?.values?.productId ? [formik?.values?.productId] : null,
        }
        if(user?.businessId){
            const selectedUpcBrandResponse = await genericSearchService.searchProductByvendorId(user?.businessId, params, filter);
            const data = selectedUpcBrandResponse?.data?.data?.content?.[0];
            setCurrentSectionTo(ADD_VENDOR_PRICE_STATES.ADD_PRICE);
        }
        else{
            showSnackbar("error", "User is not logged in.")
        }
    }

    const handleFilterClear = () => {
        setUpcFilterData({});
    }

    const onFilterSubmit = async () => {
        setRequestBodyValuesTo({ upcIds: upcFilterData?.productIds ?? null, brandIds: null });
        if(!upcFilterData?.productIds?.[0]){
            showSnackbar("error", "Please select a product")
            return;
        }
        formik.setFieldValue('productId', Number(upcFilterData?.productIds?.[0]));
        let params: IProductSearchParams = {
            page: 0,
            size: 10,
            sort: 'createdAt,desc',
            classType: formik?.values?.productType
        }
        const filter = {
            productCode: Number(upcFilterData?.productIds?.[0]) ? [Number(upcFilterData?.productIds?.[0])] : null,
        }
        if(user?.businessId){
            const selectedUpcBrandResponse = await genericSearchService.searchProductByvendorId(user?.businessId, params, filter);
            const data = selectedUpcBrandResponse?.data?.data?.content?.[0];
            setCurrentSectionTo(ADD_VENDOR_PRICE_STATES.ADD_PRICE);
        }
        else{
            showSnackbar("error", "User is not logged in.")
        }
    }

    const clearAllData = () => {
        setUpcFilterData({});
        setRequestBodyValuesTo({ upcIds: null, brandIds: null });
    }

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination: IPagination) => ({
            ...prevPagination,
            page: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPagination((prevPagination) => ({
            ...prevPagination,
            size: newRowsPerPage
        }));
    };


    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
        const [inputPage, setInputPage] = useState(page + 1);

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>,
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        const handleInputPageChange = (event: any) => {
            setInputPage(parseInt(event.target.value, 10));
        };
        const handleInputBlur = (event: any) => {
            onPageChange(event, inputPage - 1);
        };

        return (
            <div className={`flex gap-x-6 ${classes.paginationComponent}`}  >
                <div className="flex gap-x-2.5">
                    <div className='my-auto'>Page</div>
                    <div className='my-auto'>
                        <Input
                            type="number"
                            value={inputPage}
                            onChange={handleInputPageChange}
                            onBlur={handleInputBlur}
                            disableUnderline={true}
                            inputProps={{ min: 1, max: Math.ceil(count / rowsPerPage) }}
                            style={{ width: '54px', height: "28px", borderRadius: '8px', border: '1px solid #E6E6E6', paddingLeft: '16px' }}
                        />
                    </div>
                    <div className='my-auto'>of {Math.ceil(count / rowsPerPage)}</div>
                </div>

                <div className={`flex`}>
                    <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                    <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                    <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </div>
            </div>
        );
    }

    const getVedorProduct = async () => {
        if (user?.businessId) {
            try {
                const ProductsResponse = await vendorInventoryService.getAllProducts(user?.businessId , { classType: formik?.values?.productType, page: pagination.page, size: pagination.size, search: searchTerm, sort: 'createdAt,desc' });
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data?.content;
                    const filteredProductData = productData.filter((product: any) => product.status !== STATUS.INACTIVE && product.status !== STATUS.DRAFT);
                    setProductsData(filteredProductData);
                    setPagination((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalPages: ProductsResponse?.data?.data?.totalPages,
                        totalRecords: ProductsResponse?.data?.data?.totalElements
                    }));
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');

            }
        }
    };

    const handleChange = (event: any) => {
        formik.setFieldValue('productId', event.target.value);
    }

    useEffect(() => {
        getVedorProduct();
    }, [pagination.page, pagination.size, searchTerm]);

    useEffect(() => {
        setFilterSections([GENERIC_FILTER_SECTIONS.UPC, GENERIC_FILTER_SECTIONS.MATERIAL_FILTER, GENERIC_FILTER_SECTIONS.OTHER]);
    }, []);

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, [pagination.page]);

    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.previousActiveHeading} my-auto font-semibold`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.activeBarHeading} my-auto`}>Select Product</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Add Pricing Details </div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className=' grid gap-4 '>
                <div className='flex  justify-between'>
                    <div className=' grid gap-1/2'>
                        <text className={`${classes.textHeading} text-base font-medium`}> Search and Select Product</text>
                        <text className={`${classes.subHeading} text-xs font-normal`}> Please select the product you want to add</text>
                    </div>
                    <div className='flex gap-1'>
                        <TextFieldV2
                            className=''
                            size="medium"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                className: "px-2 h-11 text-base ",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                        />

                        <UpcMpcSearchDialogModal
                            sections={filterSection}
                            productType={PRODUCT_SECTION.VENDOR_PRODUCTS}
                            filterData={upcFilterData}
                            setFilterData={setUpcFilterData}
                            handleFilterClear={handleFilterClear}
                            onFilterSubmit={onFilterSubmit}
                            isSingleSelect={true}
                            classType={formik?.values.productType}
                            triggerButton={
                                <HoverButton variant="tertiaryContained"
                                    iconButton={<img src={advanceFilterIcon} alt="Filter Icon" />}
                                    label="Advance Filters"
                                    buttonWithImg={true}
                                    hoverButton={!!upcFilterData?.productIds?.length}
                                    hoverButtonLabel={upcFilterData?.productIds?.length}
                                    handleCloseIcon={clearAllData}
                                    size='medium'
                                />
                            }
                            advanceFilterType={ADVANCE_FILTER_TYPE.VENDOR_PRODUCTS}
                        />
                    </div>
                </div>

                <div className={`${classes.selectBox} rounded-xl p-4 grid gap-3`}>
                    {
                        productsData?.map((product: IProductData, index: number) => (
                            <ProductViewTemplate
                                key={index}
                                product={product}
                                formik={formik}
                                showButton={true}
                                handleChange={handleChange}
                                showAttributes={true}
                                showUsnDetails={true}
                            />

                        ))
                    }
                    {formik.touched?.productId && formik.errors?.productId &&
                        <FormHelperText error className='top-half '>{formik.errors?.productId}</FormHelperText>
                    }
                    <TablePagination
                        className={`${classes.paginationTable} w-full mt-4 flex`}
                        component="div"
                        rowsPerPageOptions={[5, 10, 15]}
                        count={pagination.totalRecords}
                        page={pagination.page}
                        onPageChange={handlePaginationChange}
                        rowsPerPage={pagination.size}
                        ActionsComponent={TablePaginationActions}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        labelRowsPerPage="Rows per page"
                    />
                </div>

            </div>
            <div className=' flex justify-between'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <div className='flex gap-6 '>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        size={"medium"}
                        onClick={handleBack}
                    />
                    <Button
                        variant="primaryContained"
                        label="Next"
                        size={"medium"}
                        onClick={onNext}
                    />
                </div>
            </div>
        </div>
    )
}

export default SelectPriceProductTemplate