import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { ADD_VENDOR_INVENTORY_STATES, HTTP_STATUS } from '../../../../utils/types';
import { FormikProps, useFormik } from 'formik';
import SelectProductTypeTemplate from './SelectProductType.template';
import SelectProductTemplate from './SelectProduct.template';
import AddUsnDetailTemplate from './AddUsnDetail.template';
import VendorInventoryReviewTemplate from './VendorInventoryReview.template';
import { createUseStyles } from 'react-jss';
import * as Yup from "yup";
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { IVendorInventoryMillTest, useVendorInventoryService } from '../../../../services/useVendorInventoryService';
import { useNavigate, useParams } from 'react-router-dom';
import { usePostProductService } from '../../../../services/usePostProductService';
import { IproductDetails } from '../../VendorManagement/ProductCatalogueView.template';
import { Enum } from '../../../../utils/helper';
import { IModalFormikForm } from './AddInventorysection.template';
export interface IAddNewInventoryDetail {
    id?: number;
    ageingDays?: number;
    warehouseId: number;
    usnCode?: string;
    vendorUsnCode: string;
    grossWeight: number;
    netWeight: number;
    packagingType: string;
    isMillTest: boolean;
    millTestCertificate: File | null;
    entryDate?: string;
    status?: string;
}
export interface IInventorySampleInformation {
    sampleAvailable: boolean;
    sizeOfSample: string;
    PriceOfSample: string;
}
export interface IAddVendorInventoryForm {
    productType: string;
    productId: number | null;
    warehouseDetails: IAddNewInventoryDetail[];
    sampleInformation: IInventorySampleInformation;
}
export interface AddVendorInventorySectionProp {
    setCurrentSectionTo: (section: ADD_VENDOR_INVENTORY_STATES) => void;
    formik: FormikProps<IAddVendorInventoryForm>;
    setWareHouseId?: Dispatch<SetStateAction<number>>;
    mode?: string;
}
const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.background.primary.primary900
    }
}));
const InventoryManagementEditTemplate = () => {
    const classes = useStyles();
    const [currentSection, setCurrentSection] = useState<ADD_VENDOR_INVENTORY_STATES>(ADD_VENDOR_INVENTORY_STATES.ADD_USN);
    const [productDetails, setproductDetailsTo] = useState<IproductDetails | null>(null);
    const [warehouseId, setWareHouseId] = useState<number>(0);
    const [existingWarehouseDetails, setExistingWarehouseDetails] = useState<IAddNewInventoryDetail[]>([]);
    const [productType, setProductType] = useState<string>('')
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vendorInventoryService = useVendorInventoryService();
    const postProductService = usePostProductService();
    const navigate = useNavigate();
    const params = useParams();
    const productId = Number(params.productId);
    const vendorId = user?.businessId!!;
    const initialValuesEdit: IModalFormikForm = {
        wareHouses: [{
            warehouseId: 0,
            vendorUsnCode: '',
            grossWeight: 0,
            netWeight: 0,
            packagingType: '',
            isMillTest: false,
            millTestCertificate: null,
        }]
    };

    const modalFormikEdit = useFormik<IModalFormikForm>({
        initialValues: initialValuesEdit,
        onSubmit: async () => {
            modalFormikEdit.values.wareHouses = modalFormikEdit.values.wareHouses.filter(
                warehouse => warehouse.warehouseId !== 0
            );
            const updatedWarehouseDetails = [...formik.values.warehouseDetails, ...modalFormikEdit.values.wareHouses];
            formik.setFieldValue('warehouseDetails', updatedWarehouseDetails)
        },
    });

    const initialValues: IAddVendorInventoryForm = {
        productType: '',
        productId: productId,
        warehouseDetails: [],
        sampleInformation: {
            sampleAvailable: false,
            sizeOfSample: '',
            PriceOfSample: '',
        },
    };
    const [bussinessLocation, setBussinessLocationTo] = useState<IAddVendorInventoryForm>(initialValues);
    const getVendorProductDetails = async () => {
        if (user?.businessId) {
            try {
                const productResponse = await postProductService.getProductById(user?.businessId, productId);
                setProductType(productResponse?.data?.data?.productType);
                if (productResponse?.data?.data) {
                    const content: IproductDetails = productResponse.data.data;
                    setproductDetailsTo(content);
                } else {
                    throw new Error("Products fetch failed");
                }
            } catch (error) {
                console.error("Error fetching product details:", error);
            }
        }
    };

    useEffect(() => {
        getVendorProductDetails();
    }, [productId])

    const validationSchema = Yup.object().shape({
        productId: Yup.number()
            .required('Product ID is required')
            .positive('Product ID must be a positive number')
            .integer('Product ID must be an integer'),

        warehouseDetails: Yup.array().of(
            Yup.object().shape({
                netWeight: Yup.number()
                    .required('Net Weight is required')
                    .positive('Net Weight must be a positive number'),

                grossWeight: Yup.number()
                    .required('Gross Weight is required')
                    .positive('Gross Weight must be a positive number'),

                packagingType: Yup.string()
                    .required('Packaging Type is required'),

                isMillTest: Yup.boolean()
                    .required('Mill Test is required'),
            })
        )
    });
    const formik = useFormik<IAddVendorInventoryForm>({
        initialValues,
        validationSchema,
        onSubmit: async () => {
            const { wareHouses } = modalFormikEdit.values;
            const formData = new FormData();
            const inventoryArray = wareHouses.map((detail) => ({
                id: detail?.id,
                vendorId: user?.businessId!,
                productId: formik.values.productId!,
                warehouseId: detail.warehouseId,
                vendorUsnCode: detail.vendorUsnCode,
                netWeight: detail.netWeight,
                grossWeight: detail.grossWeight,
                packagingType: detail.packagingType,
                isMillTest: detail.isMillTest,
                status: detail.status === Enum.ADJUST_OUT ? Enum.ADJUST_OUT : Enum.ACTIVE,
                uom: "MT",
                warehousePlacement: "Aisle 3, Shelf B",
            }));
            
            const jsonArrayBlob = new Blob([JSON.stringify(inventoryArray)], { type: 'application/json' });
            formData.append('inventoryRequestBodyDTOV2s', jsonArrayBlob);

            wareHouses.forEach((detail, index) => {
                if (detail.isMillTest && detail.millTestCertificate) {
                    formData.append(`files`, detail.millTestCertificate, detail.millTestCertificate.name);
                } else {
                    const emptyBlob = new Blob([], { type: 'application/octet-stream' });
                    formData.append(`files`, emptyBlob, `NULL`);
                }
            });

            try {
                const vendorCreateResponse = await vendorInventoryService.createInventories(formData);
                if (vendorCreateResponse.status === HTTP_STATUS.OK) {
                    navigate('/dashboard/inventory-management');
                    showSnackbar("success", `Vendor Inventory updated successfully`);
                }
            } catch (error) {
                showSnackbar("error", `Vendor Inventory updation failed: ${error}`);
            }
        },
    });

    const addVendorInventorySectionProps: AddVendorInventorySectionProp = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
        setWareHouseId: setWareHouseId,
        mode: 'EDIT',
    };

    useEffect(() => {
        formik.setFieldValue('productType', productType);
    }, [productType]);

    const getVedorInventoryProductbyId = async (vendorId: number, productId: number, warehouseId: number) => {

        try {
            const ProductsMappedInventoryResponse = await vendorInventoryService.getAllInvendorMappedVenndor(vendorId, warehouseId, productId, { classType: productDetails?.productType || "STANDARD", page: 0, size: 100 });
            if (ProductsMappedInventoryResponse.status === HTTP_STATUS.OK) {
                const content: IAddNewInventoryDetail[] = ProductsMappedInventoryResponse.data.data.content;
                formik.setFieldValue('warehouseDetails', content);
            }
        } catch (error) {
            showSnackbar('error', 'Vendor Inventory  fetch failed');
        }

    };

    useEffect(() => {
        if (productId && user?.businessId) {
            getVedorInventoryProductbyId(user?.businessId, productId, warehouseId);

        }
    }, [warehouseId, user?.businessId]);

    const VendorInventorySectionView = useMemo(() => {
        switch (currentSection) {
            case ADD_VENDOR_INVENTORY_STATES.PRODUCT_TYPE:
                return <SelectProductTypeTemplate {...addVendorInventorySectionProps} />;
            case ADD_VENDOR_INVENTORY_STATES.PRODUCT:
                return <SelectProductTemplate {...addVendorInventorySectionProps} />
            case ADD_VENDOR_INVENTORY_STATES.ADD_USN:
                return <AddUsnDetailTemplate {...addVendorInventorySectionProps}
                    modalFormikEdit={modalFormikEdit}
                    setExistingWarehouseDetails={setExistingWarehouseDetails}
                />;
            case ADD_VENDOR_INVENTORY_STATES.REVIEW:
                return <VendorInventoryReviewTemplate {...addVendorInventorySectionProps} modalFormikEdit={modalFormikEdit} existingWarehouseDetails={existingWarehouseDetails} />;
            default:
                return <SelectProductTypeTemplate {...addVendorInventorySectionProps} />;
        }
    }, [currentSection, formik]);
    return (
        <div className='grid gap-6 justify-items-stretch '>
            <div className={`${classes.titleText} font-semibold text-lg`}>Add USN</div>
            {VendorInventorySectionView}
        </div>
    )
}

export default InventoryManagementEditTemplate