import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { IInventoryAddViewProps } from "./InventoryManagementView.template";
import { useVendorInventoryService } from "../../../../services/useVendorInventoryService";
import { HTTP_STATUS, IPagination } from "../../../../utils/types";
import { IAddNewInventoryDetail } from "./AddNewVendorInventory.template";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { useEffect, useState } from "react";
import { ColumnType } from "../../../organisms/table";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";
import { capitalizeFirstLetter, convertConstantsToCamelCase, enumToString } from "../../../../utils/helper";
import { initialPaginationValues } from "../../../../utils/constant";
import TableV2 from "../../../organisms/TableV2";
import ResourceStatusV2 from "../../../atoms/ResourceStatusV2/ResourceStatusV2";

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    UsnText: {
        color: theme.palette.text.complementary.complementary600
    },
}));

const InventoryAddView: React.FC<IInventoryAddViewProps> = ({ warehouseId, productId, setInventoryStates, productType}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vendorInventoryService = useVendorInventoryService();
    const { user } = useAuthenticatedUser();
    const [vendorInventories, setVendorInventoriesTo] = useState<IAddNewInventoryDetail[]>([]);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const handleAction = (id: number) => {
        navigate('/dashboard/inventory-management/add');
    }
    const getVedorInventoryProductbyId = async () => {
        if(user?.businessId){
            try {

                const ProductsMappedInventoryResponse = await vendorInventoryService.getAllInvendorMappedVenndor(user?.businessId,  warehouseId, productId, { classType: productType || "STANDARD", page: pagination.page, size: pagination.size });
                if (ProductsMappedInventoryResponse.status === HTTP_STATUS.OK) {
                    const content: IAddNewInventoryDetail[] = ProductsMappedInventoryResponse.data.data.content;
                    setVendorInventoriesTo(content);
                    setInventoryStates(content);
                    setPagination((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: ProductsMappedInventoryResponse?.data?.data?.totalElements,
                    }));
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }
        
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination: IPagination) => ({
          ...prevPagination,
          page: newPage
        }));
      };

      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPagination((prevPagination: IPagination) => ({
          ...prevPagination,
          page: 0,
          size: parseInt(event.target.value, 10)
        }));
      };
  
    useEffect(()=>{
        getVedorInventoryProductbyId();
    }, [warehouseId, user?.businessId, productId, pagination.size, pagination.page,])
    const Action = (id: number) => (
        <div className='flex items-center justify-center gap-x-3' title="">
            {/* <button onClick={() => handleAction(id)}><img src={ViewEyeIcon} alt={ViewEyeIcon} /></button> */}
        </div>
    )

    const getTableColumns = () => [
        { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
        { label: "USN Number", key: "vendorUsnNumber", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Vendor USN Number", key: "vendorUsnNumber", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Gross Weight(MT)", key: "GrossWeight", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Net Weight (MT)", key: "netWeight", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Packaging Type", key: "packagingType", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Ageing Days", key: "ageingDays", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Entry Date", key: "entryDate", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
    ]
    const getRecords = () => {
        return vendorInventories
            .map((vendorInventory: IAddNewInventoryDetail, index) => ([
                pagination.page * pagination.size + index + 1,
                vendorInventory.usnCode ? vendorInventory.usnCode : "NA",
                vendorInventory.vendorUsnCode ? vendorInventory.vendorUsnCode : "NA",
                vendorInventory.grossWeight,
                vendorInventory.netWeight,
                convertConstantsToCamelCase(vendorInventory.packagingType),
                vendorInventory.ageingDays ? vendorInventory.ageingDays : "0",
                vendorInventory.entryDate ? vendorInventory.entryDate : "NA",
                enumToString(vendorInventory?.status ?? "ACTIVE")
            ]));
    };

    const getSchema = () => ({
        id: "1",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.page,
            isVisible: true,
            limit: pagination.size,
            handleChangePage: handleChangePage,
            handleChangeRowsPerPage: handleChangeRowsPerPage
        },
        columns: getTableColumns() ?? []
    })
    return (
        <div className={` grid gap-4`}>
            <text className={` ${classes.UsnText} text-lg font-medium pt-4`}> Total No of USN: {vendorInventories.length} </text>
            <TableV2 schema={getSchema()} records={getRecords()} />
        </div>
    )
}

export default InventoryAddView;