import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useCategoryService } from '../../../../services/useCategoryService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { CATALOGUE_TYPE_STATES, CLASS_TYPE, HTTP_STATUS } from '../../../../utils/types';
import rightArrow from '../../../../assets/icons/rightArrow.svg';
import { useNavigate } from 'react-router-dom';

interface ICategoryFilter {
  id: number;
  name: string;
  path: string | null;
  subCategories?: ICategoryFilter[];
}

const useStyles = createUseStyles((theme: any) => ({
  primaryContainer: {
    background: '#F2F2F3',
    height: '500px',
    marginTop: '22px',
  },
  containers: {
    background: '#F2F2F3',
    zIndex: 999,
    position: 'relative',
    top: '20px',
    marginTop: '5px', 
    width: '100%',
    height:'100%',
  },
  navigationLevel1: {
    borderRight: `1px solid ${theme.palette.border.neutral.neutral50}`,
    position: 'relative',
    '& ul': {
      listStyle: 'none',
      borderRight: `1px solid ${theme.palette.border.neutral.neutral50}`,
    },
    '& li': {},
    '& a': {
      display: 'block',
      position: 'relative', 
      padding: '1em',
      color: theme.palette.text.neutral.neutral900,
      textDecoration: 'none',
      fontSize: '14px',
      '&::after': {
        content: '""',
        backgroundImage: `url(${rightArrow})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        top: '50%',
        right: '15px',
        width: '12px', 
        height: '12px',
        transform: 'translateY(-50%)',
        "&:hover": {
          color: theme.palette.text.primary.primary500,
        },
      },
      '&:hover': {
        background: '#fff',
        borderRight: `1px solid ${theme.palette.border.neutral.neutral50}`,
        color: theme.palette.text.primary.primary500,
      },
    },
  },
  navigationLevel2: {
    '& ul': {
      background: '#F2F2F3',
      display: 'none',
      position: 'absolute',
      top: 0,             
      left: '100%', 
      
      borderRight: `1px solid ${theme.palette.border.neutral.neutral50}`,
      zIndex: 1000,   
    },
    '&:hover > ul': {
      display: 'block',
      zIndex: 2000,
      borderRight: `1px solid ${theme.palette.border.neutral.neutral50}`,
    },
  },
  lastDropdownMenu: {
    flexWrap: 'wrap',
    gap: '10px',
    position: 'absolute',
    top: 0,                
    left: '100%',          
    background: 'white',
    zIndex: 3000,          
    padding: '10px',
  },
  lastLevel: {
    display: 'inline-block',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  lastCategoryItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '15px',
  },
  productImg: {
    width: '100%',
    height: 'auto',
    maxWidth: '180px',
    display: 'block',
  },
  imgcontainer: {
    width: '120px !important',
    height: '102px !important',
    borderRadius: '100% !important',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  productName: {
    fontSize: '14px',
    color: theme.palette.text.neutral.neutral600,
  },
}
));

const CategoriesDropdown: React.FC = () => {
  const classes = useStyles();
  const CategoryService = useCategoryService();
  const { showSnackbar } = useSnackbar();
  const [categoriesFilter, setCategoriesFilter] = useState<ICategoryFilter[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getCategoriesFilters();
  }, []);

  const getCategoriesFilters = async () => {
    try {
      const categoriesFiltersResponse = await CategoryService.getCategoriesFilters();
      if (categoriesFiltersResponse.status === HTTP_STATUS.OK && categoriesFiltersResponse.data) {
        const categoriesData = categoriesFiltersResponse.data.data;
        setCategoriesFilter(categoriesData);
      } else {
        showSnackbar('error', 'No categories found');
      }
    } catch (error) {
      showSnackbar('error', 'Failed to fetch categories');
    } finally {
      setLoading(false);
    }
  };

  const handleProductNavigation = (id: string) => {
    navigate(`/catalogue/list?classes=${CLASS_TYPE.STANDARD}&catalogueTypes=${CATALOGUE_TYPE_STATES.CURRENT_INVENTORY}&categoryId=${id}`);
  }; 

  const rawCategory = {
    id: 0,
    name: 'Flat Rolled Products',
    path: null,
    subCategories: categoriesFilter,
  };

  const finishedProductsCategory = {
    id: 1,
    name: 'Long Products',
    path: null,
    subCategories: [
      // { id: 11, name: 'Product 1', path: null },
      // { id: 12, name: 'Product 2', path: null },
      // { id: 13, name: 'Product 3', path: null },
    ],
  };

  const mainCategory = (categories: ICategoryFilter[]) => (
    <ul className={`${classes.navigationLevel1} CatMenuL2`}>
      {categories.map((category) => (
        <li key={category.id} className={classes.navigationLevel2}>
          <a href={category.path || '#'}>{category.name}</a>
          {category.subCategories && superCategory(category.subCategories)}
        </li>
      ))}
    </ul>
  );

  const superCategory = (subCategories: ICategoryFilter[]) => (
    <ul className='CatMenuL3'>
      {subCategories.map((subCategory) => (
        <li key={subCategory.id} className={classes.navigationLevel2}>
          <a href={subCategory.path || '#'}>{subCategory.name}</a>
          {subCategory.subCategories && productCategory(subCategory.subCategories)}
        </li>
      ))}
    </ul>
  );

  const productCategory = (subCategories: ICategoryFilter[]) => (
    <ul  className={`${classes.lastDropdownMenu} CatMenuLast xl:px-4`}>
      {subCategories.map((subCategory) => (
        <li key={subCategory.id} className={classes.lastLevel}>
          <div className={classes.lastCategoryItem}  onClick={() => handleProductNavigation(subCategory.id.toString())}>
            {subCategory.path && (
              <div className={classes.imgcontainer}>
                <img src={subCategory.path} alt={subCategory.name} className={classes.productImg} />
              </div>
            )}
            <span className={classes.productName}>{subCategory.name}</span>
          </div>
        </li>
      ))}
    </ul>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`${classes.primaryContainer} CatMenuDropBox`}>
      <div className={classes.containers}>
        <div className={`${classes.navigationLevel1} CatMenuItems`}>
          <div className={`${classes.navigationLevel1} CatMenuL1`}>
            <div className={`${classes.navigationLevel2} `}>
              <a href="#">{rawCategory.name}</a>
              {mainCategory(rawCategory.subCategories || [])}
            </div>
            <div className={`${classes.navigationLevel2}`}>
              <a href="#">{finishedProductsCategory.name}</a>
              {mainCategory(finishedProductsCategory.subCategories || [])}
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default CategoriesDropdown;
