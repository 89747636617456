import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { Link } from 'react-router-dom';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';
import navbarArrowIcon from '../../../../assets/icons/navbarArrowIcon.svg';
import Navbarplatform6 from '../../../../assets/images/Navbarplatform3.jpg';
import Navbarplatform7 from '../../../../assets/images/Navbarplatform9.jpg';
import Navbarplatform8 from '../../../../assets/images/Navbarplatform2.jpg';
import Navbarplatform9 from '../../../../assets/images/Navbarplatform9.jpg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: theme.palette.background.neutral.neutral50,
        boxShadow: `0px 20px 20px 0px rgba(0, 0, 0, 0.25)`,
        position: 'absolute',
        width: '100%',
        top: '125px',
        left: '0',
        zIndex: '99',
      
    },
    primaryContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    dropdownContent: {
        "& h3": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& h4": {
            lineHeight: '22px',
            color: '#000',
            borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`
        },
        "& p": {
            color: '#000',
            position: 'relative', 
            paddingLeft: '24px', 
            "&::before": {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '8px', 
                height: '12px',
                backgroundImage: `url(${navbarArrowIcon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
        },
    },
    dropdownImgContainer: {
        border: '4px solid white',
        borderRadius: '8px',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
        overflow: 'hidden',
        '& img': {
            width: '100%',
        },
    },
}));

const HelpCenterDropdown: React.FC = () => {
    const classes = useStyles();

    const [hoveredImage, setHoveredImage] = useState<string>(Navbarplatform6);

    const imagesMap: Record<string, string> = {
        'Navbarplatform6': Navbarplatform6,
        'Navbarplatform7': Navbarplatform7,
        'Navbarplatform8': Navbarplatform8,
        'Navbarplatform9': Navbarplatform9,
    };

    return (
        <div className={`${classes.container} py-10`}>
            <div className={`${classes.primaryContainer} flex justify-between items-center`}>
                <div className='flex w-full gap-[15px]'>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>FAQs</h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform7'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.HELP_CENTER}`}>Buyer</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform8'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.HELP_CENTER}`}>Sellers</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform9'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.HELP_CENTER}`}>Payment & Shipping</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Tutorial</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Platform</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Walkthrough</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Feature Specifies</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Knowledge Base</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Best Practices</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Industry Insights</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Troubleshooting</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Common Issues</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Reporting Bugs</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.dropdownImgContainer} rounded-lg w-1/4 h-fit`}>
                        {hoveredImage && <img src={hoveredImage} alt="Hovered Image" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpCenterDropdown;
