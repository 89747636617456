import { FormikErrors, FormikProps } from 'formik';
import React, { memo, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { IMaterialAttribute } from '../../pages/DashBoard/MyMaterialProduct.page';
import SelectV2 from '../../atoms/Select/SelectV2';
import { isArray } from 'lodash';
import { useAdminMasterService } from '../../../services/useAdminMasterService';
import { DELIVERY_INFORMATION, MODE, MY_MATERIAL_CUSTOMIZATION_ATTRIBUTE, SHAPE_TYPES } from '../../../utils/constant';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import { AttributeSpecification, ICategory, IGrade, IStandards } from '../Rfq/ProductRequirementForm.template';
import AutoCompleteInputV2, { AutoCompleteOption } from '../../atoms/AutoCompleteInput/AutoCompleteInput';
import { ADD_OWN_PRODUCT, CATEGORY_TYPE, HTTP_STATUS } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import InputAdornment from "@mui/material/InputAdornment";
import CustomRadioGroup from '../../molecules/CustomRadioGroup/CustomRadioGroup';
import { IAddOwnProduct, IAddOwnProductFormik } from './AddOwnProduct.template';
import ImageUploadFormTemplate from '../DashBoard/ValueAddedService/Machine/ImageUploadForm.template';
import { ITabsSchema } from '../../molecules/Tabs/Tabs';
import { capitalizeFirstLetter, sortByRank } from '../../../utils/helper';
import TabsV2 from '../../molecules/TabsV2/TabsV2';
import DeliveryInformationOwnProductTemplate from './DeliveryInformationOwnProduct.template';
import TagInputGroup from '../../molecules/TagInputGroup/TagInputGroup';
import { useAdminMasterServiceV2 } from '../../../services/useAdminMasterServiceV2';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';

interface AddMaterialFormTemplateProps {
    formik: FormikProps<IAddOwnProductFormik>
    warehouses: { id: number, name: string }[];
    index: number
    attributesOriginal?: IMaterialAttribute[]
    mode: string;
    setCurrentSectionTo: (section: ADD_OWN_PRODUCT) => void;
    handleCancel: () => void
}

const useStyles = createUseStyles((theme: any) => ({
    highlightedText: {
        color: theme.palette.text.primary.primary500
    },
    tertiary100Border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    darkHeading: {
        color: theme.palette.text.primary.primary800,
        lineHeight: "22px"
    },
    textFieldText: {
        color: theme.palette.text.neutral.neutral400,
    },
    title: {
        color: theme.palette.text.primary.primary900,
    },
    radioColor: {
        color: theme.palette.text.primary.primary500,
        width: "18%",
        padding: "16px 20px",
        display: "block",
        " & .MuiFormControlLabel-label": {
            display: "block",
            color: theme.palette.text.neutral.neutral400,
            fontSize: "18px",
            fontWeight: "600",
            marginTop: "12px"
        }
    },
    warningAsterisk: {
        marginTop: "-4px",
        color: theme.palette.text.primary.primary500
    },
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
}));

const OwnProductFormTemplate: React.FC<AddMaterialFormTemplateProps> = memo(({ formik, warehouses, setCurrentSectionTo, handleCancel, index, attributesOriginal = [], mode }) => {
    const classes = useStyles();
    const adminService = useAdminMasterService();
    const adminMasterService = useAdminMasterServiceV2();
    const { user } = useAuthenticatedUser();
    const [grades, setGrades] = useState<IGrade[]>([]);
    const [standards, setStandards] = useState<IStandards[]>([]);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [attributes, setAttributes] = useState<IMaterialAttribute[]>([]);
    const [activeTab, setActiveTabTo] = useState("1");
    const [superCategoryData, setSuperCategoryData] = useState<ICategory[] | null>(null);
    const [mainCategoryData, setMainCategoryData] = useState<ICategory[] | null>(null);
    const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);
    const [brandFilterValues, setBrandFilterValues] = useState<{ id: any, label: string }[]>([]);

    const handleFormikValueChange = (fieldName: string, value: any) => {
        formik.setFieldValue(fieldName, value);
    }

    const handleAttributeChange = (formIndex: number, attributeIndex: number, attributeName: string) => (value: string | AutoCompleteOption | null) => {
        if (typeof value === 'string' && value !== null && value !== "") {
            if (attributeName.trim().toUpperCase() == 'WIDTH' && (value.includes('.') || parseInt(value) > 9999)) return;
            const customAttribute = JSON.parse(JSON.stringify(attributes));
            const isStandard = customAttribute[attributeIndex].attributeOptions?.includes(value);
            if (!isStandard && isArray(customAttribute?.[attributeIndex]?.attributeOptions)) {
                (customAttribute?.[attributeIndex]?.attributeOptions as string[])?.push(value);
                if (parseInt(value) > 9999) return;
                handleFormikValueChange(`productDetails[${formIndex}].attributes[${attributeIndex}].isMpc`, true);
                handleFormikValueChange(`productDetails[${formIndex}].attributes[${attributeIndex}].attributeOptions`, value);
                setAttributes && setAttributes(customAttribute);
            }
        } else if (typeof value !== 'string') {
            const isStandard = attributes[attributeIndex].attributeOptions?.includes(value?.label as string);
            if (isStandard)
                handleFormikValueChange(`productDetails[${formIndex}].attributes[${attributeIndex}].isMpc`, false);
            handleFormikValueChange(`productDetails[${formIndex}].attributes[${attributeIndex}].attributeOptions`, value?.label);
        }
    }

    const handleTabChange = (tab: string | number) => {
        setActiveTabTo(tab.toString());
    };

    const handleOptionChange = (field: string) => (value: AutoCompleteOption | null) => {
      if (typeof value?.id === 'number' && value.id > 0) {
        handleFormikValueChange(`productDetails[${index}].[${field}]`, value.id )
    }
        if (['standardId', 'gradeId', 'superCategoryId', 'mainCategoryId', 'productCategoryId', 'brandId'].includes(field)) {
            let fieldName = ""
            if(field==="standardId"){fieldName="standard"}
            else if(field==="gradeId"){fieldName="grade"}
            else if(field==="superCategoryId"){fieldName="superCategory"}
            else if(field==="mainCategoryId"){fieldName="mainCategory"}
            else if(field==="productCategoryId"){fieldName="productCategory"}
            else if(field==="brandId"){fieldName="brand"}
            handleFormikValueChange(`productDetails[${index}].[${fieldName}]`, value)
        }
    }

    const loadGrade = (inputValue?: string) => {
        if (formik?.values.productDetails[index]?.standardId) {
            adminService.getAllQualityGrades({ search: inputValue ?? '', type: 'GRADE', page: 0, size: 10, sort: 'createdAt,desc', standardId: formik?.values.productDetails[index]?.standardId as number })
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK)
                        setGrades(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const loadSuperCategory = (inputValue?: string) => {
        adminService.getAllCategories({ level: CATEGORY_TYPE.SUPER_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setSuperCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadMainCategory = (inputValue?: string) => {
        if (formik?.values?.productDetails?.[index]?.superCategoryId) {
            adminService.getAllCategories({ level: CATEGORY_TYPE.MAIN_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik?.values?.productDetails[index]?.superCategoryId ?? '', name: inputValue ?? '', status: 'ACTIVE' })
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK)
                        setMainCategoryData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const loadProductCategory = (inputValue?: string) => {
        if (formik?.values?.productDetails[index]?.mainCategoryId) {
            adminService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik?.values?.productDetails[index]?.mainCategoryId ?? '', name: inputValue ?? '', status: 'ACTIVE' })
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK)
                        setProductCategoryData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const loadAttributes = (inputValue?: string) => {
        const currentProductCategoryId = formik?.values?.productDetails[index]?.productCategoryId;
        if (currentProductCategoryId) {
            const matchingProductCategory = productCategoryData?.find(
                (category) => category.id === currentProductCategoryId
            );
            if (matchingProductCategory) {
                const productAttributes = matchingProductCategory.attributeDetails.SPECIFICATION ?? [];

                productAttributes.forEach((attribute: any) => {
                    if (typeof attribute.attributeOptions === 'string')
                        attribute.attributeOptions = JSON.parse(attribute.attributeOptions);
                });
                // const filteredAttributes = productAttributes.filter((attribute: any) => attribute.name !== 'Hardness');
                const attributesCopy = JSON.parse(JSON.stringify(productAttributes));
                setAttributes && setAttributes(attributesCopy);
                if (productAttributes.length > 0) {
                    const formikAttributes = productAttributes.map((attribute: AttributeSpecification) => ({
                        ...attribute,
                        attributeOptions: null,
                    }));
                    formik.setFieldValue(
                        `productDetails[${index}].attributes`,
                        formikAttributes
                    );
                }
            }
        }
    }

    const loadStandard = (inputValue?: string) => {
        const productCategoryId = formik?.values?.productDetails[index]?.productCategoryId;
        if (productCategoryId !== null && productCategoryId !== undefined) {
            adminService.getCategoryById(productCategoryId)
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK) {
                        setStandards(sortByRank(res?.data?.data?.standardDetails));
                    }
                });
        }
    };

    const schema: ITabsSchema[] = warehouses?.map((warehouse: any, warehouseIndex: number) => ({
        label: capitalizeFirstLetter(warehouse?.name),
        component: <DeliveryInformationOwnProductTemplate warehouseId={warehouse.id} formik={formik} index={index} warehouseIndex={warehouseIndex} />,
    }));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue(`warehouseWiseProductDetails.${index}.deliveryTimeFrame`, event.target.value);
    };

    const updatekeywordsValues = (data: string[]) => {
        formik.setFieldValue(`productDetails.${index}.keywords`, data);
    }

    const superCategoryOptions = useMemo(() => {
        return superCategoryData?.map((superCategory: ICategory) => ({ label: superCategory.name, id: superCategory.id })) ?? []
    }, [superCategoryData])

    const mainCategoryOptions = useMemo(() => {
        return mainCategoryData?.map((mainCategory: ICategory) => ({ label: mainCategory.name, id: mainCategory.id })) ?? []
    }, [mainCategoryData])

    const productCategoryOptions = useMemo(() => {
        return productCategoryData?.map((productCategory: ICategory) => ({ label: productCategory.name, id: productCategory.id })) ?? []
    }, [productCategoryData])

    useEffect(() => {
        loadGrade();
    }, [formik?.values.productDetails?.[index]?.standardId]);

    useEffect(() => {
        formik.setFieldValue(`productDetails[${index}].productCategoryId`,null)
        formik.setFieldValue(`productDetails[${index}].productCategory`,null)
        formik.setFieldValue(`productDetails[${index}].mainCategoryId`,null)
        formik.setFieldValue(`productDetails[${index}].mainCategory`,null)
        loadMainCategory();
    }, [formik?.values?.productDetails[index]?.superCategory]);

    useEffect(() => {
        formik.setFieldValue(`productDetails[${index}].productCategoryId`,null)
        formik.setFieldValue(`productDetails[${index}].productCategory`,null)
        loadProductCategory();
    }, [formik?.values?.productDetails[index]?.mainCategory]);

    useEffect(() => {
        loadAttributes();
        loadStandard();
    }, [formik?.values?.productDetails[index]?.productCategory]);

    useEffect(() => {
        loadSuperCategory();
    }, []);


    const loadBrand = (inputValue?: string) => {
        if(!user?.businessId)return;
        if(formik?.values?.productDetails?.[index]?.productCategoryId == null)return;
        if (inputValue !== undefined) {
            formik.setFieldValue(`productDetails[${index}].brandId`, inputValue)
        }

        let params = {
            name: inputValue || "",
            page: 0,
            size: 20,
            productCategoryId: formik.values.productDetails[index]?.productCategoryId || null
		}
        adminMasterService.getRootAndVendorBrands(user?.businessId, params)
            .then((brandsResponse) => {
                if (brandsResponse?.data?.data) {
                    let newBrandFilterValues: { id: number; label: string }[] = [];
                    brandsResponse?.data?.data?.content.forEach((brand: any) => {
                        newBrandFilterValues.push({ id: brand.id, label: brand.name });
                    });
                    if (brandsResponse?.data?.data?.content.length === 0) {
                        newBrandFilterValues.push({ id: 0, label: inputValue!! });
                    }
                    setBrandFilterValues(newBrandFilterValues);
                }
            })
            .catch((error) => {
                setBrandFilterValues([]);
            });

    }

	useEffect(() => {
		loadBrand();
	}, [formik?.values?.productDetails?.[index]?.productCategoryId])

    return (
        <div className='flex flex-col gap-6'>
            {SnackBarComponent}
            <div>
                <div className={`pb-3 text-lg font-medium ${classes.darkHeading}`}>Category</div>
                <div className='grid grid-cols-3 gap-4'>
                    {(mode === MODE.ADD || formik.values.productDetails[index]?.superCategory) &&
                        <AutoCompleteInputV2
                            {...formik.getFieldProps(`productDetails[${index}].superCategoryId`)}
                            label='Super Category'
                            options={superCategoryOptions}
                            onSearch={loadSuperCategory}
                            onChange={handleOptionChange('superCategoryId')}
                            placeholder='Select Super Category'
                            isDisabled={mode === MODE.EDIT}
                            error={
                                formik.touched.productDetails?.[index]?.superCategoryId &&
                                Boolean(
                                    formik.errors.productDetails &&
                                    Array.isArray(formik.errors.productDetails) &&
                                    (formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.superCategoryId
                                )
                            }
                            helperText={(formik.touched.productDetails?.[index]?.superCategoryId &&
                                formik.errors.productDetails &&
                                Array.isArray(formik.errors.productDetails) &&
                                ((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.superCategoryId ?? "")) as string}
                            value={formik.values.productDetails[index]?.superCategory}
                            onBlur={() => formik.setFieldTouched(`productDetails[${index}].superCategoryId`)}
                        />}

                    {(mode === MODE.ADD || formik.values.productDetails[index]?.mainCategory) &&
                        <AutoCompleteInputV2
                            {...formik.getFieldProps(`productDetails[${index}].mainCategoryId`)}
                            label='Main Category'
                            options={mainCategoryOptions}
                            onSearch={loadMainCategory}
                            onChange={handleOptionChange('mainCategoryId')}
                            placeholder='Select Main Category'
                            error={
                                formik.touched.productDetails?.[index]?.mainCategoryId &&
                                Boolean(
                                    formik.errors.productDetails &&
                                    Array.isArray(formik.errors.productDetails) &&
                                    (formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.mainCategoryId
                                )
                            }
                            helperText={(formik.touched.productDetails?.[index]?.mainCategoryId &&
                                formik.errors.productDetails &&
                                Array.isArray(formik.errors.productDetails) &&
                                ((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.mainCategoryId ?? "")) as string}
                            value={formik.values.productDetails[index]?.mainCategory}
                            onBlur={() => formik.setFieldTouched(`productDetails[${index}].mainCategoryId`)}
                        />}
                    {(mode === MODE.ADD || formik.values.productDetails[index]?.productCategory) &&
                        <AutoCompleteInputV2
                            {...formik.getFieldProps(`productDetails[${index}].productCategoryId`)}
                            label='Product Category'
                            options={productCategoryOptions}
                            onSearch={loadProductCategory}
                            onChange={handleOptionChange('productCategoryId')}
                            placeholder='Select Product Category'
                            error={
                                formik.touched.productDetails?.[index]?.productCategoryId &&
                                Boolean(
                                    formik.errors.productDetails &&
                                    Array.isArray(formik.errors.productDetails) &&
                                    (formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.productCategoryId
                                )
                            }
                            helperText={(formik.touched.productDetails?.[index]?.productCategoryId &&
                                formik.errors.productDetails &&
                                Array.isArray(formik.errors.productDetails) &&
                                ((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.productCategoryId ?? "")) as string}
                            value={formik.values.productDetails[index]?.productCategory}
                            onBlur={() => formik.setFieldTouched(`productDetails[${index}].productCategoryId`)}
                        />}
                </div>
            </div>
            <div>
                <div className={`pb-3 text-lg font-medium ${classes.darkHeading}`}>Standard</div>
                <div className='grid grid-cols-2 gap-4'>
                    {(mode === MODE.ADD || formik.values.productDetails[index]?.standard) &&
                        <AutoCompleteInputV2
                            {...formik.getFieldProps(`productDetails[${index}].standardId`)}
                            label='Standard'
                            options={standards.map(standard => ({ id: standard.id, label: standard.name }))}
                            onSearch={loadStandard}
                            onChange={handleOptionChange('standardId')}
                            placeholder='Select Standard'
                            isDisabled={mode === MODE.EDIT}
                            error={
                                formik.touched.productDetails?.[index]?.standardId &&
                                Boolean(
                                    formik.errors.productDetails &&
                                    Array.isArray(formik.errors.productDetails) &&
                                    (formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.standardId
                                )
                            }
                            helperText={(formik.touched.productDetails?.[index]?.standardId &&
                                formik.errors.productDetails &&
                                Array.isArray(formik.errors.productDetails) &&
                                ((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.standardId ?? "")) as string}
                            value={formik.values.productDetails[index]?.standard}
                            onBlur={() => formik.setFieldTouched(`productDetails[${index}].standardId`)}
                        />}

                    {(mode === MODE.ADD || formik.values.productDetails[index]?.grade) &&
                        <AutoCompleteInputV2
                            {...formik.getFieldProps(`productDetails[${index}].gradeId`)}
                            label='Grade'
                            options={grades.map(grade => ({ id: grade.id, label: grade.name }))}
                            onSearch={loadGrade}
                            onChange={handleOptionChange('gradeId')}
                            placeholder='Select Grade'
                            error={
                                formik.touched.productDetails?.[index]?.gradeId &&
                                Boolean(
                                    formik.errors.productDetails &&
                                    Array.isArray(formik.errors.productDetails) &&
                                    (formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.gradeId
                                )
                            }
                            helperText={(formik.touched.productDetails?.[index]?.gradeId &&
                                formik.errors.productDetails &&
                                Array.isArray(formik.errors.productDetails) &&
                                ((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.gradeId ?? "")) as string}
                            value={formik.values.productDetails[index]?.grade}
                            onBlur={() => formik.setFieldTouched(`productDetails[${index}].gradeId`)}
                        />}
                </div>
            </div>

            <div>
                <div className={`pb-3 text-lg font-medium ${classes.darkHeading}`}>Product Specification</div>
                <div className='grid grid-cols-4 gap-4'>
                    {
                        attributes.map(((attribute, attributeIndex) => (
                            MY_MATERIAL_CUSTOMIZATION_ATTRIBUTE.includes(attribute.name?.trim()?.toUpperCase()) ?
                                <AutoCompleteInputV2
                                    {...formik.getFieldProps(`productDetails[${index}].attributes[${attributeIndex}].attributeOptions`)}
                                    label={`${attribute.name} ${attribute.uom != null ? `(${attribute.uom.toLowerCase()})` : ''}`}
                                    options={isArray(attribute.attributeOptions) ? attribute.attributeOptions?.map((option: string) => ({ id: option, label: option })) : []}
                                    onSearch={handleAttributeChange(index, attributeIndex, attribute.name)}
                                    onChange={handleAttributeChange(index, attributeIndex, attribute.name)}
                                    placeholder={`Select ${attribute.name}`}
                                    isDisabled={mode === MODE.EDIT}
                                    value={
                                        formik.values.productDetails[index]?.attributes[attributeIndex]?.attributeOptions
                                            ? {
                                                  id: formik.values.productDetails[index]?.attributes[attributeIndex].attributeOptions as string,
                                                  label: formik.values.productDetails[index]?.attributes[attributeIndex].attributeOptions as string,
                                              }
                                            : null
                                    }
                                    error={
                                        formik.touched.productDetails?.[index]?.attributes?.[attributeIndex] &&
                                        Boolean(
                                            formik.errors.productDetails &&
                                            Array.isArray(formik.errors.productDetails) &&
                                            ((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index] as FormikErrors<IAddOwnProduct>)?.attributes?.[attributeIndex]
                                        )
                                    }
                                    helperText={(formik.touched.productDetails?.[index]?.attributes?.[attributeIndex] &&
                                        formik.errors.productDetails &&
                                        Array.isArray(formik.errors.productDetails) &&
                                        (((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index] as FormikErrors<IAddOwnProduct>)?.attributes?.[attributeIndex] as FormikErrors<IMaterialAttribute>)?.attributeOptions) as string}
                                    onBlur={() => formik.setFieldTouched(`productDetails[${index}].attributes[${attributeIndex}].attributeOptions`)}
                                />
                                : <SelectV2
                                    {...formik.getFieldProps(`productDetails[${index}].attributes[${attributeIndex}].attributeOptions`)}
                                    label={`${attribute.name} ${attribute.uom != null ? `(${attribute.uom.toLowerCase()})` : ''}`}
                                    options={isArray(attribute.attributeOptions) ? attribute.attributeOptions?.map((option: string) => ({ value: option, label: option })) : []}
                                    placeholder={`Select ${attribute.name}`}
                                    disabled={mode === MODE.EDIT}
                                    error={
                                        formik.touched.productDetails?.[index]?.attributes?.[attributeIndex] &&
                                        Boolean(
                                            formik.errors.productDetails &&
                                            Array.isArray(formik.errors.productDetails) &&
                                            ((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index] as FormikErrors<IAddOwnProduct>)?.attributes?.[attributeIndex]
                                        )
                                    }
                                    helperText={(formik.touched.productDetails?.[index]?.attributes?.[attributeIndex] &&
                                        formik.errors.productDetails &&
                                        Array.isArray(formik.errors.productDetails) &&
                                        (((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index] as FormikErrors<IAddOwnProduct>)?.attributes?.[attributeIndex] as FormikErrors<IMaterialAttribute>)?.attributeOptions) as string}
                                    onBlur={() => formik.setFieldTouched(`productDetails[${index}].attributes[${attributeIndex}].attributeOptions`)}
                                />
                        )))
                    }
                </div>
            </div>
            <div>
                <TagInputGroup
                    onTagChange={updatekeywordsValues}
                    displayLabel={'Keywords'}
                    tags={formik.values.productDetails[index].keywords}
                    disabled={mode === MODE.VIEW}
                />
              {formik.touched.productDetails?.[index]?.keywords &&
                  formik.errors.productDetails &&
                  Array.isArray(formik.errors.productDetails) &&
                  (formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.keywords && (
                      <div className={classes.errorMessage}>
                          <small>{(formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.keywords}</small>
                      </div>
                  )}
            </div>
            <div>
                <div className={`pb-3 text-lg font-medium ${classes.darkHeading}`}>Brand Details</div>
                <div className='grid grid-cols-2 gap-4'>
                    <AutoCompleteInputV2
                        label='Select Brand'
                        options={brandFilterValues}
                        onSearch={loadBrand}
                        onChange={handleOptionChange('brandId')}
                        placeholder='Select Brand'
                        isDisabled={mode === MODE.EDIT}
                        error={
                            formik.touched.productDetails?.[index]?.brandId &&
                            Boolean(
                                formik.errors.productDetails &&
                                Array.isArray(formik.errors.productDetails) &&
                                (formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.brandId
                            )
                        }
                        helperText={(formik.touched.productDetails?.[index]?.brand &&
                            formik.errors.productDetails &&
                            Array.isArray(formik.errors.productDetails) &&
                            ((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.brandId ?? "")) as string}
                        value={formik.values.productDetails[index]?.brand}
                        onBlur={() => formik.setFieldTouched(`productDetails[${index}].brandId`)}
                    />
                    <SelectV2
                      placeholder='Select Here'
                      label="Shape*"
                      fullWidth
                      {...formik.getFieldProps(`productDetails[${index}].shape`)}
                      error={
                        formik.touched.productDetails?.[index]?.shape &&
                        Boolean(
                            formik.errors.productDetails &&
                            Array.isArray(formik.errors.productDetails) &&
                            (formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.shape
                        )
                        }
                      options={
                          SHAPE_TYPES?.map((option: any) => ({
                              value: (option.id),
                              label: option.name,
                          })) || []
                      }
                      helperText={(formik.touched.productDetails?.[index]?.shape &&
                        formik.errors.productDetails &&
                        Array.isArray(formik.errors.productDetails) &&
                        ((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.shape ?? "")) as string}
                    value={formik.values.productDetails[index]?.shape}
                    onBlur={() => formik.setFieldTouched(`productDetails[${index}].shape`)}
                    />
                
                </div>
            </div>
            <div>
                <div className={`pb-3 text-lg font-medium ${classes.darkHeading}`}>Delivery and Order Details</div>
                <div className="grid gap-y-3">
                    <div className={` ${classes.container} p-4 rounded-xl`}>
                        {schema.length > 0 && (
                            <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
                        )}
                    </div>
                </div>
            </div>

            <div>
                <div className='grid gap-6'>
                    <TextFieldV2
                        {...formik.getFieldProps(`productDetails[${index}].otherSpecificRequirement`)}
                        label='Any Other Specific Requirement (Optional)'
                        placeholder='Enter your specific requirement'
                        multiline
                        rows={4}
                  
                    // helperText={(formik.touched.productDetails?.[index]?.otherSpecificRequirement &&
                    //     formik.errors.productDetails &&
                    //     Array.isArray(formik.errors.productDetails) &&
                    //     ((formik.errors.productDetails as FormikErrors<IAddOwnProduct>[])[index]?.otherSpecificRequirement ?? "")) as string}
                    />
                </div>
            </div>
            {/* <ImageUploadFormTemplate mode={mode} formik={formik} /> */}
        </div>
    )
})

export default OwnProductFormTemplate;