import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavbarDropdownTemplate from './NavbarDropdowns/NavbarDropdown.template';
import CategoriesDropdown from './NavbarDropdowns/CategoriesDropdown';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
    },
    primaryContainer: {
    },
    homeSection: {
        borderRight: `1px solid ${theme.palette.border.primary.primary700}`,
        cursor: 'pointer',
        color: theme.palette.text.primary.primary700,
    },
    categories: {
        color: theme.palette.text.neutral.neutral600,
    },
    homemenu: {
        position: 'relative',
        color: theme.palette.text.primary.primary700,
        cursor: 'pointer',

        '&:hover $dropDownContent': {
            display: 'block',
        },
    },
    dropDownContent: {
        display: 'none',
        position: 'absolute',
        left: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        top: '0px', 
    },
}));

const NavbarOptions: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.container}`}>
            <div className={`${classes.primaryContainer} container mx-auto lg:px-4`}>
                <div className="flex gap-x-2 items-center WebNavigation py-[9px]">
                    <div className={`${classes.homemenu} flex gap-2`}>
                        <MenuIcon />
                        <div
                        className={`font-medium text-base ${classes.homeSection} ${classes.homemenu} pr-3 relative`}
                    >
                        Categories
                    </div>
                        <div className={`${classes.dropDownContent} CatMenuDropBox`}>
                            <CategoriesDropdown />
                        </div>
                    </div>
                    <NavbarDropdownTemplate />
                </div>
            </div>
        </div>
    );
};

export default NavbarOptions;