import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { CLASS_TYPE, HTTP_STATUS, STATUS } from "../../../utils/types";
import { useFormik } from "formik";
import { IMaterialFilterData } from "./GenericSerachDialog.template";
import { PRODUCT_SECTION } from "../../../utils/constant";
import Button from "../../atoms/Button/Button";
import FilterChip from "../../atoms/FilterChip/FilterChip";
import { IMultiSelectOption } from "../../atoms/MultiSelectInput/MultiSelectInput2";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { IProductUpcFilterRequest, IProductUPCRequest, useAdminMasterServiceV2 } from "../../../services/useAdminMasterServiceV2";

interface ProductCodeSearchFormTemplateProps {
    materialFilters: IMaterialFilterData;
    sectionType: string;
    setFilterData: (materialFilters: IMaterialFilterData) => void;
    onFilterSubmit: () => void;
    handleClose: () => void;
    handleFilterClear: () => void;
    classType?: string;
}

interface IUpcsByFilter {
    id: number;
    upcCode: string
    productId: number;
    productCategory: string;
    classType: string;
    shape: string;
    status: string;
    attributes: string;
    createdAt: string;
    updatedAt: string;
}

interface IBrandUpcsByFilter {
    id: number;
    upcCode: string
    productCategory: string;
    classType: string;
    shape: string;
    status: string;
    attributes: string;
}

interface ISecondaryInventory {
    id: number;
    skuCode: string;
    name: string;
    warehouseName: string;
    quantity: number;
    createdAt: string;
    updatedAt: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterContainer: {
        borderTop: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.text.primary.primary900,
    },
    activeProgressBar: {
        backgroundColor: "#CA4F02"
    },
    inActiveProgressBar: {
        backgroundColor: "#FD6202"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
}));

const materialFilterData = {
    productCodeArray: [],
    productCodeValue: {
        label: "",
        id: ""
    },
}

const ProductCodeSearchFormTemplate: React.FC<ProductCodeSearchFormTemplateProps> = ({ materialFilters, sectionType, setFilterData, onFilterSubmit, handleClose, handleFilterClear, classType }) => {
    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const [upcs, setUpcsTo] = useState<IUpcsByFilter[]>([]);
    const [brandUpcs, setBrandUpcsTo] = useState<IBrandUpcsByFilter[]>([]);

    const [secondaryInventories, setSecondaryInventoriesTo] = useState<ISecondaryInventory[]>([]);
    const adminMasterServiceV2 = useAdminMasterServiceV2();

    const formik = useFormik<any>({
        initialValues: materialFilterData,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            onFilterSubmit()
            handleClose()

        }
    });

    useEffect(() => {
        formik.setFieldValue("productCodeArray", materialFilters.productCode ?? []);
    }, [materialFilters.productCode])

    const refreshUpc = async (inputValue?: string) => {
        try {
            if (sectionType === PRODUCT_SECTION.MASTER_UPC) {
                let params: IProductUPCRequest = {
                    page: 0,
                    size: 10,
                    status: '',
                    upcCode: inputValue ?? '',
                    sort: "createdAt,desc",
                    classType: classType || CLASS_TYPE.STANDARD
                }
                const upcFilterResponse = await adminMasterServiceV2.getProductUPCs(params);
                if (
                    upcFilterResponse?.status === HTTP_STATUS.OK &&
                    upcFilterResponse?.data?.data?.content?.length
                ) {
                    setUpcsTo(upcFilterResponse.data.data.content);
                } else {
                    throw new Error("Upcs fetch failed");
                }
            }
            else if (sectionType === PRODUCT_SECTION.BRAND_UPC) {
                const requestBody: IProductUpcFilterRequest = {
                    status: STATUS.ACTIVE,
                    upcCode: inputValue ?? '',
                    classType: classType || CLASS_TYPE.STANDARD
                };
                let params: IProductUPCRequest = {
                    page: 0,
                    size: 10,
                    status: '',
                    sort: "createdAt,desc",
                }
                const upcFilterResponse = await adminMasterServiceV2.searchUpc(requestBody, params);
                if (
                    upcFilterResponse?.status === HTTP_STATUS.OK &&
                    upcFilterResponse?.data?.data
                ) {
                    setBrandUpcsTo(upcFilterResponse?.data?.data?.content);
                } else {
                    throw new Error("Upcs fetch failed");
                }
            }
            else if (sectionType === PRODUCT_SECTION.VENDOR_PRODUCTS && user?.businessId) {
                const requestBody: IProductUpcFilterRequest = {
                    status: STATUS.ACTIVE,
                    upcCode: inputValue ?? '',
                    classType: classType || CLASS_TYPE.STANDARD
                };
                let params: IProductUPCRequest = {
                    page: 0,
                    size: 10,
                    status: '',
                    sort: "createdAt,desc",
                }
                const upcFilterResponse = await adminMasterServiceV2.searchUpcByVendorId(user?.businessId, requestBody, params);
                if (upcFilterResponse?.status === HTTP_STATUS.OK && upcFilterResponse?.data?.data) {
                    setBrandUpcsTo(upcFilterResponse?.data?.data?.content);
                } else {
                    throw new Error("Upcs fetch failed");
                }
            }
        } catch (error) {
            setBrandUpcsTo([]);
            setUpcsTo([]);
        }
    };

    const getAllSecondaryInventories = async (inputValue?: string) => {
        let params = {
            skuCode: inputValue ?? '',
            page: 0,
            size: 10,
            sort: "createdAt,desc",
        }

        const skuFilterResponse = await adminMasterServiceV2.getAllSecondaryInventories({}, params);
        if (
            skuFilterResponse?.status === HTTP_STATUS.OK &&
            skuFilterResponse?.data?.data?.content?.length
        ) {
            setSecondaryInventoriesTo(skuFilterResponse.data.data.content);
        } else {
            throw new Error("SKU fetch failed");
        }
    }


    const upcOptions = useMemo(() => {
        return upcs?.map((upc: IUpcsByFilter) => ({ label: upc.upcCode, value: upc.id })) ?? []
    }, [upcs])

    const brandUpcOptions = useMemo(() => {
        return brandUpcs?.map((upc: IBrandUpcsByFilter) => ({ label: upc.upcCode, value: upc.id })) ?? []
    }, [brandUpcs])

    const skuOptions = useMemo(() => {
        return secondaryInventories?.map((sku: ISecondaryInventory) => ({ label: sku.skuCode, value: sku.id })) ?? []
    }, [secondaryInventories])


    useEffect(() => {
        if ((sectionType === PRODUCT_SECTION.BRAND_UPC || sectionType === PRODUCT_SECTION.MASTER_UPC || sectionType === PRODUCT_SECTION.VENDOR_PRODUCTS)) {
            refreshUpc();
        } else if (sectionType === PRODUCT_SECTION.SECONDARY) {
            getAllSecondaryInventories();
        }
    }, []);

    const updateSelectedUpc = (key: string) => (option: IMultiSelectOption[] | null) => {
        if (option) {
            formik.setFieldValue(`${key}Array`, option);
            setFilterData({
                ...materialFilters,
                productCode: option
            })
        }
    };

    const handleClearClick = (key: string) => {
        formik.setFieldValue(`${key}Array`, []);
        setFilterData({
            ...materialFilters,
            productCode: []
        })

    };


    return (
        <div className={`${classes.container} m-4 p-4 grid rounded-lg  border-b`}>
            <div className="flex justify-between">
                <div className={`${classes.filterHeading} text-lg font-medium my-auto`}>Search By Product Code</div>
            </div>
            <div className={`grid gap-y-2 pt-2`}>
                {(sectionType === PRODUCT_SECTION.BRAND_UPC || sectionType === PRODUCT_SECTION.MASTER_UPC || sectionType === PRODUCT_SECTION.VENDOR_PRODUCTS) && <div className="grid gap-y-2 w-2/3">
                    <FilterChip
                        options={sectionType === PRODUCT_SECTION.MASTER_UPC ? upcOptions : brandUpcOptions}
                        label=""
                        value={formik.values.productCodeArray}
                        onchange={updateSelectedUpc('productCode')}
                        placeholder="Search by UPC"
                        ButtonComponent={Button}
                        buttonLabel="Clear All"
                        buttonOnClick={() => handleClearClick('productCode')}
                        textFieldPlaceholder='Search...'
                        setSearchTerm={refreshUpc}
                        optionToShow={1}
                    />

                </div>
                }
                {sectionType === PRODUCT_SECTION.SECONDARY && <div className="grid gap-y-2 w-2/3">
                    <FilterChip
                        options={skuOptions}
                        label=""
                        value={formik.values.productCodeArray}
                        onchange={updateSelectedUpc('productCode')}
                        placeholder="Search by SKU"
                        ButtonComponent={Button}
                        buttonLabel="Clear All"
                        buttonOnClick={() => handleClearClick('productCode')}
                        textFieldPlaceholder='Search...'
                        setSearchTerm={getAllSecondaryInventories}
                        optionToShow={1}
                    />


                </div>
                }
            </div>
        </div>
    );
};

export default ProductCodeSearchFormTemplate;