import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Dialog from '@mui/material/Dialog';
import filterCrossIcon from '../../../assets/icons/filterCrossIcon.svg'
import { Tooltip } from '@mui/joy';
import Button from '../../atoms/Button/Button';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import facebook_Icon_1 from '../../../assets/icons/Facebook.svg';
import linkedin_Icon_1 from '../../../assets/icons/linkedin_Icon_1.svg';
import whatsapp_Icon_1 from '../../../assets/icons/shareWhatsapp.svg';
import LinkIcon from '@mui/icons-material/Link';
import { useSnackbar } from '../../../hooks/useSnackBar';

interface IshareProps {
    open: any;
    onClose: any;
}

const useStyles = createUseStyles({
    dialog: {
        width: '300px',
        display: 'block',
    },
});

const CatalogueSharetemplate: React.FC<IshareProps> = ({ open, onClose }) => {
    const classes = useStyles();

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [label, setLabel] = useState("Copy");

    const handleCopyLink = () => {
        navigator.clipboard.writeText(window.location.href)
            .then(() => {
                setTooltipOpen(true);
                setTimeout(() => setTooltipOpen(false), 1000);
            })
            .catch(() => {
                showSnackbar("error", "Failed to open");
            });
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(window.location.href);
        setLabel("Copied");
        setTimeout(() => { setLabel("Copy"); }, 2000);
    };

    return (
        <div className={`${classes.dialog} border bg-white rounded-xl`}>
            <div
                style={{
                    position: 'absolute',
                    top: '-10px',
                    left: '20px',
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderBottom: '10px solid white',
                    zIndex: 1,
                }}
            />
            <div className='flex justify-between p-3'>
                <div className='flex items-center gap-3'>
                    <span className='p-1'><a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
                        target="_blank" rel="noopener noreferrer" ><img src={facebook_Icon_1} className="w-10" alt="facebook" />
                    </a>
                    </span>
                    <span className='p-1'>
                        <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer" >
                            <img src={whatsapp_Icon_1} className="w-10" alt="whatsapp" />
                        </a>
                    </span>
                    <span className='p-1'><a href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer" >
                        <img src={linkedin_Icon_1} alt="linkedIn" />
                    </a>
                    </span>
                    <Tooltip
                        title="Copied to clipboard!"
                        open={tooltipOpen}
                        placement="right"
                        arrow
                    >
                        <span className="p-1 cursor-pointer" onClick={handleCopyLink}>
                            <LinkIcon />
                        </span>
                    </Tooltip>
                </div>
                <div><img className="w-4 cursor-pointer" src={filterCrossIcon} alt='' onClick={onClose} /></div>
            </div>
            <div className="flex items-center gap-2 p-3 border-t">
                <div className='flex-1'>
                    <TextFieldV2
                        value={window.location.href}
                        disabled={true}
                    />
                </div>
                <Button variant="primaryContained" label={label} size="medium" onClick={handleCopy} />
            </div>
        </div>)
}

export default CatalogueSharetemplate;