import React, { useCallback, useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import logoutIcon from "../../../assets/icons/logout.svg"
import profileIcon from "../../../assets/images/profileIcon.svg"
import dotMenu from "../../../assets/icons/zondicons_dots-horizontal-triple.svg"
import ConfirmDialog from "../../molecules/ConfirmDialog/ConfirmDialog";
import TabsV2, { ITabsSchema } from "../../molecules/Tabs/TabsV2";
import { MenuItem, getDashboardMenuSchema } from './DashBoardMenuSchema';
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { CartContext } from "../../../contexts/CartContext";
import { WishlistContext } from "../../../contexts/WishlistContext";
import { ISellerAccountCreationRequestBody, useBusinessProfileService } from "../../../services/useBusinessProfileService";
import { useUserService } from "../../../services/useUserService";
import { useUserProfileTypeService } from "../../../services/useUserProfileTypeService";
import { DASHBOARD_RESOURCES, GENERIC_EXCEPTION_CODE } from '../../../utils/constant';
import { addToQueue, getLatestItemByKey } from "../../../utils/helper";
import { HTTP_STATUS, USER_PROFILE_TYPE } from "../../../utils/types";
import VendorSellerSelectionDialogTemplate from "../../template/VendorSellerSelectionDialog/VendorSellerSelectionDialog.template";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.background.neutral.neutral100}`
    },
    webContainer: {
        display: "grid"
    },
    customListItemButton: {
        width: "100%",
        marginBottom: "4px",
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
        padding: "8px 12px",
        borderRadius: "8px",
        color: theme.palette.background.neutral.neutral400,
        "&.Mui-selected": {
            color: `${theme.palette.background.primary.primary600} !important`,
            background: `${theme.palette.background.primary.primary50} !important`,
        },
    },
    lastLevelListItemButton: {
        color: theme.palette.background.neutral.neutral400
    },
    selectedOptionHighlight: {
        background: `${theme.palette.background.primary.primary100} !important`
    },
    selectedOption: {
        background: `${theme.palette.background.primary.primary50} !important`,
        color: theme.palette.background.primary.primary600
    },
    logoutButton: {
        color: "#DA1E27"
    },
    name: {
        color: theme.palette.background.primary.primary900
    }
}));

const DashBoard = () => {
    const classes = useStyles();
    const cartContextData = useContext(CartContext);
    const wishlistContextData = useContext(WishlistContext);
    const userService = useUserService();
    const navigate = useNavigate();
    const location = useLocation();
    const { user, setAuthenticatedUser } = useAuthenticatedUser();
    const [expandedMenu, setExpandedMenu] = useState<{ [key: string]: boolean }>({});
    const [isUserOptionActive, setIsUserOptionActiveTO] = useState(false);
    const toggleUserOption = () => setIsUserOptionActiveTO(prev => !prev);
    const localHistory = getLatestItemByKey("dashboard");
    const latestDashboardTab = localHistory;
    const [confirmDialogState, setConfirmDialogState] = useState<boolean>(false);
    const [vendorSellerDialogState, setVendorSellerDialogState] = useState<boolean>(false);
    const userProfileTypeService = useUserProfileTypeService();
    const businessProfileService = useBusinessProfileService();
    const [activeUserProfileTab, setActiveUserProfileTab] = useState((JSON.parse(sessionStorage.getItem('current-profile-type') as string)) ?? USER_PROFILE_TYPE.BUYER);
    const [sellerType, setSellerType] = useState<string>('');
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    useEffect(() => {
        if (activeUserProfileTab === USER_PROFILE_TYPE.SELLER) {
            verifySellerProfile();
        }
        sessionStorage.setItem('current-profile-type', JSON.stringify(activeUserProfileTab));
    }, [activeUserProfileTab])

    const verifySellerProfile = async () => {
        if (!user?.isVendor) {
            if (!(await handleVendorConfirmDialog(true)(true)))
                setVendorSellerDialogState(true);
        }
    }

    const handleMenuChange = (keys: string[]) => () => {
        if (keys.every(key => expandedMenu[key])) {
            delete expandedMenu[keys.pop() as string];
            setExpandedMenu({ ...expandedMenu });
        }
        else {
            const updatedExpandedMenu: { [key: string]: boolean } = keys.reduce(
                (previousValue: { [key: string]: boolean }, currentValue: string) => (
                    { ...previousValue, [currentValue]: true }
                ), {})
            addToQueue(JSON.stringify({ "key": "dashboard", "expandedMenu": updatedExpandedMenu }));
            setExpandedMenu(updatedExpandedMenu);
        }
    };

    const getUserDetails = async () => {
        let res = await userService.getUser();

        if (res.status === HTTP_STATUS.OK) {
            let tokenResponse = await userService.refershToken(Number(res?.data?.data?.id));
            if (tokenResponse.status === HTTP_STATUS.OK){
                let { user, token, isVendor} = tokenResponse?.data?.data ?? {};
                setAuthenticatedUser({ ...user, token, isVendor });
    }
        }
    }

    useEffect(() => {
        if (latestDashboardTab) {
            setExpandedMenu(latestDashboardTab.expandedMenu);
        }
    }, [latestDashboardTab])

    useEffect(() => {
        getUserDetails();
    },[])

    const handleLogout = () => {
        setAuthenticatedUser(null);
        wishlistContextData.clearWishlistState();
        cartContextData.clearCartState();
        navigate('/')
    };

    const renderMenuItems = useCallback((menuItems: MenuItem[], parent: string[] = []) => {
        return menuItems.map(({ key, route, icon, subList, disabled }) => {
            const isSelected = !!(route && location.pathname.startsWith(route));
            return <div key={key} className="">
                <div className="flex flex-row-reverse">
                    <ListItemButton
                        component={route ? Link : "span"}
                        to={route}
                        selected={isSelected}
                        onClick={handleMenuChange([...parent, key])}
                        className={`
                                ${classes.customListItemButton} 
                                ${!subList?.length && classes.lastLevelListItemButton} 
                            `}
                        disabled={disabled || (!!!user?.email && key === DASHBOARD_RESOURCES.CHANGE_PASSWORD)}
                    >
                        {icon && <ListItemIcon style={{ marginRight: "-35px" }}>
                            <img src={icon} alt="icon" />
                        </ListItemIcon>}

                        <ListItemText primary={key} className={`${['pl-1', 'pl-2', 'pl-3'][parent.length]}`} />
                        {subList?.length && <div >{expandedMenu[key] ? <ExpandLess /> : <ExpandMore />}</div>}
                    </ListItemButton>
                    {/* {!subList?.length && expandedMenu[key] && <div className={`pl-1 rounded-lg  mr-1 ${classes.selectedOptionHighlight}`}></div>} */}
                </div>
                <Collapse in={expandedMenu[key]} timeout="auto" unmountOnExit>
                    <div className="ml-3">
                        {subList && renderMenuItems(subList, [...parent, key])}
                    </div>
                </Collapse>
            </div>
                ;
        });
    }, [expandedMenu, user, activeUserProfileTab]);

    const schema: ITabsSchema[] = [
        { label: 'Buyer', component: <></> },
        { label: 'Seller', component: <></> },
    ]

    const handleVendorConfirmDialog = (isCheckProfile: boolean) => async (value: boolean) => {
        if (value) {
            if (!user || !user.businessId) {
                showSnackbar("error", "Buyer account doesn't exist or seller profile missing");
                return false;
            }

            if (!sellerType) {
                return false;
            }

            const res = await userProfileTypeService.createVendorProfile({ checkProfile: isCheckProfile });
            if (res?.status === HTTP_STATUS.OK || res?.data.exceptionCode === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY) {

                const data: ISellerAccountCreationRequestBody = {
                    "vendorType": sellerType
                }

                const sellerUpdateResponse = await businessProfileService.updateBusinessSellerProfile(data, user.businessId)
                if (sellerUpdateResponse?.status === HTTP_STATUS.OK) {
                    let updatedUser = { ...user, ...{ isVendor: true } };
                    setAuthenticatedUser(JSON.parse(JSON.stringify(updatedUser)));
                    setActiveUserProfileTab(USER_PROFILE_TYPE.SELLER);
                    setVendorSellerDialogState(false)
                    return true;
                }
                showSnackbar("error", "Error creating seller profile")
                return false;

            } else {
                if (!isCheckProfile) {
                    setActiveUserProfileTab(USER_PROFILE_TYPE.BUYER);
                    showSnackbar("error", "Error creating seller profile")
                }
                return false;
            }
        } else {
            !isCheckProfile && setActiveUserProfileTab(USER_PROFILE_TYPE.BUYER);
            return false;
        }
    }

    const getProfileType = () => {
        return (activeUserProfileTab === USER_PROFILE_TYPE.SELLER && user?.isVendor)
            ? USER_PROFILE_TYPE.SELLER : USER_PROFILE_TYPE.BUYER;
    };

    const sellerToggleCallback = (value: any) => {
        setActiveUserProfileTab(value)
    }


    const findRoute = (menuSchema: MenuItem[]) => {
        for (const item of menuSchema) {
            if (item.route && !item.disabled) return item.route;
            if (item.subList) {
                const subRoute = item.subList.find(subItem => subItem.route && !subItem.disabled)?.route;
                if (subRoute) return subRoute;
            }
        }
        return null;
    };

    const handleTabChange = (tab: USER_PROFILE_TYPE) => {
        setActiveUserProfileTab(tab);  
        sessionStorage.setItem('current-profile-type', JSON.stringify(tab));
        const dashboardSchema = getDashboardMenuSchema(tab);
        const route = findRoute(dashboardSchema);
        if (route) {
            navigate(route);
        }
        if (tab === USER_PROFILE_TYPE.SELLER) {
            verifySellerProfile();
            sessionStorage.setItem('current-profile-type', JSON.stringify(tab));
        }
    };

    return (
        <>
            {SnackBarComponent}
            <div className={`${classes.webContainer} gap-y-6 m-auto max-container `} style={{ padding: "0px 70px 108px 70px" }}>
                <div>
                    {/* <Breadcrumbs /> */}
                </div>
                <div className="flex gap-x-4 ">
                    <div className={`w-1/5`}>
                        <div className={`py-6 px-2 rounded-2xl mt-0 flex flex-col ${classes.container}`}>
                            <div className="border-b relative">
                                <div className=" mb-4 flex gap-x-3 justify-between items-center">
                                    <div className="rounded-full">
                                        <div className={`${classes.selectedOptionHighlight} flex justify-center items-center font-bold rounded-full w-10 h-10`}>
                                            {user?.fullName?.[0]}
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-1">
                                        <span className={`${classes.name} text-sm font-medium`}>{user?.fullName}</span>
                                        {/* //TODO - Currently registraionCode is not available in the system */}
                                        {/* <span className={`text-xs ${classes.title}`}>{user?.registrationCode}</span> */}
                                    </div>
                                    <div>
                                        {isUserOptionActive && <div className="absolute right-6 bottom-14 border px-4 py-3 flex flex-col gap-y-4 rounded-lg bg-white z-10 w-32">
                                            {/* <div className={` ${classes.name} text-sm flex gap-x-2 cursor-pointer`}>
                                                <img src={profileIcon} alt="profileIcon" />
                                                <span>My Profile</span>
                                            </div> */}
                                            {/* <span className="border-t"></span> */}
                                            <div className={`text-sm flex gap-x-2 ${classes.logoutButton} cursor-pointer`} onClick={handleLogout}>
                                                <img src={logoutIcon} alt="logoutIcon" />
                                                <span>Logout</span>
                                            </div>
                                        </div>}
                                        <div className="cursor-pointer p-2">
                                            <img src={dotMenu} alt="dotMenu" onClick={toggleUserOption} className="w-4 h-4" />
                                        </div>

                                    </div>
                                </div>
                                <TabsV2 schema={schema} value={activeUserProfileTab} setValue={handleTabChange} />

                                <VendorSellerSelectionDialogTemplate
                                    dialogOpen={vendorSellerDialogState}
                                    setDialogOpen={setVendorSellerDialogState}
                                    onSubmit={handleVendorConfirmDialog(false)} 
                                    setConfirmDialogState={setConfirmDialogState}/>

                                <ConfirmDialog
                                    dialogOpen={confirmDialogState}
                                    setDialogOpen={setConfirmDialogState}
                                    message="You are currently not registered as a seller"
                                    question="Do you want to register as a seller?"
                                    onSubmit={handleVendorConfirmDialog(false)}
                                    sellerType={sellerType}
                                    setSellerType={setSellerType}
                                />
                            </div>
                            <div className="flex-1 h-auto mt-6">
                                {!!user && <List component="nav" sx={{ padding: "0px" }}>
                                    {renderMenuItems(getDashboardMenuSchema(getProfileType()))}
                                </List>}
                            </div>
                        </div>
                    </div>
                    <div className="w-4/5">
                        <Outlet context={{sellerToggleCallback}}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashBoard;